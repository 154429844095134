import { CLOSE_SEND_RX_MODAL, OPEN_SEND_RX_MODAL } from 'constants/actionTypes';

export const openSendRxModal = (data) => ({
	type: OPEN_SEND_RX_MODAL,
	data,
});

export const closeSendRxModal = () => ({
	type: CLOSE_SEND_RX_MODAL,
});
