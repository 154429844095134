import initialState from 'store/initialState';
import { OPEN_SEND_RX_MODAL, CLOSE_SEND_RX_MODAL } from 'constants/actionTypes';

export default (state = initialState.sendRxModal, action = null) => {
	switch (action.type) {
		case OPEN_SEND_RX_MODAL:
			return {
				visible: true,
				rxId: action.data,
			};

		case CLOSE_SEND_RX_MODAL:
			return initialState.sendRxModal;

		default:
			return state;
	}
};
