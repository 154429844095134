import React from 'react';
import { QRCode } from 'react-qrcode-logo';

import { EuiFlexItem, EuiFlexGroup, EuiText, EuiImage } from '@elastic/eui';

import { get } from 'lodash';

import PropTypes from 'prop-types';
import Logo from 'assets/images/logo_horizontal_white_small.png';
import moment from 'moment';

const QrCodeDisplay = ({ prescription, type }) => {
	const { vendor = {}, patient = {}, branch = {}, order = {} } = prescription;

	const getPatientName = () => {
		return `${patient.firstName} ${patient.lastName}`;
	};

	const getVendorBranchDisplay = () => {
		return `${get(vendor, 'name')} / ${get(branch, 'name')}`;
	};

	const getPackingCode = () => {
		return `${
			type === 'rx' ? get(prescription, 'code') : get(order, 'orderId')
		} ${
			type === 'rx'
				? // `- ${get(prescription, 'packingCode', '')}`
				  ''
				: `- ${get(order, 'packingCode', '')}`
		}`;
	};

	const getDateCreated = () => {
		return moment(
			get(type === 'rx' ? prescription : order, 'createdAt'),
		).format('MM-DD-YYYY');
	};

	const getQrString = () => {
		return `${process.env.REACT_APP_BASE_URL}${
			type === 'rx'
				? `/prescription/${get(prescription, 'code')}`
				: `/orders/${get(order, 'orderId')}`
		}`;
	};

	return (
		<EuiFlexGroup
			direction="column"
			gutterSize="s"
			justifyContent="spaceAround"
		>
			<EuiFlexItem
				grow={false}
				style={{ justifyContent: 'center', alignItems: 'center' }}
			>
				<QRCode size="160" value={getQrString()} />
			</EuiFlexItem>

			<EuiFlexItem grow={false}>
				<EuiText textAlign="center">{getVendorBranchDisplay()}</EuiText>
			</EuiFlexItem>

			<EuiFlexItem
				style={{ justifyContent: 'center', alignItems: 'center' }}
			>
				<EuiImage
					alt="logo"
					size="m"
					url={get(vendor, 'logo.url', Logo)}
				/>
			</EuiFlexItem>
			<EuiFlexItem grow={false}>
				<EuiText textAlign="center">
					<b>{getPatientName()}</b>
				</EuiText>
			</EuiFlexItem>
			<EuiFlexItem>
				<EuiText textAlign="center">
					{type === 'rx' ? 'Prescription Code' : 'Code'}
				</EuiText>
				<EuiText
					style={{
						backgroundColor: get(vendor, 'primaryColor'),
						borderRadius: 5,
						padding: '5px',
						color: 'white',
					}}
					textAlign="center"
				>
					{getPackingCode()}
				</EuiText>
			</EuiFlexItem>
			<EuiFlexItem>
				<EuiText textAlign="center">Date Created</EuiText>
				<EuiText
					style={{
						borderStyle: 'solid',
						borderWidth: '2px',
						borderRadius: 5,
						borderColor: get(vendor, 'primaryColor'),
						padding: '5px',
						color: get(vendor, 'primaryColor'),
					}}
					textAlign="center"
				>
					{getDateCreated()}
				</EuiText>
				<EuiText style={{ paddingTop: '1em' }}>
					Your order is now ready for pickup. You may now go to
					{branch && branch.name} to pickup your order.
				</EuiText>
			</EuiFlexItem>
		</EuiFlexGroup>
	);
};

QrCodeDisplay.propTypes = {
	prescription: PropTypes.shape({
		order: PropTypes.shape({
			orderId: PropTypes.string,
			packingCode: PropTypes.string,
			createdAt: PropTypes.string,
		}),
		branch: PropTypes.shape({
			name: PropTypes.string,
		}),
		code: PropTypes.string,
		orders: PropTypes.oneOfType([PropTypes.array]),
		patient: PropTypes.PropTypes.shape({
			firstName: PropTypes.string,
			lastName: PropTypes.string,
		}),
		status: PropTypes.bool,
		vendor: PropTypes.shape({
			name: PropTypes.string,
			logo: PropTypes.shape({
				url: PropTypes.string,
			}),
			primaryColor: PropTypes.string,
		}),
	}).isRequired,
	type: PropTypes.string,
};

QrCodeDisplay.defaultProps = {
	type: null,
};

export default QrCodeDisplay;
