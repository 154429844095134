import React, { useState, useEffect } from 'react';
import { getBranchInfo } from 'modules/branches/branches.fetch';
import { EuiText } from '@elastic/eui';
import { get } from 'lodash';
import PropTypes from 'prop-types';

const BranchInfo = ({ id }) => {
	const [branch, setBranch] = useState(null);

	useEffect(() => {
		async function fetchBranchInfo(branchId) {
			const result = await getBranchInfo(branchId);

			return setBranch(result);
		}

		if (id) fetchBranchInfo(id);
	}, [id]);

	return (
		<EuiText>
			{get(
				branch,
				'name',
				id ? 'Unable to get branch info.' : 'Call Center',
			)}
		</EuiText>
	);
};

BranchInfo.propTypes = {
	id: PropTypes.string,
};

BranchInfo.defaultProps = {
	id: null,
};

export default BranchInfo;
