import React from 'react';
import { EuiText, EuiSpacer, EuiLink } from '@elastic/eui';

const TermsAndConditions = () => {
	const privacyUrl = `${process.env.REACT_APP_BASE_URL}/privacy-policy`;
	return (
		<EuiText>
			<h3>Terms and Conditions</h3>
			<p>
				This website and any mobile application (collectively, this
				“Site”) is owned by Unexus Medical Solutions, Inc. (“We”, “Us”
				or “Unexus Medical Solutions, Inc.”). We are providing you with
				access to this Site and our online store (together, our
				“Services”) subject to the following terms and conditions.
			</p>
			<p>
				By browsing, accessing, using, registering for or purchasing
				merchandise on this Site or otherwise using our Services, you
				are agreeing to all of the following terms and conditions,
				including any policies referred to herein (collectively, these
				“Terms”). So, please read these Terms carefully. We reserve the
				right to change this Site and these Terms at any time. If you
				are unwilling to be bound by these Terms‚ you should not browse,
				access‚ use‚ register for or purchase merchandise from the Site.
			</p>
			<p>
				You represent and warrant that you are at least 18 years old or
				visiting this Site under the supervision of a parent or
				guardian.
			</p>
			<h3>Privacy Policy</h3>
			<p>
				Our Privacy Policy, which also governs your visit to Our Site,
				can be found at{' '}
				<EuiLink href={privacyUrl}>{privacyUrl}</EuiLink>. Please review
				our Privacy Policy for information on how We collect, use and
				share information about our users.
			</p>
			<h3>Use of This Site</h3>
			<p>
				Subject to your compliance with these Terms‚ We grant you a
				limited‚ non-exclusive‚ non-transferable‚ non-sublicensable
				license to access and make personal‚ non-commercial use of this
				Site. This license grant does not include: (a) any resale or
				commercial use of this Site or content therein; (b) the
				collection and use of any product listings or descriptions; (c)
				making derivative uses of this Site and its contents; or (d) use
				of any data mining‚ robots‚ or similar data gathering and
				extraction methods on this Site. You may not use‚ frame or
				utilize framing techniques to enclose any of Our trademark‚
				logo‚ content or other proprietary information (including the
				images found at this Site‚ the content of any text or the
				layout/design of any page or form contained on a page) without
				Our express written consent. Further‚ you may not use any meta
				tags or any other “hidden text” utilizing Our name‚ trademark‚
				or product name without Our express written consent. Any breach
				of these Terms shall result in the immediate revocation of the
				license granted in this paragraph without notice to you.
			</p>
			<h3>Account</h3>
			<p>
				In order to access some features of this Site‚ you are required
				to register your email address and provide a password. If you
				register‚ you agree to provide Us with accurate and complete
				registration information‚ and to inform us immediately of any
				updates or other changes to such information.
			</p>
			<p>
				You are solely responsible for protecting the security and
				confidentiality of your login credentials. You shall immediately
				notify Us of any unauthorized use of your password or any other
				breach or threatened breach of this Site&apos;s security. Each
				time you login or use our relay Service, you will be deemed
				authorized to access and use the Site and our Service in a
				manner consistent with these Terms, and We have no obligation to
				investigate the authorization or source of any such access or
				use of this Site. YOU WILL BE SOLELY RESPONSIBLE FOR ALL ACCESS
				TO AND USE OF THIS SITE BY ANYONE USING YOUR LOGIN CREDENTIALS
				ORIGINALLY SELECTED BY, OR ASSIGNED TO, YOU WHETHER OR NOT SUCH
				ACCESS TO AND USE OF THIS SITE IS ACTUALLY AUTHORIZED BY YOU,
				INCLUDING WITHOUT LIMITATION, ALL COMMUNICATIONS AND
				TRANSMISSIONS AND ALL OBLIGATIONS (INCLUDING WITHOUT LIMITATION
				FINANCIAL OBLIGATIONS) INCURRED THROUGH SUCH ACCESS OR USE.
			</p>
			<h3>Electronic Communication</h3>
			<p>
				When you use this Site, or send emails to Us, you are
				communicating with Us electronically. You consent to receive
				communications from Us electronically. We will communicate with
				you by e-mail or by posting notices on this Site or through our
				other services. You agree that all agreements, notices,
				disclosures and other communication that We provide to you
				electronically satisfy any legal requirements that such
				communications be in writing.
			</p>
			<h3>User Content</h3>
			<p>
				This Site may include features and functionality (“Interactive
				Features”) that allows users to create, post, transmit or store
				any content, such as text, photos, graphics or code on the
				Site’s (&quot;User Content&quot;). You agree that you are solely
				responsible for your User Content and for your use of
				Interactive Features, and that you use any Interactive Features
				at your own risk.
			</p>
			<p>
				By using any Interactive Areas, you agree not to post, upload
				to, transmit, distribute, store, create or otherwise publish or
				send through the Sites any of the following:
			</p>

			<ul>
				<li>
					User Content that is unlawful, libelous, defamatory,
					obscene, pornographic, indecent, lewd, suggestive,
					harassing, threatening, abusive, inflammatory, fraudulent or
					otherwise objectionable;
				</li>
				<li>
					User Content that would constitute, encourage or provide
					instructions for a criminal offense, violate the rights of
					any party or that would otherwise create liability or
					violate any local, state, national or international law;
				</li>
				<li>
					User Content that displays, describes or encourages usage of
					any product We sell in a manner that could be offensive,
					inappropriate or harmful to Us or any user or consumer;
				</li>
				<li>
					User Content that may impinge upon or violate the publicity,
					privacy or data protection rights of others, including
					pictures, videos, images or information about another
					individual where you have not obtained such
					individual&apos;s consent;
				</li>
				<li>
					User Content that makes false or misleading statements,
					claims or depictions about a person, company, product or
					service;
				</li>
				<li>
					User Content that may infringe any patent, trademark, trade
					secret, copyright or other intellectual or proprietary right
					of any party;
				</li>
				<li>
					User Content that impersonates any person or entity or
					otherwise misrepresents your affiliation with a person or
					entity; and
				</li>
				<li>
					Viruses, malware of any kind, corrupted data or other
					harmful, disruptive or destructive files or code.
				</li>
			</ul>
			<h3>Feedback</h3>
			<p>
				Separate and apart from User Content, you may have the ability
				to submit questions, comments suggestions, reviews, ideas,
				plans, designs, notes, proposals, drawings, original or creative
				materials and other information regarding this Site, Us and our
				products or services (collectively &quot;Feedback&quot;). You
				agree that Feedback is non-confidential and shall become Our
				sole property. We shall own exclusive rights, including all
				intellectual property rights, in and to such Feedback and shall
				be entitled to the unrestricted use and dissemination of the
				Feedback for any purpose, commercial or otherwise, without
				acknowledgment or compensation to you.
			</p>
			<h3>Ownership</h3>
			<p>
				As between you and Us‚ this Site‚ including all photographs‚
				images‚ text‚ graphics‚ icons‚ audio clips‚ software‚ source
				code and other aspects thereof (excluding User Content)‚ all
				improvements or modifications thereof‚ all derivative works
				based thereon‚ and the collection‚ arrangement‚ and assembly of
				this Site (collectively, the “Site Content”)‚ including all
				copyrights‚ trademarks‚ and other intellectual property or
				proprietary rights in the foregoing‚ are owned by Us or our
				licensors and protected by applicable copyright laws.
			</p>
			<p>
				The use of any of Our trademarks or service marks without our
				express written consent is strictly prohibited. You may not use
				our trademarks or service marks in connection with any product
				or service in any way that is likely to cause confusion. You may
				not use our trademarks or service marks in any manner that
				disparages or discredits us. You may not use any of our
				trademarks or service marks in meta tags without prior explicit
				consent. Nothing in these Terms shall be deemed to grant to you
				or any other user any license or right in or to any of Our
				patents‚ copyrights‚ trademarks‚ trade secrets or other
				proprietary rights.
			</p>
			<h3>Links</h3>
			<p>
				This Site may contain links to other sites on the Internet that
				are owned and operated by third parties. You acknowledge that We
				are not responsible for the operation of or content located on
				or through any such site.
			</p>
			<h3>Termination</h3>
			<p>
				You may terminate the Terms at any time by closing your account,
				discontinuing your use of this Site and providing Us with a
				notice of termination. We reserve the right, without notice and
				in our sole discretion, to terminate your right to use this
				Site, or any portion of this Site, and to block or prevent your
				future access to and use of this Site or any portion of this
				Site.
			</p>
			<h3>Indemnification</h3>
			<p>
				To the fullest extent permitted by applicable law, you agree to
				defend, indemnify and hold harmless Us and our subsidiaries and
				affiliates, and our respective officers, directors, agents,
				partners, members, employees, independent contractors, service
				providers and consultants (&quot;Our Related Parties&quot;),
				from and against any claims, damages, costs, liabilities and
				expenses (collectively, &quot;Claims&quot;) arising out of or
				related to (a) your access to and use or misuse of this Site;
				(b) any User Content you post, upload, use, distribute, store or
				otherwise transmit on or through this Site; (c) any Feedback
				that you provide; (d) your violation of these Terms; and (e)
				your violation of any rights of another. You agree to promptly
				notify Us of any third party Claims, cooperate with Us in
				defending such Claims and pay all fees, costs and expenses
				associated with defending such Claims (including but not limited
				to attorneys&apos; fees). You further agree that the We shall
				have the right to control of the defense or settlement of any
				third party Claims.
			</p>
			<h3>Modifications to Site</h3>
			<p>
				We reserve the right to modify or discontinue, temporarily or
				permanently, this Site or any features or portions thereof
				without prior notice.
			</p>
			<h3>Severability</h3>
			<p>
				If any these provisions shall be deemed invalid, void, or for
				any reason unenforceable, that condition shall be deemed several
				and shall not affect the validity and enforceability of any
				remaining provision.
			</p>
			<h3>No Third-Party Beneficiaries</h3>
			<p>
				These Terms are for the benefit of, and will be enforceable by,
				the parties only. These Terms are not intended to confer any
				right or benefit on any third party or to create any obligations
				or liability of a party to any such third party.
			</p>
			<h3>Miscellaneous</h3>
			<p>
				No agency‚ partnership‚ joint venture‚ or employment
				relationship is created as a result of these Terms‚ and you do
				not have any authority of any kind to bind Us in any respect
				whatsoever. We may provide you with notices‚ including those
				regarding changes to these Terms‚ by email‚ regular mail‚ or
				postings on this Site. These Terms, which shall be deemed
				accepted by you upon your use of the Site‚ constitute the entire
				agreement among you and Us regarding use of this Site. Our
				failure to exercise or enforce any right or provision of these
				Terms shall not constitute a waiver of the enforcement of such
				right or provision. If any provision of these Terms is found to
				be unenforceable or invalid‚ that provision shall be limited or
				eliminated to the minimum extent necessary so that these Terms
				shall otherwise remain in full force and effect and enforceable.
				These Terms are not assignable‚ transferable or sublicensable by
				you‚ except with our prior written consent. These Terms include
				and incorporate by reference Our Privacy Policy, which can be
				found at <EuiLink href={privacyUrl}>{privacyUrl}</EuiLink>, and
				any notices regarding the Site.
			</p>
			<h3>Questions</h3>
			<p>
				Questions regarding these Terms, Our Privacy Policy, or other
				policy related material can be directed to our support staff by
				emailing us at: info@medalert.ph.
			</p>
			<EuiSpacer />
		</EuiText>
	);
};

export default TermsAndConditions;
