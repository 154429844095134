import React from 'react';
import {
	EuiOverlayMask,
	EuiModal,
	EuiModalHeader,
	EuiModalHeaderTitle,
	EuiModalBody,
	EuiModalFooter,
	EuiButton,
} from '@elastic/eui';

import PropTypes from 'prop-types';
import PrivacyPolicy from 'modules/_global/PrivacyPolicy';

const PrivacyModal = ({ isOpen, onClose }) => {
	if (!isOpen) return null;

	return (
		<EuiOverlayMask>
			<EuiModal onClose={onClose}>
				<EuiModalHeader>
					<EuiModalHeaderTitle>Privacy Policy</EuiModalHeaderTitle>
				</EuiModalHeader>

				<EuiModalBody>
					<PrivacyPolicy />
				</EuiModalBody>

				<EuiModalFooter>
					<EuiButton fill onClick={onClose}>
						Close
					</EuiButton>
				</EuiModalFooter>
			</EuiModal>
		</EuiOverlayMask>
	);
};

PrivacyModal.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
};

PrivacyModal.defaultProps = {
	isOpen: false,
};

export default PrivacyModal;
