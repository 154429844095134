import React, { useState } from 'react';
import {
	EuiModal,
	EuiModalBody,
	EuiModalHeader,
	EuiModalHeaderTitle,
	EuiOverlayMask,
	EuiLink,
	EuiSpacer,
	EuiDescriptionList,
	EuiHorizontalRule,
	EuiButtonEmpty,
} from '@elastic/eui';

const ContactInformation = () => {
	const [modalVisibility, setModalVisibility] = useState(false);

	const handleOpenModal = () => {
		setModalVisibility(true);
	};

	const handleCloseModal = () => {
		setModalVisibility(false);
	};

	const listItems = [
		{
			title: 'For partnerships',
			description: (
				<EuiLink href="mailto:partnerships@medalert.ph">
					partnerships@medalert.ph
				</EuiLink>
			),
		},
		{
			title: 'Support',
			description: (
				<EuiLink href="mailto:erx-support@medalert.ph">
					erx-support@medalert.ph
				</EuiLink>
			),
		},
		{
			title: 'Facebook',
			description: (
				<EuiLink href="https://www.facebook.com/MedAlertEMR">
					https://www.facebook.com/MedAlertEMR
				</EuiLink>
			),
		},
	];

	const contactInformationModal = (
		<EuiOverlayMask>
			<EuiModal onClose={handleCloseModal}>
				<EuiModalHeader>
					<EuiModalHeaderTitle>Contact Us</EuiModalHeaderTitle>
				</EuiModalHeader>
				<EuiHorizontalRule margin="xs" />
				<EuiModalBody>
					<EuiSpacer />
					<EuiDescriptionList listItems={listItems} />
					<EuiSpacer />
				</EuiModalBody>
			</EuiModal>
		</EuiOverlayMask>
	);

	return (
		<div>
			<EuiButtonEmpty color="primary" onClick={handleOpenModal} size="xs">
				Contact Us
			</EuiButtonEmpty>
			{modalVisibility ? contactInformationModal : null}
		</div>
	);
};

export default ContactInformation;
