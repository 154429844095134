import React, { useState } from 'react';
// import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
	EuiForm,
	EuiFormRow,
	EuiFieldText,
	EuiModal,
	EuiOverlayMask,
	EuiModalHeader,
	EuiModalHeaderTitle,
	EuiModalBody,
	EuiFieldNumber,
	EuiModalFooter,
	EuiButtonEmpty,
	EuiButton,
	EuiSuperSelect,
} from '@elastic/eui';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import PropTypes from 'prop-types';

import Callout from 'components/Callout';
import { getRxItem } from 'modules/prescriptions/prescriptions.fetch';

const LineItemSchema = Yup.object().shape({
	mode: Yup.string(),
	itemName: Yup.string().required('Required'),
	reasonCode: Yup.string().when('mode', {
		is: (mode) => mode === 'edit',
		then: Yup.string().required('Reason Code is required'),
	}),
});

const LineItemModal = ({
	addLineItem,
	onClose,
	selectedItem,
	updateLineItem,
}) => {
	const { t } = useTranslation();
	// const dispatch = useDispatch();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [error, setError] = useState(null);
	const mode = !selectedItem ? 'add' : 'edit';

	const initialValues = selectedItem || {
		itemName: '',
		description: '',
		price: 0,
		quantity: 0,
		reasonCode: null,
	};

	initialValues.mode = mode;

	if (initialValues.reasonCode === null) {
		initialValues.reasonCode = '';
	}

	const { errors, handleChange, handleSubmit, setFieldValue, values } =
		useFormik({
			initialValues,
			onSubmit: async (data) => {
				try {
					setIsSubmitting(true);
					if (data.rxItem) {
						const { data: rxItem } = await getRxItem(data.rxItem);

						if (data.quantity > rxItem.currentQty) {
							throw new Error(
								'Quantity cannot be greater than prescribed quantity',
							);
						}
					}

					switch (mode) {
						case 'edit':
							updateLineItem(data);
							break;
						default:
							addLineItem(data);
							break;
					}
					onClose();
				} catch (err) {
					// log or do something with the error
					setError(err.message || err);
				} finally {
					setIsSubmitting(false);
				}
			},
			validationSchema: LineItemSchema,
		});

	const reasonOpts = [
		{
			value: 'quantity_only',
			inputDisplay: 'Quantity Only',
		},
		{
			value: 'no_stock',
			inputDisplay: 'No Stock',
		},
		{
			value: 'patient_decision',
			inputDisplay: 'Patient Decision',
		},
	];

	return (
		<EuiOverlayMask>
			<EuiModal
				onClose={onClose}
				// TODO: move to stylesheet
				style={{ position: 'relative', zIndex: 1 }}
			>
				<EuiModalHeader>
					<EuiModalHeaderTitle>
						{t(
							mode === 'add'
								? 'addLineItem.title'
								: 'editLineItem.title',
						)}
					</EuiModalHeaderTitle>
				</EuiModalHeader>
				<EuiModalBody>
					<EuiForm>
						<Callout color="danger" value={error} />
						<EuiFormRow
							error={errors.itemName}
							isInvalid={errors.itemName || false}
							label={t('addLineItem.itemTitle')}
						>
							<EuiFieldText
								id="itemName"
								isInvalid={errors.itemName || false}
								name="itemName"
								onChange={handleChange}
								placeholder={t('addLineItem.itemTitle')}
								value={values.itemName}
							/>
						</EuiFormRow>
						<EuiFormRow
							error={errors.brand}
							isInvalid={errors.brand || false}
							label={t('addLineItem.brandName')}
						>
							<EuiFieldText
								id="brand"
								isInvalid={errors.brand || false}
								name="brand"
								onChange={handleChange}
								placeholder={t('addLineItem.brandName')}
								value={values.brand}
							/>
						</EuiFormRow>
						<EuiFormRow
							error={errors.description}
							isInvalid={errors.description || false}
							label={t('addLineItem.description')}
						>
							<EuiFieldText
								id="description"
								isInvalid={errors.description || false}
								name="description"
								onChange={handleChange}
								placeholder={t('addLineItem.description')}
								value={values.description}
							/>
						</EuiFormRow>
						<EuiFormRow
							error={errors.quantity}
							isInvalid={errors.quantity || false}
							label={t('addLineItem.qty')}
						>
							<EuiFieldNumber
								id="quantity"
								isInvalid={errors.quantity || false}
								min={0}
								name="quantity"
								onChange={handleChange}
								placeholder={t('addLineItem.qty')}
								value={values.quantity}
							/>
						</EuiFormRow>
						{mode !== 'add' && (
							<EuiFormRow
								error={errors.reasonCode}
								isInvalid={errors.reasonCode || false}
								label="Reason Code"
							>
								<EuiSuperSelect
									data-testid="reasonCode-input"
									id="reasonCode"
									isInvalid={!!errors.reasonCode}
									name="reasonCode"
									onChange={(value) => {
										setFieldValue('reasonCode', value);
									}}
									options={reasonOpts}
									placeholder="Type"
									valueOfSelected={values.reasonCode}
								/>
							</EuiFormRow>
						)}
					</EuiForm>
				</EuiModalBody>
				<EuiModalFooter>
					<EuiButtonEmpty color="danger" onClick={onClose}>
						{t('general.cancel')}
					</EuiButtonEmpty>

					<EuiButton
						color="primary"
						fill
						isLoading={isSubmitting}
						onClick={handleSubmit}
					>
						{t(mode === 'add' ? 'general.add' : 'general.save')}
					</EuiButton>
				</EuiModalFooter>
			</EuiModal>
		</EuiOverlayMask>
	);
};

LineItemModal.propTypes = {
	addLineItem: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	selectedItem: PropTypes.shape({
		itemName: PropTypes.string,
		description: PropTypes.string,
		price: PropTypes.number,
		quantity: PropTypes.number,
	}),

	updateLineItem: PropTypes.func.isRequired,
};

LineItemModal.defaultProps = {
	selectedItem: null,
};

export default LineItemModal;
