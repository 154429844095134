import initialState from 'store/initialState';
import { ADD_TOAST, REMOVE_TOAST, CLEAR_TOASTS } from 'constants/actionTypes';

export default (state = initialState.toasts, action = null) => {
	switch (action.type) {
		case ADD_TOAST:
			return [...state, action.toast];

		case REMOVE_TOAST:
			return state.filter((toast) => toast.id !== action.toast.id);

		case CLEAR_TOASTS:
			return [];

		default:
			return state;
	}
};
