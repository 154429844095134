import api from 'utils/api';
import { thenCallbackRQ } from 'utils/helpers';

const { REACT_APP_PUSH_SERVER_PUBLIC_KEY } = process.env;

function isPushNotificationSupported() {
	return (
		'serviceWorker' in navigator &&
		'PushManager' in window &&
		'Notification' in window
	);
}

async function askUserPermission() {
	return Notification.requestPermission();
}

async function registerServiceWorker() {
	return navigator.serviceWorker.register('/sw.js');
}

async function createPushNotificationEndpoint() {
	const serviceWorker = await navigator.serviceWorker.ready;

	const subscription = await serviceWorker.pushManager.subscribe({
		userVisibleOnly: true,
		applicationServerKey: REACT_APP_PUSH_SERVER_PUBLIC_KEY,
	});

	return api
		.post('/push-notification-subscriptions', { subscription })
		.then(thenCallbackRQ);
}

async function getDeviceSubscription() {
	return navigator.serviceWorker.ready
		.then((serviceWorker) => {
			return serviceWorker.pushManager.getSubscription();
		})
		.then((pushSubscription) => {
			return pushSubscription;
		});
}

async function getSubscription(params) {
	return api
		.get('/push-notification-subscriptions', { params })
		.then(thenCallbackRQ);
}

async function putSubscribeUser(id) {
	return api
		.put(`/push-notification-subscriptions/${id}`)
		.then(thenCallbackRQ);
}

async function readNotification(id) {
	return api.get(`/notifications/${id}/read`);
}

export {
	createPushNotificationEndpoint,
	askUserPermission,
	getDeviceSubscription,
	getSubscription,
	isPushNotificationSupported,
	putSubscribeUser,
	registerServiceWorker,
	readNotification,
};
