/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { EuiBasicTable, EuiDescriptionList, EuiSpacer } from '@elastic/eui';

import PropTypes from 'prop-types';

import ContactDetails from 'modules/_global/ContactDetails';
import Print from 'components/Print';

const PrintOrder = ({
	address,
	contactDetails,
	lineItems,
	name,
	orderId,
	packingCode,
	prescriptionCode,
	size = 'm',
	...props
}) => {
	const columns = [
		{
			field: 'itemName',
			name: 'NAME',
		},
		{
			field: 'brand',
			name: 'BRAND',
		},
		{
			field: 'quantity',
			name: 'QTY',
			dataType: 'number',
			width: '10%',
		},
	];

	const descriptionList = [
		{
			title: 'Order #',
			description: orderId,
		},
		{
			title: 'Packing Code',
			description: packingCode || '-',
		},
		{
			title: 'Prescription',
			description: prescriptionCode,
		},
		{
			title: 'Name',
			description: name,
		},
		{
			title: 'Address',
			description: address,
		},
		{
			title: 'Contact Numbers',
			description: (
				<ContactDetails
					hideEmails
					ids={contactDetails}
					plain
					showType="mobile"
				/>
			),
		},
	];

	return (
		<Print {...props} size={size}>
			<EuiDescriptionList
				compressed
				listItems={descriptionList}
				style={{
					maxWidth: 400,
				}}
				type="column"
			/>
			<EuiSpacer size="s" />
			<EuiBasicTable columns={columns} items={lineItems} />
		</Print>
	);
};

PrintOrder.propTypes = {
	address: PropTypes.string.isRequired,
	contactDetails: PropTypes.arrayOf(PropTypes.string).isRequired,
	lineItems: PropTypes.arrayOf(
		PropTypes.shape({
			itemName: PropTypes.string,
			brand: PropTypes.string,
			quantity: PropTypes.string,
		}),
	).isRequired,
	name: PropTypes.string.isRequired,
	orderId: PropTypes.string.isRequired,
	packingCode: PropTypes.string.isRequired,
	prescriptionCode: PropTypes.string.isRequired,
	size: PropTypes.oneOf(['m', 's']),
};

PrintOrder.defaultProps = {
	size: 'm',
};

export default PrintOrder;
