import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
	EuiHorizontalRule,
	EuiFlexGroup,
	EuiFlexItem,
	EuiButton,
	EuiSpacer,
} from '@elastic/eui';
import Table from 'components/Table';
import ConfirmationModal from 'components/ConfirmationModal';
import { setLightboxData } from 'modules/lightbox/lightbox.actions';
import AddLicense from './AddLicense';
import { addToast } from '../toasts/toasts.actions';
import { getLicenses, deleteLicense } from './licenses.fetch';

const Licenses = ({ setHeaderContent }) => {
	const dispatch = useDispatch();
	const [addLicenseVisible, setAddLicenseVisible] = useState(false);
	const [licenses, setLicenses] = useState([]);
	const [selectedLicense, setSelectedLicense] = useState(null);
	const [isConfirmDeleteModalVisisble, setIsConfirmDeleteModalVisible] =
		useState(false);
	const [, setIsLoading] = useState(false);

	useEffect(() => {
		const fetchLicenses = async () => {
			try {
				setIsLoading(true);
				const { data } = await getLicenses();
				setLicenses(data);
			} catch (err) {
				dispatch(addToast('Error', err.message, 'danger', 'help'));
			} finally {
				setIsLoading(false);
			}
		};
		fetchLicenses();
	}, []);

	const handleCloseAddLicense = () => {
		setAddLicenseVisible(false);
	};

	useEffect(() => {
		if (setAddLicenseVisible) {
			setHeaderContent(
				<AddLicense
					onClose={handleCloseAddLicense}
					setLicenses={setLicenses}
					visible={addLicenseVisible}
				/>,
			);
		} else {
			setHeaderContent(null);
		}
	}, [addLicenseVisible]);

	const handleDelete = (data) => {
		setIsConfirmDeleteModalVisible(true);
		setSelectedLicense(data);
	};

	const removeLicense = async (data) => {
		setIsLoading(true);
		try {
			await deleteLicense(data.id);
			const updatedLicenses = licenses.filter(
				(contact) => contact.id !== data.id,
			);
			setLicenses(updatedLicenses);
			dispatch(
				addToast('Success', 'License Deleted', 'success', 'check'),
			);
		} catch (err) {
			dispatch(addToast('Error', err.message, 'danger', 'help'));
		} finally {
			setIsLoading(false);
		}
	};

	const confirmDeleteModal = isConfirmDeleteModalVisisble ? (
		<ConfirmationModal
			message="Are you sure you want to do this"
			onClose={() => {
				setIsConfirmDeleteModalVisible(false);
				setSelectedLicense(null);
			}}
			onConfirm={() => {
				removeLicense(selectedLicense);
				setIsConfirmDeleteModalVisible(false);
				setSelectedLicense(null);
			}}
			title="Delete License"
		/>
	) : null;

	const columns = [
		{
			field: 'licenseNumber',
			name: 'License Number',
		},
		{
			field: 'type',
			name: 'Type',
		},
		{
			field: 'expiry',
			name: 'Expiration Date',
		},
		{
			field: 'validated',
			name: 'Validated',
		},
		{
			field: 'actions',
			name: '',
			actions: [
				{
					name: 'View',
					description: 'View',
					onClick: (data) => {
						dispatch(
							setLightboxData({
								array: [
									{
										title: data.image?.name,
										url: data.image?.url,
									},
								],
								index: 0,
							}),
						);
					},
					icon: 'eye',
					type: 'icon',
					isPrimary: true,
				},
				{
					name: 'Delete',
					description: 'Delete',
					onClick: (data) => handleDelete(data),
					icon: 'trash',
					type: 'icon',
					color: 'danger',
					isPrimary: true,
				},
			],
		},
	];

	const sorting = {
		sort: {
			field: 'expiry',
			direction: 'desc',
		},
	};

	return (
		<>
			<EuiSpacer />
			<EuiFlexGroup
				direction="row"
				gutterSize="m"
				justifyContent="flexEnd"
			>
				<EuiFlexItem grow={false}>
					<EuiFlexGroup responsive={false}>
						<EuiFlexItem>
							<EuiButton
								fill
								iconType="plusInCircleFilled"
								onClick={() => setAddLicenseVisible(true)}
							>
								Add License
							</EuiButton>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
			<EuiHorizontalRule margin="m" />
			<Table
				columns={columns}
				isSelectable
				itemId="id"
				items={licenses}
				sorting={sorting}
			/>
			{confirmDeleteModal}
		</>
	);
};

Licenses.propTypes = {
	setHeaderContent: PropTypes.func.isRequired,
};

export default Licenses;
