import React, { useEffect, useState } from 'react';

import { EuiComboBox } from '@elastic/eui';

import PropTypes from 'prop-types';

import {
	getBranchesByVendorCity,
	getBranchesByVendorNearest,
	getBranchInfo,
} from 'modules/branches/branches.fetch';

const BranchDropdown = ({
	cityId,
	customFetchGetBranch,
	deliveryAddress,
	forceCity,
	name,
	onChange,
	optionCount,
	pickup = false,
	value,
	vendor,
	...props
}) => {
	const [options, setOptions] = useState([]);
	const [selected, setSelected] = useState([]);
	const [loading, setLoading] = useState(false);

	const handleChange = (e) => {
		const [selectedOption] = e;

		if (!selectedOption) {
			if (onChange) onChange(null);
			return setSelected([]);
		}

		if (onChange) onChange(selectedOption);
		return setSelected(e);
	};

	const processBranchOptions = (branch) => ({
		value: branch.id,
		label: `${
			branch.distanceKm ? `${branch.distanceKm.toFixed(1)}km ` : ''
		}${branch.name} ${branch.address ? `- ${branch.address.city}` : ''}`,
	});

	useEffect(() => {
		async function fetchBranches() {
			if (!vendor) return;
			try {
				setLoading(true);
				let res;
				if (!deliveryAddress || forceCity) {
					const { data } = await getBranchesByVendorCity(
						vendor,
						cityId,
						pickup,
					);
					res = data;
				} else {
					const { data } = await getBranchesByVendorNearest(
						vendor,
						deliveryAddress,
						pickup,
					);
					res = data;
				}
				console.log({ res }); // eslint-disable-line no-console
				res = res.slice(0, optionCount);
				const opts = res ? res.map(processBranchOptions) : [];
				setOptions(opts);
			} catch (err) {
				setOptions([]);
			} finally {
				setLoading(false);
			}
		}

		const revertSelection = () => {
			setSelected([]);
			setOptions([]);
			return onChange(null);
		};

		if (vendor && cityId) {
			fetchBranches();
		}

		if (!vendor || !cityId) {
			revertSelection();
		}
	}, [vendor, cityId]);

	useEffect(() => {
		const fetchCurrentValue = async () => {
			const result = await getBranchInfo(value);

			const processed = processBranchOptions(result);

			if (!options) setOptions([processed]);
			setSelected([processed]);
		};

		if (value) fetchCurrentValue();

		if (!value) setSelected([]);
	}, [value]);

	return (
		<EuiComboBox
			async
			isLoading={loading}
			name={name}
			onChange={handleChange}
			options={options}
			placeholder="Select branch"
			selectedOptions={selected}
			singleSelection={{ asPlainText: true }}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		/>
	);
};

BranchDropdown.propTypes = {
	cityId: PropTypes.string,
	customFetchGetBranch: PropTypes.func,
	deliveryAddress: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number,
	}),
	forceCity: PropTypes.bool,
	name: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	optionCount: PropTypes.number,
	pickup: PropTypes.bool,
	value: PropTypes.string,
	vendor: PropTypes.string,
};

BranchDropdown.defaultProps = {
	cityId: '',
	customFetchGetBranch: null,
	deliveryAddress: null,
	forceCity: false,
	name: null,
	optionCount: 6,
	pickup: false,
	value: null,
	vendor: null,
};

export default BranchDropdown;
