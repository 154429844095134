import React from 'react';
import { EuiModal, EuiModalBody, EuiSpacer } from '@elastic/eui';
import PropTypes from 'prop-types';

import PatientMyInfoForm from './PatientMyInfoForm';

const PatientMyInfoModal = ({ isOpenMyInfo, onClose, user }) => {
	if (!isOpenMyInfo) return null;

	return (
		<EuiModal onClose={onClose}>
			<EuiModalBody>
				<EuiSpacer />
				<PatientMyInfoForm onClose={onClose} user={user} />
			</EuiModalBody>
		</EuiModal>
	);
};

PatientMyInfoModal.propTypes = {
	isOpenMyInfo: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	user: PropTypes.instanceOf(Object).isRequired,
};

PatientMyInfoModal.defaultProps = {
	isOpenMyInfo: false,
};

export default PatientMyInfoModal;
