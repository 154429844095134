import React from 'react';
import {
	EuiLoadingSpinner,
	EuiOverlayMask,
	EuiModal,
	EuiModalHeader,
	EuiModalHeaderTitle,
	EuiModalBody,
	EuiButton,
	EuiModalFooter,
	EuiFlexGroup,
	EuiFlexItem,
	EuiFormLabel,
	EuiDescriptionList,
	EuiBadge,
} from '@elastic/eui';

import PropTypes from 'prop-types';

const BookingModal = ({
	cancelDisable,
	dropoff,
	id,
	isVisible,
	message,
	onCancel,
	onClose,
	// onConfirm,
	// onRefresh,
	// refreshDisable,
	pickup,
	seconds,
	title,
	// ...props
}) => {
	if (!isVisible) {
		return null;
	}

	const deliveryInfo = [
		{
			title: <EuiFormLabel>Pickup</EuiFormLabel>,
			description: pickup,
		},
		{
			title: (
				<>
					<p>
						<EuiBadge color="#009D00" label="d" />
					</p>
					<p>
						<EuiBadge color="#009D00" label="d" />
					</p>
					<p>
						<EuiBadge color="#009D00" label="d" />
					</p>
				</>
			),
			description: '',
		},
		{
			title: <EuiFormLabel>Dropoff</EuiFormLabel>,
			description: dropoff,
		},
	];

	return (
		<EuiOverlayMask key={id}>
			<EuiModal onClose={onClose}>
				<EuiModalHeader>
					<EuiModalHeaderTitle>
						<h1>{title}</h1>
					</EuiModalHeaderTitle>
				</EuiModalHeader>

				<EuiModalBody>
					<EuiFlexGroup
						alignItems="center"
						direction="column"
						justifyContent="center"
						responsive
					>
						<EuiFlexItem>
							<p>{message}</p>
						</EuiFlexItem>
						<EuiFlexItem>
							<EuiLoadingSpinner size="l" />
						</EuiFlexItem>
						<EuiFlexItem>{seconds}</EuiFlexItem>
						<EuiFlexItem>
							<EuiDescriptionList
								compressed
								listItems={deliveryInfo}
								responsive={false}
							/>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiModalBody>

				<EuiModalFooter>
					<EuiButton disabled={cancelDisable} fill onClick={onCancel}>
						Cancel
					</EuiButton>
					{/* <EuiButton
						disabled={refreshDisable}
						fill
						onClick={onRefresh}
					>
						Refresh
					</EuiButton> */}
				</EuiModalFooter>
			</EuiModal>
			{/* <EuiConfirmModal
                // cancelButtonText="Cancel"
                // confirmButtonText="Confirm"
                onCancel={onClose}
                onConfirm={onConfirm}
                title={title}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
            >
                {message}
            </EuiConfirmModal> */}
		</EuiOverlayMask>
	);
};

BookingModal.propTypes = {
	cancelDisable: PropTypes.bool,
	dropoff: PropTypes.string,
	id: PropTypes.string,
	isVisible: PropTypes.bool,
	message: PropTypes.string,
	onCancel: PropTypes.func.isRequired,
	onClose: PropTypes.func,
	pickup: PropTypes.string,
	seconds: PropTypes.number,
	// onConfirm: PropTypes.func.isRequired,
	// onRefresh: PropTypes.func.isRequired,
	// refreshDisable: PropTypes.bool,
	title: PropTypes.string,
};

BookingModal.defaultProps = {
	cancelDisable: true,
	dropoff: '',
	id: null,
	isVisible: true,
	message: null,
	onClose: null,
	// refreshDisable: true,
	pickup: '',
	seconds: 60,
	title: null,
};

export default BookingModal;
