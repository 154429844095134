import React from 'react';

import {
	EuiOverlayMask,
	EuiModal,
	EuiModalHeader,
	EuiModalHeaderTitle,
	EuiModalFooter,
	EuiButton,
	EuiText,
	EuiSpacer,
} from '@elastic/eui';

import PropTypes from 'prop-types';
import { get } from 'lodash';

const PdfModalViewer = ({ file, onClose, small, visible }) => {
	if (!file || !visible) return null;

	return (
		<EuiOverlayMask>
			<EuiModal
				onClose={onClose}
				style={{
					height: 700,
					width: small ? 'auto' : 766,
					position: 'relative',
					zIndex: '1052 !important',
				}}
			>
				<EuiModalHeader>
					<EuiModalHeaderTitle>PDF Attachment</EuiModalHeaderTitle>
				</EuiModalHeader>
				<EuiSpacer size="s" />
				{/* <EuiModalBody
					style={{ alignItems: 'center', overflowY: 'hidden' }}
				> */}

				<iframe
					allow="accelerometer; gyroscope;"
					allowFullScreen
					height="100%"
					src={get(file, 'url')}
					title="Attachment"
					width="100%"
				/>

				{/* </EuiModalBody> */}
				<EuiText>{get(file, 'name')}</EuiText>
				<EuiModalFooter>
					<EuiButton fill onClick={onClose}>
						Close
					</EuiButton>
				</EuiModalFooter>
			</EuiModal>
		</EuiOverlayMask>
	);
};

PdfModalViewer.propTypes = {
	file: PropTypes.shape({ url: PropTypes.string, name: PropTypes.string }),
	onClose: PropTypes.func,
	small: PropTypes.bool,
	visible: PropTypes.bool,
};

PdfModalViewer.defaultProps = {
	file: null,
	onClose: () => {},
	small: false,
	visible: true,
};

export default PdfModalViewer;
