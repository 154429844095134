import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import {
	EuiAvatar,
	EuiFlexGroup,
	EuiFlexItem,
	EuiHeaderSectionItemButton,
	EuiLink,
	EuiPopover,
	EuiText,
} from '@elastic/eui';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ROLE_PATH } from 'constants/protect';
import { PATIENT } from 'components/roles';
import { logoutAction } from 'modules/auth/auth.actions';

const HeaderUserMenu = ({ setIsOpenMyInfo }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const { user } = useSelector((s) => s.auth);
	const { role } = useSelector((state) => ({
		role: _.get(state, ROLE_PATH),
	}));
	const [isOpen, setIsOpen] = useState(false);
	const onLogout = () => {
		dispatch(logoutAction());
		if (role === PATIENT.key) history.push('/login');
	};

	const onMenuButtonClick = () => {
		setIsOpen(!isOpen);
	};

	const closeMenu = () => {
		setIsOpen(false);
	};

	let name = user.username;

	if (_.get(user, 'details.firstName', null)) {
		name = _.get(user, 'details.firstName');
	}

	if (_.get(user, 'details.lastName', null)) {
		name += ` ${_.get(user, 'details.lastName')}`;
	}

	if (_.get(user, 'firstName', null)) {
		name = _.get(user, 'firstName');
	}

	if (_.get(user, 'lastName', null)) {
		name += ` ${_.get(user, 'lastName')}`;
	}

	const avatarButton = (
		<EuiHeaderSectionItemButton
			aria-label="Account menu"
			onClick={onMenuButtonClick}
		>
			<EuiAvatar name={name} size="s" />
		</EuiHeaderSectionItemButton>
	);

	return (
		<EuiPopover
			anchorPosition="downRight"
			button={avatarButton}
			closePopover={closeMenu}
			isOpen={isOpen}
		>
			<EuiFlexGroup
				gutterSize="m"
				responsive={false}
				style={{
					width: '300px',
					alignItems: 'center',
					padding: '0',
				}}
			>
				<EuiFlexItem grow={false}>
					<EuiAvatar name={name} size="xl" />
				</EuiFlexItem>

				<EuiFlexItem>
					<EuiText>{name}</EuiText>
					{role === PATIENT.key && (
						<EuiLink
							onClick={() => {
								setIsOpenMyInfo(true);
								setIsOpen(false);
							}}
						>
							My Info
						</EuiLink>
					)}
					<EuiLink onClick={onLogout}>
						{t('dashboard.logout')}
					</EuiLink>
				</EuiFlexItem>
			</EuiFlexGroup>
		</EuiPopover>
	);
};

export default HeaderUserMenu;

HeaderUserMenu.propTypes = {
	setIsOpenMyInfo: PropTypes.instanceOf(Object).isRequired,
};
