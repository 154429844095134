import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
	EuiSpacer,
	EuiFlexGroup,
	EuiButton,
	EuiFlexItem,
	// EuiButtonEmpty,
	EuiFormRow,
	EuiHorizontalRule,
	EuiFieldText,
	EuiFieldPassword,
} from '@elastic/eui';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import PropTypes from 'prop-types';

// import ConfirmationModal from 'components/ConfirmationModal';

import { getFieldError, getErrorMessage } from '../utils/helpers';
import { addToast } from '../modules/toasts/toasts.actions';

const AccountSchema = Yup.object().shape({});

// eslint-disable-next-line no-unused-vars
const AccountTab = ({ onClose, onSubmit, requireOldPassword, user }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	// const [toggleChangePass, setToggleChangePass] = useState(false);
	const [hasChanges, setHasChanges] = useState(false);
	// const [
	// 	isConfirmCancelChangesModalVisible,
	// 	setIsConfirmCancelChangesModalVisible,
	// ] = useState(false);

	const {
		errors,
		handleChange,
		handleSubmit,
		initialValues,
		resetForm,
		setFieldError,
		touched,
		values,
	} = useFormik({
		initialValues: {
			email: user.email,
			username: user.username,
			oldPassword: '',
			newPassword: '',
			confirmNewPassword: '',
		},
		validationSchema: AccountSchema,
		validateOnBlur: true,
		enableReinitialize: true,
		onSubmit: async (data) => {
			setIsLoading(true);
			try {
				const payload = {
					newPassword: data.newPassword,
					confirmNewPassword: data.confirmNewPassword,
				};

				if (requireOldPassword) {
					payload.oldPassword = data.oldPassword;
				}

				await onSubmit(user.id, payload);

				dispatch(
					addToast(
						'Profile updated',
						'Changes Saved',
						'success',
						'check',
					),
				);
				resetForm({ values: initialValues });
			} catch (err) {
				const fieldError = getFieldError(err);
				if (fieldError) {
					setFieldError(fieldError.field, fieldError.message);
				} else {
					const errorMessage = getErrorMessage(err);
					setError(errorMessage);
				}
			} finally {
				setError(null);
				setIsLoading(false);
			}
		},
	});

	const { confirmNewPassword, email, newPassword, oldPassword, username } =
		values;

	useEffect(() => {
		setHasChanges(_.isEqual(initialValues, values));
	}, [values]);

	// useEffect(() => {
	// 	if (!toggleChangePass) resetForm({ values: initialValues });
	// }, [toggleChangePass]);

	useEffect(() => {
		function toastError() {
			dispatch(addToast('Error', error, 'danger', 'help'));
		}
		if (error) toastError();
	}, [error]);

	// const handleToggleChangePass = () => setToggleChangePass((isOn) => !isOn);

	// const handleCancel = () => setIsConfirmCancelChangesModalVisible(true);

	// const handleCancelConfirmationModal = () => {
	// 	setIsConfirmCancelChangesModalVisible(false);
	// };

	// const handleConfirmConfirmationModal = () => {
	// 	resetForm({ values: initialValues });
	// 	setIsConfirmCancelChangesModalVisible(false);
	// };
	// eslint-disable-next-line no-unused-vars
	const disableButton = Object.keys(errors).length > 0;

	return (
		<>
			<EuiSpacer />
			<EuiFlexGroup>
				<EuiFlexItem>
					<EuiFormRow label={t('account.email')}>
						<EuiFieldText
							data-testid="email-input"
							id="email"
							name="email"
							onChange={handleChange}
							readOnly
							value={email}
						/>
					</EuiFormRow>
					<EuiFormRow label={t('account.username')}>
						<EuiFieldText
							data-testid="username-input"
							id="username"
							name="username"
							onChange={handleChange}
							readOnly
							value={username}
						/>
					</EuiFormRow>
				</EuiFlexItem>
				<EuiFlexItem>
					{/* <EuiFormRow hasEmptyLabelSpace>
						<EuiButton
							fill={toggleChangePass}
							iconType={toggleChangePass ? 'lockOpen' : 'lock'}
							isSelected={toggleChangePass}
							onClick={handleToggleChangePass}
						>
							Change Password
						</EuiButton>
					</EuiFormRow> */}
					{requireOldPassword && (
						<EuiFormRow
							error={touched.oldPassword && errors.oldPassword}
							isInvalid={
								touched.oldPassword && !!errors.oldPassword
							}
							label={t('account.oldPassword')}
							type="column"
						>
							<EuiFieldPassword
								data-testid="oldPassword-input"
								// disabled={!toggleChangePass}
								id="oldPassword"
								isInvalid={
									touched.oldPassword && !!errors.oldPassword
								}
								name="oldPassword"
								onChange={handleChange}
								type="dual"
								value={oldPassword}
							/>
						</EuiFormRow>
					)}
					<EuiFormRow
						error={touched.newPassword && errors.newPassword}
						isInvalid={touched.newPassword && !!errors.newPassword}
						label={t('account.newPassword')}
						type="column"
					>
						<EuiFieldPassword
							data-testid="newPassword-input"
							// disabled={!toggleChangePass}
							id="newPassword"
							isInvalid={
								touched.newPassword && !!errors.newPassword
							}
							name="newPassword"
							onChange={handleChange}
							type="dual"
							value={newPassword}
						/>
					</EuiFormRow>
					<EuiFormRow
						error={
							touched.confirmNewPassword &&
							errors.confirmNewPassword
						}
						isInvalid={
							touched.confirmNewPassword &&
							!!errors.confirmNewPassword
						}
						label={t('account.confirmNewPassword')}
						type="column"
					>
						<EuiFieldPassword
							data-testid="confirmNewPassword-input"
							// disabled={!toggleChangePass}
							id="confirmNewPassword"
							isInvalid={
								touched.confirmNewPassword &&
								!!errors.confirmNewPassword
							}
							name="confirmNewPassword"
							onChange={handleChange}
							type="dual"
							value={confirmNewPassword}
						/>
					</EuiFormRow>
					<EuiFormRow>
						<EuiButton
							disabled={isLoading || hasChanges}
							fill
							iconType={hasChanges ? 'lock' : 'lockOpen'}
							isLoading={isLoading}
							onClick={handleSubmit}
						>
							Update Password
						</EuiButton>
					</EuiFormRow>
				</EuiFlexItem>
			</EuiFlexGroup>
			<EuiHorizontalRule margin="l" />
		</>
	);
};

AccountTab.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func.isRequired,
	requireOldPassword: PropTypes.bool,
	user: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

AccountTab.defaultProps = {
	onClose: null,
	requireOldPassword: true,
};

export default AccountTab;
