import initialState from 'store/initialState';
import {
	CREATE_RX_SUCCESS,
	UPDATE_RX_SUCCESS,
	DELETE_RX_SUCCESS,
	FIND_RX_SUCCESS,
	DELETE_MULTIPLE_RX_SUCCESS,
} from 'constants/actionTypes';

export default (state = initialState.prescriptions, action = null) => {
	switch (action.type) {
		case FIND_RX_SUCCESS:
			return [...action.data];

		case CREATE_RX_SUCCESS:
			return [action.data, ...state];

		case UPDATE_RX_SUCCESS:
			return state.map((rx) => {
				if (rx.id === action.data.id) return action.data;

				return rx;
			});

		case DELETE_RX_SUCCESS:
			return state.filter((rx) => rx.id !== action.data.id);

		case DELETE_MULTIPLE_RX_SUCCESS: {
			const selectedRxIndexes = action.data.map((rx) =>
				state.findIndex((rxItem) => rxItem.id === rx.id),
			);
			const updatedRxItems = state.filter(
				(rxItem, index) => !selectedRxIndexes.includes(index),
			);
			return updatedRxItems;
		}

		default:
			return state;
	}
};
