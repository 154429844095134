import React, { useEffect, useRef, useState } from 'react';

import {
	EuiFlexItem,
	EuiSpacer,
	EuiFlexGroup,
	EuiComment,
	EuiImage,
	EuiText,
	EuiAvatar,
	EuiButton,
	EuiTextArea,
	EuiFieldText,
	EuiPopover,
	EuiButtonIcon,
	EuiContextMenuPanel,
	EuiContextMenuItem,
	EuiShowFor,
} from '@elastic/eui';

import { FiSend, FiCamera, FiFile } from 'react-icons/fi';
import { FaWindowClose } from 'react-icons/fa';

import { get } from 'lodash';
import moment from 'moment';

import PropTypes from 'prop-types';

import PdfThumbnail from '../../../components/PdfThumbnail';

const CommentForm = ({
	attachments,
	branchName,
	deletedFiles,
	event,
	hasActions,
	id,
	isLoading,
	onCancel,
	onChange,
	onDelete,
	onSubmit,
	readOnly,
	resetForm,
	role,
	setFieldTouched,
	timestamp,
	username,
	value,
}) => {
	const inputRef = useRef();
	const imageUploadRef = useRef();
	const fileUploadRef = useRef();
	const [isOpenTextArea, setIsOpenTextArea] = useState(false);
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [reset, setReset] = useState(0);
	useEffect(() => {
		function focus(isOpen) {
			if (isOpen) {
				inputRef.current.focus();
			}
		}
		focus(isOpenTextArea);
	}, [isOpenTextArea]);

	useEffect(() => {
		function newForm(isSuccess) {
			if (isSuccess) {
				setIsOpenTextArea(false);
				setIsPopoverOpen(false);
			}
		}

		newForm(resetForm);
	}, [resetForm]);

	const handleTogglePopover = () => setIsPopoverOpen((isOpen) => !isOpen);

	const handleFilePicker = (e) => {
		const fileList = get(e, 'target.files');
		setFieldTouched('attachments', true);
		const files = Array.from(fileList).map((file) => file);

		if (!files.length) return onChange('attachments', attachments);
		if (attachments.length) {
			const filesId = attachments
				.filter((file) => file.id)
				.map((file) => file.id);
			onChange('deletedFiles', [...deletedFiles, filesId]);
			setReset(Math.random().toString(36));
			onChange('attachments', []);
		}
		return onChange('attachments', files);
	};

	const handleChangeComment = (e) => {
		setFieldTouched('comment', true);
		return onChange('comment', e.target.value);
	};

	const handleEdit = () => {
		setIsOpenTextArea((isOpen) => !isOpen);
		setIsPopoverOpen((isOpen) => !isOpen);
	};

	const handleCancel = () => {
		setIsOpenTextArea((isOpen) => !isOpen);
		onCancel();
	};

	const handleRemoveData = (fileId, fileIndex) => {
		const files = attachments.filter((file, index) =>
			fileId ? file.id !== fileId : index !== fileIndex,
		);
		onChange('attachments', files);
		return onChange('deletedFiles', [...deletedFiles, fileId]);
	};

	const pdf = (file) => (
		<>
			<PdfThumbnail data={file} size={240} />
			<EuiSpacer />
			<span style={{ fontSize: 10 }}>{file.name}</span>
		</>
	);

	const image = (url) => {
		return (
			<>
				<EuiShowFor sizes={['xs', 's']}>
					<EuiImage
						key={url}
						allowFullScreen
						className="thumbnail-img"
						// size={size ? 'm' : 's'}
						style={{
							objectFit: 'cover',
							width: 120,
							height: 90,
						}}
						url={url}
					/>
				</EuiShowFor>
				<EuiShowFor sizes={['xl', 'l', 'm']}>
					<EuiImage
						key={url}
						allowFullScreen
						className="thumbnail-img"
						// size={size ? 'l' : 'm'}
						style={{
							objectFit: 'cover',
							width: 240,
							height: 180,
						}}
						url={url}
					/>
				</EuiShowFor>
			</>
		);
	};

	// eslint-disable-next-line consistent-return
	const getRenderType = (file) => {
		const { name: fileName, url } = file;

		// eslint-disable-next-line default-case
		switch (file.type || file.mime) {
			case 'application/pdf':
				return pdf(file);
			case 'image/png':
				return image(url, fileName);
			case 'image/jpeg':
				return image(url, fileName);
			case 'image/gif':
				return image(url, fileName);
		}
	};

	const renderFiles = (files, showRemoveButton) => {
		if (!files.length) return null;

		return files.map((file, index) => {
			const currentFile = file;

			if (currentFile.provider === 'local') {
				currentFile.url = `${process.env.REACT_APP_API_URL}/${currentFile.url}`;
			}

			if (!currentFile.url) {
				currentFile.url = URL.createObjectURL(file);
			}
			return (
				<div key={file.id || file.name + index}>
					<div
						style={{
							width: 'auto',
							padding: '10px',
							position: 'relative',
							zIndex: 1,
						}}
					>
						{showRemoveButton && (
							<EuiButtonIcon
								color="ghost"
								iconType="cross"
								onClick={() => handleRemoveData(file.id, index)}
								size="m"
								style={{
									// width: '70px',
									height: 'auto',
									background: '#898989',
									fontWeight: 'bolder',
									// color: '#FFF',
									// borderRadius: '25px',
									// border: '2px solid #888888',
									// padding: '2px',
									textAlign: 'center',
									top: 0,
									position: 'absolute',
									right: 0,
									zIndex: 3,
								}}
							/>
						)}
						{getRenderType(currentFile)}
					</div>
				</div>
			);
		});
	};

	const avatar = <EuiAvatar name={username} size="l" />;

	const customActions = (
		<EuiPopover
			anchorPosition="leftCenter"
			button={
				<EuiButtonIcon
					aria-label="Actions"
					color="text"
					iconType="gear"
					onClick={handleTogglePopover}
					size="s"
				/>
			}
			closePopover={handleTogglePopover}
			isOpen={isPopoverOpen}
			panelPaddingSize="none"
		>
			<EuiContextMenuPanel
				items={[
					<EuiContextMenuItem
						key="A"
						icon="pencil"
						onClick={handleEdit}
					>
						Edit
					</EuiContextMenuItem>,
					<EuiContextMenuItem
						key="B"
						icon="trash"
						onClick={() => onDelete(id)}
					>
						Delete
					</EuiContextMenuItem>,
					// <EuiContextMenuItem
					// 	key="C"
					// 	icon="copy"
					// 	onClick={handleTogglePopover}
					// >
					// 	Copy
					// </EuiContextMenuItem>,
				]}
			/>
		</EuiPopover>
	);

	const writeComment = () => (
		<>
			<EuiTextArea
				disabled={isLoading}
				fullWidth
				inputRef={inputRef}
				name="comment"
				onChange={handleChangeComment}
				value={value}
			/>
			<EuiSpacer size="s" />
			<EuiFlexGroup direction="row" gutterSize="xs">
				{renderFiles(attachments, true)}
				<EuiFlexGroup
					direction="row"
					gutterSize="xs"
					justifyContent="flexEnd"
				>
					<input
						key={reset}
						ref={fileUploadRef}
						// id="attachments"
						accept="application/pdf"
						onChange={handleFilePicker}
						style={{ display: 'none' }}
						type="file"
					/>
					<EuiButtonIcon
						// id={useGeneratedHtmlId}
						aria-label="File Upload"
						iconType={() => <FiFile size={16} />}
						onClick={() => fileUploadRef.current.click()}
					/>
					<input
						key={reset}
						ref={imageUploadRef}
						// id="attachments"
						accept="image/*"
						onChange={handleFilePicker}
						style={{ display: 'none' }}
						type="file"
					/>
					<EuiButtonIcon
						// id={useGeneratedHtmlId}
						aria-label="Image Upload"
						iconType={() => <FiCamera size={16} />}
						onClick={() => imageUploadRef.current.click()}
					/>
				</EuiFlexGroup>
			</EuiFlexGroup>
			<EuiSpacer />
			<EuiFlexGroup direction="row" gutterSize="xs">
				<EuiFlexItem grow={false}>
					<EuiButton
						disabled={!value && !attachments.length}
						iconType={() => <FiSend size={14} />}
						isLoading={isLoading}
						onClick={onSubmit}
						size="s"
					>
						{!id ? 'Send' : 'Update'}
					</EuiButton>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<EuiButton
						iconType={() => <FaWindowClose size={14} />}
						onClick={handleCancel}
						size="s"
					>
						Cancel
					</EuiButton>
				</EuiFlexItem>
			</EuiFlexGroup>
		</>
	);

	const readOnlyComment = () => (
		<>
			<EuiText size="s">{value}</EuiText>
			{/* {currentFile && currentFile.url ? (
				<>
					<EuiSpacer size="m" />
					<EuiFlexGroup gutterSize="xs" justifyContent="flexStart">
						<EuiImage
							allowFullScreen
							hasShadow
							size="m"
							src={currentFile.url}
						/>
					</EuiFlexGroup>
				</>
			) : null} */}
			{renderFiles(attachments, false)}
		</>
	);
	const placeholderComment = () => (
		<EuiFieldText
			fullWidth
			onFocus={handleEdit}
			placeholder="Add a comment..."
		/>
	);

	return (
		<EuiComment
			key={timestamp}
			actions={!isOpenTextArea && id && hasActions && customActions}
			event={event}
			timelineIcon={avatar}
			timestamp={
				timestamp ? (
					<EuiText size="s">
						{`${moment(
							timestamp,
						).fromNow()} - ${branchName} ${role}`}
					</EuiText>
				) : null
			}
			type="regular"
			username={username}
		>
			{!isOpenTextArea && !readOnly ? placeholderComment() : null}
			{isOpenTextArea && !readOnly ? writeComment() : null}
			{isOpenTextArea && readOnly ? writeComment() : null}
			{!isOpenTextArea && readOnly ? readOnlyComment() : null}
		</EuiComment>
	);
};

CommentForm.propTypes = {
	attachments: PropTypes.arrayOf(PropTypes.shape({})),
	branchName: PropTypes.string.isRequired,
	deletedFiles: PropTypes.arrayOf(PropTypes.string),
	event: PropTypes.string,
	hasActions: PropTypes.bool,
	id: PropTypes.string,
	isLoading: PropTypes.bool,

	onCancel: PropTypes.func,
	onChange: PropTypes.func,
	onDelete: PropTypes.func,
	onSubmit: PropTypes.func,
	readOnly: PropTypes.bool,
	resetForm: PropTypes.bool,
	role: PropTypes.string,
	setFieldTouched: PropTypes.func,
	timestamp: PropTypes.string,
	username: PropTypes.string.isRequired,
	value: PropTypes.string,
};

CommentForm.defaultProps = {
	attachments: [],
	deletedFiles: [],
	event: '',
	hasActions: false,
	id: '',
	isLoading: false,
	onCancel: () => {},
	onChange: () => {},
	onDelete: () => {},
	onSubmit: () => {},
	readOnly: false,
	resetForm: false,
	role: '',
	setFieldTouched: () => {},
	timestamp: '',
	value: '',
};

export default CommentForm;
