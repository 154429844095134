export const CONFIG = {
	timeout: 30000,
};

/**
 * Add a leading slash for each endpoint
 *
 * e.g. AUTH: '/auth',
 */
export const ENDPOINTS = {
	PRESCRIPTIONS: '/prescriptions',
	ORDERS: '/orders',
};
