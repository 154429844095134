import FormData from 'form-data';
import api from 'utils/api';

export const createLicense = async (payload) => {
	const data = new FormData();
	const { image, ...payloadData } = payload;
	data.append('data', JSON.stringify(payloadData));

	data.append('files.image', image, image.name);

	const result = await api.post('/licenses', data, {
		headers: {
			'content-type': 'multipart/form-data',
		},
	});

	return result;
};

export const getLicenses = async () => {
	return api.get('/licenses');
};

export const deleteLicense = async (id) => {
	return api.delete(`/licenses/${id}`);
};
