/* eslint-disable no-irregular-whitespace */
// eslint-disable-next-line import/prefer-default-export
import api from '../../utils/api';

export const createUser = async (payload) => api.post('/users', payload);

export const getUsers = async () => api.get('/users');

export const getUser = async (id) => api.get(`/users/${id}`);

export const updateUser = async (id, payload) =>
	api.put(`/users/${id}`, payload);
