/* eslint-disable react/no-unknown-property */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';

import {
	EuiImage,
	EuiPage,
	EuiPageBody,
	EuiPanel,
	EuiPageSection,
	EuiFlexGroup,
	EuiFlexItem,
	EuiText,
	EuiLoadingContent,
	EuiHideFor,
	EuiSpacer,
	EuiButtonEmpty,
} from '@elastic/eui';

import PropTypes from 'prop-types';

import Logo from 'assets/images/logo_medium.png';
import { getRegistrationContent } from 'modules/auth/auth.fetch';
import ContactInformation from 'components/ContactInformation';

function RegistrationLayout({ children, containerStyle }) {
	const auth = useSelector((state) => state.auth);
	const [loaded, setLoaded] = useState(false);
	const { data, isLoading } = useQuery(
		'registration-content',
		getRegistrationContent,
		{ enabled: !loaded, onSuccess: () => setLoaded((val) => !val) },
	);
	const history = useHistory();

	// redirect to dashboard if already logged in
	if (auth.isLoggedIn) {
		return <Redirect to="/" />;
	}

	const renderEmbed = () => {
		if (!data.embed) return null;

		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<iframe
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
					frameBorder="0"
					height="197"
					src={`https://www.youtube.com/embed/${data.embed}?autoplay=0&controls=0&&showinfo=0`}
					style={{ maxWidth: '100%' }}
					title="YouTube Embed"
					width="350"
				/>
			</div>
		);
	};

	return (
		<EuiPage
			className="registration-layout-container"
			data-testid="registration-layout-container"
			style={containerStyle}
		>
			<EuiPageBody
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<EuiPageSection
					style={{
						maxWidth: '900px',
						padding: '0',
					}}
				>
					<EuiPanel>
						<EuiFlexGroup>
							<EuiFlexItem className="left-column">
								<EuiSpacer />
								<EuiImage
									alt="ERx Registration"
									size="m"
									url={Logo}
								/>
								<EuiHideFor sizes={['xs', 's']}>
									{isLoading || !data ? (
										<EuiLoadingContent lines={6} />
									) : (
										<>
											<EuiSpacer />
											<EuiText textAlign="left">
												<div
													// eslint-disable-next-line react/no-danger
													dangerouslySetInnerHTML={{
														__html: data.content,
													}}
												/>
											</EuiText>
											<EuiText textAlign="center">
												<h4
													style={{
														color: '#f88e0a',
													}}
												>
													{data.tagline}
												</h4>
											</EuiText>
											<EuiSpacer />
											{renderEmbed()}
											<EuiSpacer />
										</>
									)}
								</EuiHideFor>
							</EuiFlexItem>
							<EuiFlexItem className="right-column">
								<EuiPageSection>{children}</EuiPageSection>
							</EuiFlexItem>
						</EuiFlexGroup>
					</EuiPanel>
				</EuiPageSection>
				<EuiPageSection
					style={{
						maxWidth: '900px',
						padding: '0',
					}}
				>
					<EuiPanel className="footer">
						<EuiFlexGroup
							direction="row"
							gutterSize="none"
							responsive={false}
							wrap
						>
							<EuiFlexItem>
								<EuiButtonEmpty
									color="primary"
									onClick={() => {
										history.push('/privacy-policy');
									}}
									size="xs"
								>
									Privacy Policy
								</EuiButtonEmpty>
							</EuiFlexItem>
							<EuiFlexItem>
								<EuiButtonEmpty
									color="primary"
									onClick={() => {
										history.push('/terms-and-conditions');
									}}
									size="xs"
								>
									Terms and Conditions
								</EuiButtonEmpty>
							</EuiFlexItem>
							<EuiFlexItem>
								<ContactInformation />
							</EuiFlexItem>
						</EuiFlexGroup>
					</EuiPanel>
				</EuiPageSection>
			</EuiPageBody>
		</EuiPage>
	);
}

RegistrationLayout.propTypes = {
	children: PropTypes.node.isRequired,
	containerStyle: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.arrayOf(PropTypes.object),
	]),
};

RegistrationLayout.defaultProps = {
	containerStyle: null,
};

export default RegistrationLayout;
