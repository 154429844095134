import React from 'react';
import { EuiCallOut, EuiSpacer } from '@elastic/eui';

import PropTypes from 'prop-types';

function Callout({ value, ...props }) {
	if (!value) return null;

	return (
		<>
			{/*  eslint-disable-next-line react/jsx-props-no-spreading */}
			<EuiCallOut {...props} title={value} />
			<EuiSpacer size="s" />
		</>
	);
}

Callout.propTypes = {
	value: PropTypes.string,
};

Callout.defaultProps = {
	value: null,
};
export default Callout;
