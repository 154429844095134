import axios from 'axios';
import qs from 'qs';

// const GRAB_URL = 'https://partner-api.stg-myteksi.com'
const instance = axios.create({
	baseURL: process.env.REACT_APP_GRAB_API,
});

instance.defaults.paramsSerializer = (params) => qs.stringify(params);

export function attachToken(token) {
	instance.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export function detachToken() {
	delete instance.defaults.headers.common.Authorization;
}

export default instance;
