import React from 'react';
import { useQuery } from 'react-query';

import { EuiComboBox } from '@elastic/eui';

import PropTypes from 'prop-types';

import { getSpecializations } from 'modules/doctors/doctors.fetch';
import { mapOptions } from 'utils/helpers';

const SpecializationDropdown = ({ onBlur, onChange, selected, ...props }) => {
	const { data: options } = useQuery('specializations', getSpecializations, {
		placeholderData: [],
		select: (data) => {
			const mapFunc = mapOptions('id', 'name');
			return data.map(mapFunc);
		},
	});

	return (
		<EuiComboBox
			onBlur={onBlur}
			onChange={onChange}
			options={options}
			placeholder="Select Specializations"
			selectedOptions={selected}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		/>
	);
};

SpecializationDropdown.propTypes = {
	onBlur: PropTypes.func,
	onChange: PropTypes.func.isRequired,
	selected: PropTypes.arrayOf(PropTypes.string),
};

SpecializationDropdown.defaultProps = {
	// eslint-disable-next-line no-unused-vars
	onBlur: (e) => {},
	selected: [],
};

export default SpecializationDropdown;
