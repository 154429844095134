import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
	EuiFlexItem,
	EuiLink,
	EuiButton,
	EuiFlexGroup,
	EuiTitle,
} from '@elastic/eui';
import Table from 'components/Table';
// import { Link } from 'react-router-dom';
import { createBrand, getBrands } from './brands.fetch';
import { addToast } from '../toasts/toasts.actions';
import AddBrand from './AddBrand';

const Users = () => {
	const { t } = useTranslation();
	const { auth } = useSelector((state) => ({
		auth: state.auth,
	}));
	const { vendor } = auth.user;
	const dispatch = useDispatch();
	const [brands, setBrands] = useState([]);
	const [selection, setSelection] = useState([]);
	const [error, setError] = useState(null);
	// eslint-disable-next-line no-unused-vars
	const [isLoading, setIsLoading] = useState(false);
	const [addBrandVisible, setAddBrandVisible] = useState(false);

	useEffect(() => {
		async function fetBrands() {
			try {
				setIsLoading(true);
				const result = await getBrands();
				setBrands(result.data);
			} catch (err) {
				setError(err.message || 'Something went wrong');
			} finally {
				setError(null);
				setIsLoading(false);
			}
		}
		fetBrands();
	}, []);

	useEffect(() => {
		function toastError() {
			dispatch(addToast('Error', error, 'danger', 'help'));
			setTimeout(() => {
				setError(null);
			}, 2000);
		}
		if (error) toastError();
	}, [error]);

	const selectionProps = {
		selectable: () => true,
		onSelectionChange: (select) => {
			setSelection(select);
		},
		selectableMessage: () => undefined,
		initialSelected: selection,
	};

	const addBrand = async (data) => {
		try {
			const payload = {
				name: data.name,
				description: data.description,
				vendor,
			};
			const result = await createBrand(payload);
			setBrands([...brands, result.data]);
			dispatch(addToast('Success', 'Brand added', 'success', 'check'));
		} catch (err) {
			setError(err.message || 'Something went wrong');
		} finally {
			setError(null);
			setIsLoading(false);
			setAddBrandVisible(false);
		}
	};

	const clearFilters = async () => {
		try {
			setIsLoading(true);
		} catch (err) {
			setError(err.message || 'Something went wrong');
		} finally {
			setError(null);
			setIsLoading(false);
		}
	};

	const renderToolsLeft = () => {
		if (!selection.length) {
			return null;
		}

		return (
			<EuiButton color="danger" fill={false} iconType="trash">
				Delete
			</EuiButton>
		);
	};

	const renderToolsRight = () => [
		<EuiFlexItem key="clearFilter" grow={false} justifyContent="center">
			<EuiButton color="disabled" iconType="broom" onClick={clearFilters}>
				Clear Filters
			</EuiButton>
		</EuiFlexItem>,
	];

	const search = {
		box: {
			incremental: true,
		},
		toolsLeft: renderToolsLeft(),
		toolsRight: renderToolsRight(),
		filters: [
			{
				type: 'field_value_selection',
				field: 'brand',
				name: 'Brand',
				multiSelect: false,
				options: [],
			},
		],
	};

	const action = () => {};

	const columns = [
		{
			field: 'name',
			name: 'Brand Name',
			render: (name) => (
				// <Link to={`/brands/${id}`}>
				<EuiLink>{name}</EuiLink>
				// </Link>
			),
			sortable: true,
		},
		{
			field: 'description',
			name: 'Description',
			sortable: true,
		},
		{
			field: 'createdAt',
			dataType: 'date',
			name: 'Date Created',
			sortable: true,
		},
		{
			actions: [
				{
					name: 'View',
					description: 'View',
					onClick: async (data) => action(data, 'view'),
					icon: 'eye',
					type: 'icon',
					isPrimary: true,
				},
				{
					name: 'Edit',
					description: 'Edit',
					onClick: (data) => action(data, 'edit'),
					icon: 'pencil',
					type: 'icon',
					color: 'primary',
					isPrimary: true,
				},
				{
					name: 'Delete',
					description: 'Delete',
					onClick: (data) => action(data, 'delete'),
					icon: 'trash',
					type: 'icon',
					color: 'blue',
					isPrimary: true,
				},
			],
		},
	];

	return (
		<>
			<Helmet title={t('brands.title')} />

			<EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
				<EuiFlexItem>
					<EuiTitle size="xs">
						<h1>{t('brands.title')}</h1>
					</EuiTitle>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFlexGroup gutterSize="s" justifyContent="flexEnd">
						<EuiFlexItem grow={false}>
							<EuiButton
								fullWidth={false}
								iconType="plusInCircle"
								onClick={() => setAddBrandVisible(true)}
								size="s"
							>
								{t('brands.addBrand')}
							</EuiButton>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
			<Table
				columns={columns}
				isSelectable
				itemId="brandId"
				items={brands}
				search={search}
				selection={selectionProps}
			/>

			<AddBrand
				addBrand={addBrand}
				onClose={() => setAddBrandVisible(false)}
				setError={setError}
				visible={addBrandVisible}
			/>
		</>
	);
};

export default Users;
