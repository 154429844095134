import React from 'react';
import { EuiText, EuiLink, EuiSpacer } from '@elastic/eui';

const PrivacyPolicy = () => (
	<EuiText>
		<h3>Privacy Policy for Unexus Medical Solutions, Inc.</h3>
		<p>
			I hereby provide my consent to the use by Unexus Medical Solutions,
			Inc. (UMSI) of my personal data I provided to, and collected by
			UMSI’s MedAlert ERX web application for the purpose of filling-out
			prescriptions, lab requests and/or other similar services issued by
			my physician/s through UMSI’s MedAlert ERX web application, by
			UMSI’s partner pharmacies or laboratories and for other purposes
			stated in UMSI’s Data Privacy Policy.
		</p>
		<p>
			I have read and understood the provisions of UMSI’s Privacy Notice.
			<EuiLink>https://rx.medalert.ph</EuiLink>
		</p>
		<p>
			I understand that by providing my consent to UMSI to use my personal
			data, I do not waive any of the rights granted to me under the Data
			Privacy Act and other related laws and issuances by responsible
			government agencies, such as, but not limited to the National
			Privacy Commission.
		</p>
		<EuiSpacer />
	</EuiText>
);

export default PrivacyPolicy;
