import React from 'react';
import { EuiText } from '@elastic/eui';

import PropTypes from 'prop-types';

const DropdownOptWSub = ({ description, title }) => (
	<>
		<strong>{title}</strong>
		<EuiText color="subdued" size="s">
			<p className="euiTextColor--subdued">{description}</p>
		</EuiText>
	</>
);

DropdownOptWSub.propTypes = {
	description: PropTypes.string,
	title: PropTypes.string.isRequired,
};

DropdownOptWSub.defaultProps = {
	description: null,
};

export default DropdownOptWSub;
