import React from 'react';
import { EuiText, EuiSpacer } from '@elastic/eui';

const TermsAndConditions = () => {
	return (
		<EuiText>
			<h3>eRx+ Software Contract</h3>
			<p>
				1) I will not use this software in a manner that may compromise
				the doctor-patient confidentiality.
			</p>
			<p>
				2) I am accountable for the confidentiality and safekeeping of
				both my account access credentials (i.e. passwords, login
				details, tablet PIN, etc.) and my Secretary’s account access
				credentials (i.e. passwords, login details, tablet PIN, etc.).
				In the event I forget my password, I am responsible for
				resetting all my passwords to regain access to the app.
			</p>
			<p>
				3) I hereby acknowledge that I was strongly advised by Unexus
				Medical Solutions, Inc. NOT to share my usernames and passwords
				to any unauthorized persons/personnel.
			</p>
			<p>
				4) I hereby authorize Unexus Medical Solutions, Inc. and its
				authorized partners to have limited access to selected parts of
				the patient files made accessible through the application, as
				long as the following criteria are met:
				<ul>
					<li>
						Identifying information such as names, full addresses,
						and contact information of Doctors, Patients, and
						Guardians, are all removed.
					</li>
					<li>The data access is restricted to the following:</li>
					<ul>
						<li>ICD10 or encoded diagnosis</li>
						<li>
							Prescription information such as Date, Time,
							Generic, Brand, Quality, Frequency, Dosage, etc.
						</li>
						<li>
							Demographic information such as Age, Height, Gender,
							Citizenship, HMO, Allergies, City Location,
							Patient’s preferred pharmacy or branch; and
							anonymised medical history and diagnostic test
							results of the Patient
						</li>
						<li>
							In emergency situations such as pandemics or
							instances where Healthcare workers require
							information, Unexus Medical Solutions, Inc. will
							grant temporary access to relevant information
							contained in one&lsquo;s personal data.
						</li>
					</ul>
					<li>
						These and all other data shall be encrypted in the
						tablets and in AWS (cloud backup).
					</li>
				</ul>
			</p>
			<p>
				5) I also hereby authorize Unexus Medical Solutions, Inc.,
				Pharmacies, and Pharmaceutical Companies temporary access to the
				pertinent data required for electronic processing (Electronic
				Prescriptions, Electronic Letter of Approval, etc.), until the
				completion of processing. Pertinent data shall include but are
				not limited to Name, Address, Birthday, Delivery Address,
				Prescription Information, required medical tests, etc.
			</p>
			<p>
				6) In order to access some features of this Site‚ you are
				required to register your email address and provide a password.
				If you register‚ you agree to provide Us with accurate and
				complete registration information‚ and to inform us immediately
				of any updates or other changes to such information.
			</p>
			<p>
				7) In the event there has been an unauthorized disclosure or
				leakage of any of my account access credentials (i.e. username,
				email, password, etc.), I hereby acknowledge that I am
				responsible in immediately reporting such incident to Unexus
				Medical Solutions, Inc., and in immediately changing my
				password. I shall not hold Unexus Medical Solutions, Inc. liable
				for any damage that may arise because of my failure to
				immediately change my password upon discovery of unauthorized
				disclosure or leakage.
			</p>
			<p>
				8) In the event a Remote Device Management (TeamViewer or Knox)
				session is created, I am temporarily allowing Unexus Medical
				Solutions, Inc. to gain access to all my files. I am advised to
				be available during the entire session.
			</p>
			<p>
				9) I hereby confirm that I understand the foregoing and that I
				am voluntarily giving my consent to the processing of my
				Personal Data under the terms and conditions provided above.
				Furthermore, I understand that:
				<ul>
					<li>
						The consent I am giving through this form is in addition
						to any other consent that I may give/have given Unexus
						Medical Solutions’ Inc. regarding the use of my personal
						data (e.g. in relation to medical treatment or
						procedure).
					</li>
					<li>
						The consent will remain in full force until I revoke it
						in writing.
					</li>
				</ul>
			</p>
			<EuiSpacer />
		</EuiText>
	);
};

export default TermsAndConditions;
