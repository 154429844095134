import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	EuiOverlayMask,
	EuiModal,
	EuiModalHeader,
	EuiModalHeaderTitle,
	EuiModalBody,
	EuiModalFooter,
	EuiButton,
} from '@elastic/eui';

import PropTypes from 'prop-types';

import ErxSoftwareContract from 'modules/_global/ErxSoftwareContract';
import { acceptContract, logoutAction } from 'modules/auth/auth.actions';
import { updateErxContractAccepted } from 'modules/auth/auth.fetch';
import { addToast } from 'modules/toasts/toasts.actions';

const ErxContractModal = ({ isOpen, onClose }) => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => ({
		user: state.auth.user,
	}));
	const [isLoading, setIsLoading] = useState(false);

	if (!isOpen) return null;

	const handleAcceptContract = async () => {
		try {
			setIsLoading(true);
			await updateErxContractAccepted(user.details.id);
			dispatch(acceptContract());
			dispatch(
				addToast(
					'Success',
					'eRx+ Software Contract Accepted',
					'success',
					'check',
				),
			);
			onClose();
		} catch (err) {
			const errorResponse = ((err || {}).response || {}).data || {};
			dispatch(
				addToast(
					'Error',
					errorResponse.message || 'Something went wrong',
					'danger',
					'help',
				),
			);
		} finally {
			setIsLoading(false);
		}
	};

	const handleCloseContract = () => {
		dispatch(logoutAction());
	};

	return (
		<EuiOverlayMask>
			<EuiModal onClose={handleCloseContract}>
				<EuiModalHeader>
					<EuiModalHeaderTitle>
						eRx+ Software Contract
					</EuiModalHeaderTitle>
				</EuiModalHeader>

				<EuiModalBody>
					<ErxSoftwareContract />
				</EuiModalBody>

				<EuiModalFooter>
					<EuiButton
						fill
						isLoading={isLoading}
						onClick={handleAcceptContract}
					>
						Accept
					</EuiButton>
				</EuiModalFooter>
			</EuiModal>
		</EuiOverlayMask>
	);
};

ErxContractModal.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
};

ErxContractModal.defaultProps = {
	isOpen: false,
};

export default ErxContractModal;
