import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	EuiFlyout,
	EuiFlyoutHeader,
	EuiFlyoutBody,
	EuiFlyoutFooter,
	EuiFlexGroup,
	EuiFlexItem,
	EuiButtonEmpty,
	EuiButton,
	EuiText,
	EuiForm,
	EuiFormRow,
	EuiFieldText,
	EuiSpacer,
	EuiFieldPassword,
} from '@elastic/eui';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { getApiError, getFieldError } from 'utils/helpers';
import { secretaryRegistration } from '../auth/auth.fetch';
import { addToast } from '../toasts/toasts.actions';

const SecretarySchema = Yup.object().shape({
	firstName: Yup.string().required('First Name required'),
	lastName: Yup.string().required('Last Name required'),
	username: Yup.string()
		.min(4, 'Username must be minimum of 4 characters')
		.max(50, 'Too Long!')
		.required('Username Required'),
	email: Yup.string().email('Invalid email').required('Email Required'),
	password: Yup.string().required('Password is required'),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref('password'), null], "Passwords don't match")
		.required('Password must be confirmed'),
});

// eslint-disable-next-line react/prop-types
const AddSecretary = ({ onClose, setSecretaries, visible }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const {
		errors,
		handleChange,
		handleSubmit,
		initialValues,
		resetForm,
		setErrors,
		setFieldError,
		setFieldValue,
		touched,
		values,
	} = useFormik({
		initialValues: {
			email: '',
			mobile: '',
			username: '',
			password: '',
			confirmPassword: '',
			firstName: '',
			middleName: '',
			lastName: '',
		},
		validateOnBlur: true,
		validateOnChange: false,
		validationSchema: SecretarySchema,
		onSubmit: async (data) => {
			setIsLoading(true);
			try {
				const payload = {
					username: data.username,
					email: data.email,
					password: data.password,
					confirmPassword: data.confirmPassword,
					firstName: data.firstName,
					middleName: data.middleName,
					lastName: data.lastName,
				};

				const contactDetails = [];
				if (data.mobile) {
					contactDetails.push({
						type: 'mobile',
						value: data.mobile,
					});
				}

				if (contactDetails.length)
					payload.contactDetails = contactDetails;

				const newSecretary = await secretaryRegistration(payload);

				setSecretaries((secretaries) => [
					...secretaries,
					newSecretary.data,
				]);
				dispatch(
					addToast('Success', 'Secretary added', 'success', 'check'),
				);
				onClose();
				resetForm({ values: initialValues });
			} catch (err) {
				const fieldError = getFieldError(err);

				if (fieldError) {
					setFieldError(fieldError.field, fieldError.message);
				} else {
					const apiError = getApiError(err);

					if (apiError && apiError.field) {
						setFieldError(apiError.field, apiError.message);
					} else {
						dispatch(
							addToast(
								'Error',
								apiError.message,
								'danger',
								'help',
							),
						);
					}
				}
			} finally {
				setIsLoading(false);
			}
		},
	});

	const {
		email,
		firstName,
		lastName,
		middleName,
		mobile,
		password,
		username,
	} = values;

	const handleValidation = (data, field) => {
		const err = { ...errors };
		const isPasswordField = field === 'password';

		SecretarySchema.validateAt(field, data, {
			abortEarly: !isPasswordField,
		})
			.then(() => {
				delete err[field];
				setErrors(err);
			})
			.catch((e) => {
				if (isPasswordField) return setFieldError(field, e.errors);
				return setFieldError(field, e.errors);
			});
	};

	const handleClose = () => {
		onClose();
		resetForm({ values: initialValues });
	};

	if (!visible) return null;

	return (
		<EuiFlyout
			closeButtonAriaLabel="Close Add Prescription"
			onClose={handleClose}
			size="s"
		>
			<EuiFlyoutHeader hasBorder>
				<EuiText>
					<h3>Add Secretary</h3>
				</EuiText>
			</EuiFlyoutHeader>
			<EuiFlyoutBody>
				<EuiForm>
					<EuiFlexGroup>
						<EuiFlexItem>
							<EuiSpacer />
							<EuiFormRow
								error={touched.username && errors.username}
								isInvalid={
									touched.username && !!errors.username
								}
								label={t('users.username')}
								type="column"
							>
								<EuiFieldText
									data-testid="username-input"
									id="username"
									isInvalid={
										touched.username && !!errors.username
									}
									name="username"
									onChange={handleChange}
									placeholder={t('register.username')}
									value={username}
								/>
							</EuiFormRow>
							<EuiFormRow
								error={errors.password}
								isInvalid={!!errors.password}
								label={t('users.password')}
							>
								<EuiFieldPassword
									data-testid="password-input"
									id="password"
									isInvalid={!!errors.password}
									name="password"
									onChange={(e) => {
										setFieldValue(
											'password',
											e.target.value,
										);
										handleValidation(
											{
												password: e.target.value,
											},
											'password',
										);
									}}
									placeholder={t('register.password')}
									type="dual"
									value={password}
								/>
							</EuiFormRow>
							<EuiFormRow
								error={errors.confirmPassword}
								isInvalid={!!errors.confirmPassword}
								label={t('register.confirmPassword')}
							>
								<EuiFieldPassword
									data-testid="confirmPassword-input"
									id="confirmPassword"
									isInvalid={!!errors.confirmPassword}
									name="confirmPassword"
									onChange={handleChange}
									placeholder={t('register.confirmPassword')}
									type="dual"
									value={values.confirmPassword}
								/>
							</EuiFormRow>
							<EuiFormRow
								error={errors.firstName}
								isInvalid={!!errors.firstName}
								label="First Name"
							>
								<EuiFieldText
									data-testid="firstName-input"
									// icon="user"
									id="firstName"
									isInvalid={!!errors.firstName}
									name="firstName"
									onChange={handleChange}
									placeholder="First Name"
									value={firstName}
								/>
							</EuiFormRow>
							<EuiFormRow
								// error={errors.middleName}
								// isInvalid={!!errors.middleName}
								label="Middle Name"
							>
								<EuiFieldText
									data-testid="middleName-input"
									// icon="user"
									id="middleName"
									// isInvalid={!!errors.middleName}
									name="middleName"
									onChange={handleChange}
									placeholder="Middle Name"
									value={middleName}
								/>
							</EuiFormRow>
							<EuiFormRow
								error={errors.lastName}
								isInvalid={!!errors.lastName}
								label="Last Name"
							>
								<EuiFieldText
									data-testid="lastName-input"
									// icon="user"
									id="lastName"
									// isInvalid={!!errors.lastName}
									name="lastName"
									onChange={handleChange}
									placeholder="Last Name"
									value={lastName}
								/>
							</EuiFormRow>
							<EuiFormRow
								error={touched.email && errors.email}
								isInvalid={touched.email && !!errors.email}
								label={t('users.email')}
							>
								<EuiFieldText
									data-testid="email-input"
									id="email"
									isInvalid={touched.email && !!errors.email}
									name="email"
									onChange={handleChange}
									placeholder={t('register.email')}
									value={email}
								/>
							</EuiFormRow>
							<EuiFormRow
								error={touched.mobile && errors.mobile}
								isInvalid={touched.mobile && !!errors.mobile}
								label={t('users.mobile')}
							>
								<EuiFieldText
									data-testid="mobile-input"
									id="mobile"
									isInvalid={
										touched.mobile && !!errors.mobile
									}
									name="mobile"
									onChange={handleChange}
									placeholder={t('register.mobile')}
									value={mobile}
								/>
							</EuiFormRow>
							<EuiSpacer size="l" />
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiForm>
			</EuiFlyoutBody>
			<EuiFlyoutFooter>
				<EuiFlexGroup
					direction="row"
					gutterSize="m"
					justifyContent="spaceBetween"
				>
					<EuiFlexItem>
						<EuiButtonEmpty
							color="danger"
							iconType="crossInACircleFilled"
							onClick={handleClose}
						>
							{t('general.cancel')}
						</EuiButtonEmpty>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiButton
							disabled={isLoading}
							fill
							iconType="plusInCircleFilled"
							isLoading={isLoading}
							onClick={handleSubmit}
						>
							Add Secretary
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiFlyoutFooter>
		</EuiFlyout>
	);
};

AddSecretary.propTypes = {
	onClose: PropTypes.func.isRequired,
	setSecretaries: PropTypes.func.isRequired,
	visible: PropTypes.bool.isRequired,
};

export default AddSecretary;
