/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
	EuiPanel,
	EuiLoadingSpinner,
	EuiText,
	EuiPageSection,
} from '@elastic/eui';
import { get } from 'lodash';
import QrCodeDisplay from 'components/QrCodeDisplay';
import ValidateRxAuth from './components/ValidateRxAuth';
import { getConfirmRx, putPaymentMagpie } from './validate-prescription.fetch';
import RxReceived from './components/RxReceived';
import ValidateRxForm from './components/ValidateRxForm';
import RxPaymentForm from './components/RxPaymentForm';
import RxProcessing from './components/RxProcessing';

const ValidateRx = () => {
	const urlParams = new URLSearchParams(window.location.search);
	const code = urlParams.get('code');
	const token = urlParams.get('token');
	const orderId = urlParams.get('orderId');

	const validateRxToken = sessionStorage.getItem('validate-rx-token');
	const validateRxData = JSON.parse(
		sessionStorage.getItem('validate-rx-data'),
	);
	const [rxToken, setRxToken] = useState(null);
	const [hasChanges, setHasChanges] = useState(false);

	const { isLoading, refetch } = useQuery(
		['rx-confirm', rxToken],
		getConfirmRx,
		{ enabled: !!rxToken && !hasChanges },
	);

	const handleAuth = (data) => {
		sessionStorage.setItem('validate-rx-token', data);
		setRxToken(data);
	};
	const handleLogout = () => {
		sessionStorage.removeItem('validate-rx-token');
		setRxToken(null);
	};

	useEffect(() => {
		const sessionData = sessionStorage.getItem('validate-rx-token');
		if (sessionData && !rxToken) {
			setRxToken(sessionData);
		}
	}, []);

	useEffect(() => {
		if (token && orderId) {
			const putMagpie = async () => {
				await putPaymentMagpie({ token, orderId });
				window.location.replace(
					`${process.env.REACT_APP_BASE_URL}/validate-rx?code=${code}`,
				);
			};
			putMagpie();
		}
	}, [token, orderId]);

	let content = null;
	if (!validateRxToken && !content) {
		content = (
			<div style={{ maxWidth: '600px' }}>
				<ValidateRxAuth code={code} onValidate={handleAuth} />
			</div>
		);
	}
	if (isLoading && !content) {
		content = <EuiLoadingSpinner />;
	}
	if (!isLoading && !validateRxData && !content) {
		content = (
			<EuiPageSection>
				<EuiText>Failed to load prescription</EuiText>
			</EuiPageSection>
		);
	}
	if (
		!get(validateRxData, 'order') &&
		get(validateRxData, 'status') === 'received' &&
		!content
	) {
		content = <RxReceived rx={validateRxData} />;
	}
	if (
		// disable processing page for now
		[].length > 0 &&
		validateRxData &&
		!validateRxData.order &&
		validateRxData.status === 'processing' &&
		!content
	) {
		content = (
			<RxProcessing
				message="Please wait for payment instructions"
				rx={validateRxData}
			/>
		);
	}
	if (
		validateRxData &&
		validateRxData.order &&
		validateRxData.order.status === 'readyForPickup' &&
		validateRxData.order.pickupType === 'grab' &&
		!content
	) {
		content = (
			<RxPaymentForm
				handleChanges={setHasChanges}
				hasChanges={hasChanges}
				onClose={handleLogout}
				rx={validateRxData}
				token={rxToken}
			/>
		);
	}
	if (
		validateRxData &&
		validateRxData.order &&
		validateRxData.order.status === 'readyForPickup' &&
		validateRxData.order.pickupType === 'branch' &&
		!content
	) {
		content = (
			<QrCodeDisplay
				onClose={handleLogout}
				onSubmit={handleLogout}
				prescription={validateRxData}
			/>
		);
	}
	if (!content) {
		content = (
			<ValidateRxForm
				handleChanges={setHasChanges}
				hasChanges={hasChanges}
				refetch={refetch}
				rx={validateRxData}
				token={rxToken}
			/>
		);
	}

	return (
		<EuiPanel
			style={{
				width: 'fit-content',
				maxWidth: '100%',
				margin: '0 auto',
			}}
		>
			{content}
		</EuiPanel>
	);
};

export default ValidateRx;
