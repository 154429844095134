import React from 'react';
import {
	EuiOverlayMask,
	EuiModal,
	EuiModalBody,
	EuiFlexGroup,
	EuiFlexItem,
	EuiImage,
	EuiText,
	EuiSpacer,
} from '@elastic/eui';

import PropTypes from 'prop-types';
import moment from 'moment';

const PatientModal = ({ isOpen, onClose, order }) => {
	if (!isOpen || !order) return null;

	return (
		<EuiOverlayMask>
			<EuiModal onClose={onClose}>
				<EuiModalBody>
					<EuiSpacer />
					<EuiFlexGroup
						alignItems="center"
						direction="column"
						justifyContent="center"
					>
						<EuiFlexItem>
							<EuiImage
								alt={order.branch?.name}
								caption={order.branch?.name}
								style={{
									display: 'block',
									margin: '0 auto',
								}}
								url={order.qrCode[0]?.url}
							/>
						</EuiFlexItem>
						<EuiFlexItem>
							<EuiText>
								<b>
									{order.patient?.firstName}{' '}
									{order.patient?.lastName}
								</b>
							</EuiText>
						</EuiFlexItem>
						<EuiFlexItem>Code</EuiFlexItem>
						<EuiFlexItem
							style={{
								color: order.vendorColor,
								fontWeight: 700,
							}}
						>
							{order.orderId}
						</EuiFlexItem>
						<EuiFlexItem>Date Created</EuiFlexItem>
						<EuiFlexItem
							style={{
								color: 'red',
								fontWeight: 600,
							}}
						>
							{moment(order.createdAt).format('MM-DD-YYYY')}
						</EuiFlexItem>
					</EuiFlexGroup>
					<EuiSpacer />
				</EuiModalBody>
			</EuiModal>
		</EuiOverlayMask>
	);
};

PatientModal.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	order: PropTypes.shape({
		branch: PropTypes.shape({
			name: PropTypes.string,
		}),
		qrCode: PropTypes.arrayOf(PropTypes.shape()),
		patient: PropTypes.shape({
			firstName: PropTypes.string,
			lastName: PropTypes.string,
		}),
		createdAt: PropTypes.string,
		orderId: PropTypes.string,
		vendorColor: PropTypes.string,
	}).isRequired,
};

PatientModal.defaultProps = {
	isOpen: false,
};

export default PatientModal;
