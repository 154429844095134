import React from 'react';
import { EuiPage, EuiPageBody, EuiPageSection } from '@elastic/eui';

import PropTypes from 'prop-types';

function DocumentLayout({ children, containerStyle }) {
	return (
		<EuiPage data-testid="document-layout-container" style={containerStyle}>
			<EuiPageBody component="div">
				<EuiPageSection
					className="document form-container"
					horizontalPosition="center"
					verticalPosition="center"
				>
					{children}
				</EuiPageSection>
			</EuiPageBody>
		</EuiPage>
	);
}

DocumentLayout.propTypes = {
	children: PropTypes.node.isRequired,
	containerStyle: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.arrayOf(PropTypes.object),
	]),
};

DocumentLayout.defaultProps = {
	containerStyle: null,
};

export default DocumentLayout;
