import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	EuiForm,
	EuiFormRow,
	EuiToolTip,
	EuiFieldText,
	EuiButton,
	EuiLink,
	EuiCheckbox,
	EuiFieldPassword,
	// EuiCallOut,
} from '@elastic/eui';

import PropTypes from 'prop-types';

import TermsModal from './TermsModal';
import PrivacyModal from './PrivacyModal';

const RegistrationForm = ({ errors, formik, isLoading }) => {
	const { t } = useTranslation();
	const [termOpen, setTermOpen] = useState(false);
	const [privacyOpen, setPrivacyOpen] = useState(false);
	// payload errors
	const [serverErrors, setServerErrors] = useState([]);
	// general server error
	// const [error, setError] = useState(null);
	const {
		errors: formikErrors,
		handleChange,
		handleSubmit,
		touched,
		values,
	} = formik;

	useEffect(() => {
		if (!errors || !Object.keys(errors).length) return;

		const errs = [];

		if (errors.message) {
			errs.push(errors.message);
		}
		if (
			!errors.message &&
			typeof errors === 'object' &&
			Object.keys(errors).length > 0
		) {
			Object.keys(errors).forEach((key) => errs.push(errors[key]));
		}

		setServerErrors(errs);
	}, [errors]);

	const disableButton =
		Object.keys(formikErrors).length > 0 || !values.acceptTerms;
	const toggleTerm = () => setTermOpen(!termOpen);
	const togglePrivacy = () => setPrivacyOpen(!privacyOpen);

	const hasServerErrors = serverErrors.length > 0;

	return (
		<>
			<EuiForm error={serverErrors} isInvalid={hasServerErrors}>
				<EuiFormRow
					display="columnCompressed"
					error={touched.firstName && formikErrors.firstName}
					isInvalid={touched.firstName && !!formikErrors.firstName}
					label="First Name"
				>
					<EuiToolTip
						content={t('register.nameTip')}
						display="block"
						position="right"
					>
						<EuiFieldText
							className={
								formikErrors.firstName ? 'invalidFormError' : ''
							}
							data-testid="firstName-input"
							fullWidth
							id="firstName"
							isInvalid={
								touched.firstName && !!formikErrors.firstName
							}
							name="firstName"
							onChange={handleChange}
							placeholder="First Name"
							value={values.firstName}
						/>
					</EuiToolTip>
				</EuiFormRow>
				<EuiFormRow
					display="columnCompressed"
					error={touched.middleName && formikErrors.middleName}
					isInvalid={touched.middleName && !!formikErrors.middleName}
					label="Middle Name"
				>
					<EuiFieldText
						className={
							formikErrors.middleName ? 'invalidFormError' : ''
						}
						data-testid="middleName-input"
						id="middleName"
						isInvalid={
							touched.middleName && !!formikErrors.middleName
						}
						name="middleName"
						onChange={handleChange}
						placeholder="Middle Name"
						value={values.middleName}
					/>
				</EuiFormRow>
				<EuiFormRow
					display="columnCompressed"
					error={touched.lastName && formikErrors.lastName}
					isInvalid={touched.lastName && !!formikErrors.lastName}
					label="Last Name"
				>
					<EuiFieldText
						className={
							formikErrors.lastName ? 'invalidFormError' : ''
						}
						data-testid="lastName-input"
						id="lastName"
						isInvalid={touched.lastName && !!formikErrors.lastName}
						name="lastName"
						onChange={handleChange}
						placeholder="Last Name"
						value={values.lastName}
					/>
				</EuiFormRow>
				<EuiFormRow
					display="columnCompressed"
					error={
						touched.email && (formikErrors.email || errors.email)
					}
					isInvalid={
						touched.email &&
						(!!formikErrors.email || !!errors.email)
					}
					label={t('register.email')}
				>
					<EuiToolTip
						content={t('register.emailTip')}
						display="block"
						position="right"
					>
						<EuiFieldText
							className={
								formikErrors.email ? 'invalidFormError' : ''
							}
							data-testid="email-input"
							fullWidth
							id="email"
							isInvalid={touched.email && !!formikErrors.email}
							name="email"
							onChange={handleChange}
							placeholder={t('register.email')}
							value={values.email}
						/>
					</EuiToolTip>
				</EuiFormRow>
				<EuiFormRow
					display="columnCompressed"
					error={touched.username && formikErrors.username}
					isInvalid={touched.username && !!formikErrors.username}
					label={t('register.username')}
				>
					<EuiFieldText
						className={
							formikErrors.username ? 'invalidFormError' : ''
						}
						data-testid="username-input"
						id="username"
						isInvalid={touched.username && !!formikErrors.username}
						name="username"
						onChange={handleChange}
						placeholder={t('register.username')}
						value={values.username}
					/>
				</EuiFormRow>
				<EuiFormRow
					display="columnCompressed"
					error={touched.password && formikErrors.password}
					isInvalid={touched.password && !!formikErrors.password}
					label={t('register.password')}
				>
					<EuiToolTip
						content="Must be at least 8 characters"
						display="block"
						position="right"
					>
						<EuiFieldPassword
							className={
								formikErrors.password ? 'invalidFormError' : ''
							}
							data-testid="password-input"
							id="password"
							isInvalid={
								touched.password && !!formikErrors.password
							}
							name="password"
							onChange={handleChange}
							placeholder={t('register.password')}
							type="dual"
							value={values.password}
						/>
					</EuiToolTip>
				</EuiFormRow>
				<EuiFormRow
					display="columnCompressed"
					error={
						touched.confirmPassword && formikErrors.confirmPassword
					}
					isInvalid={
						touched.confirmPassword &&
						!!formikErrors.confirmPassword
					}
					label={t('register.confirmPassword')}
				>
					<EuiFieldPassword
						className={
							formikErrors.confirmPassword
								? 'invalidFormError'
								: ''
						}
						data-testid="confirmPassword-input"
						id="confirmPassword"
						isInvalid={
							touched.confirmPassword &&
							!!formikErrors.confirmPassword
						}
						name="confirmPassword"
						onChange={handleChange}
						placeholder={t('register.confirmPassword')}
						type="dual"
						value={values.confirmPassword}
					/>
				</EuiFormRow>
				<EuiFormRow display="columnCompressedSwitch" label=" ">
					<EuiCheckbox
						checked={values.acceptTerms}
						id="acceptTerms"
						label={
							<span>
								{formikErrors.acceptTerms && (
									<span className="invalidFormErrorTextColor">
										*{' '}
									</span>
								)}
								I confirm that I have read and agree to the{' '}
								<EuiLink onClick={toggleTerm}>
									Terms & Conditions
								</EuiLink>{' '}
								and{' '}
								<EuiLink onClick={togglePrivacy}>
									Privacy Policy
								</EuiLink>
							</span>
						}
						name="acceptTerms"
						onChange={handleChange}
					/>
				</EuiFormRow>
				<EuiFormRow display="columnCompressedSwitch" label=" ">
					<EuiCheckbox
						checked={values.mailingList}
						id="mailingList"
						label={
							<span>
								I am willing to receive invitations to join
								sponsored panels for research relevant to my
								specialty.
							</span>
						}
						name="mailingList"
						onChange={handleChange}
					/>
				</EuiFormRow>
				<EuiFormRow>
					<EuiButton
						color="primary"
						data-testid="registration-button"
						disabled={disableButton}
						fill
						fullWidth
						isLoading={isLoading}
						onClick={handleSubmit}
						type="submit"
					>
						{t('register.titleButton')}
					</EuiButton>
				</EuiFormRow>
			</EuiForm>

			<TermsModal isOpen={termOpen} onClose={toggleTerm} />
			<PrivacyModal isOpen={privacyOpen} onClose={togglePrivacy} />
		</>
	);
};

RegistrationForm.propTypes = {
	errors: PropTypes.shape(),
	formik: PropTypes.shape({
		errors: PropTypes.shape(),
		values: PropTypes.shape(),
		handleChange: PropTypes.func,
		handleSubmit: PropTypes.func,
		touched: PropTypes.shape(),
	}).isRequired,
	isLoading: PropTypes.bool,
};

RegistrationForm.defaultProps = {
	errors: null,
	isLoading: false,
};

export default RegistrationForm;
