import React, { useState } from 'react';

import { EuiInMemoryTable } from '@elastic/eui';
import { set } from 'lodash';

import PropTypes from 'prop-types';

function Table({
	items,
	onTableChange,
	pagination,
	...tableProps
	// selectionValue,
}) {
	const [sortField, setSortField] = useState(null);
	const [sortDirection, setSortDirection] = useState(null);
	const [pageIndex, setPageIndex] = useState(null);
	const [pageSize, setPageSize] = useState(null);

	const onTableChangeProp = ({ page = {}, sort = {} }) => {
		const { index, size } = page;

		const { direction, field } = sort;

		setPageIndex(index);
		setPageSize(size);
		setSortField(field);
		setSortDirection(direction);

		if (onTableChange) onTableChange({ page, sort });
	};

	if (pagination) {
		set(pagination, 'pageIndex', pageIndex);
		set(pagination, 'pageSize', pageSize);
		set(pagination, 'totalItemCount', items.length);
	}

	const sorting = {
		sort: {
			field: sortField,
			direction: sortDirection,
		},
	};

	return (
		<EuiInMemoryTable
			items={items}
			onTableChange={onTableChangeProp}
			pagination={pagination}
			sorting={sortField && sortDirection ? sorting : true}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...tableProps}
		/>
	);
}

Table.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape({})),
	onTableChange: PropTypes.func,
	pagination: PropTypes.shape({
		pageIndex: PropTypes.number,
		pageSize: PropTypes.number,
		pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
	}),
};

Table.defaultProps = {
	items: [],
	onTableChange: null,
	pagination: {
		pageIndex: null,
		pageSize: null,
		pageSizeOptions: [10, 20, 50],
	},
};

export default Table;
