/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { EuiFlexGroup, EuiFlexItem, EuiText, EuiSpacer } from '@elastic/eui';
import PropTypes from 'prop-types';

export default function PatientHeader({ user }) {
	return (
		<>
			<EuiFlexGroup direction="column">
				<EuiFlexGroup
					style={{
						flexDirection: 'column',
						alignItems: 'flex-start',
					}}
				>
					<EuiFlexItem grow={false}>
						<EuiText>
							<h3>
								Hello,{' '}
								{user?.fullName !== 'undefined undefined'
									? user?.fullName
									: user?.username}
								!
							</h3>
						</EuiText>
					</EuiFlexItem>

					<EuiFlexItem grow={false}>
						<EuiText>Try our new Grab Delivery service!</EuiText>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiFlexGroup>
			<EuiSpacer />
		</>
	);
}

PatientHeader.propTypes = {
	user: PropTypes.instanceOf(Object).isRequired,
};
