export const SUPER_ADMIN = {
	key: 'superAdmin',
	name: 'Super Admin',
	value: 'super admin',
};
export const CALL_CENTER = {
	key: 'call-center',
	name: 'Call Center',
	value: 'call-center',
};
export const DOCTOR = { key: 'doctor', name: 'Doctor', value: 'doctor' };
export const SECRETARY = {
	key: 'secretary',
	name: 'Secretary',
	value: 'secretary',
};
export const PHARMACIST = {
	key: 'pharmacist',
	name: 'Pharmacist',
	value: 'pharmacist',
};
export const ADMIN = {
	key: 'administrator',
	name: 'Admin',
	value: 'administrator',
};
export const PATIENT = {
	key: 'patient',
	name: 'Patient',
	value: 'patient',
};
