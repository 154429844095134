/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FaFileAlt } from 'react-icons/fa';

import { EuiButton, EuiButtonEmpty, EuiPortal, EuiShowFor } from '@elastic/eui';

import PropTypes from 'prop-types';

const Print = ({ children, size = 'm', ...props }) => {
	return (
		<>
			<EuiShowFor sizes={['xl', 'l', 'm']}>
				<EuiButton
					onClick={() => window.print()}
					size={size}
					{...props}
				>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<FaFileAlt color="#117aac" style={{ marginRight: 5 }} />
						Print
					</div>
				</EuiButton>
			</EuiShowFor>
			<EuiShowFor sizes={['s', 'xs']}>
				<EuiButtonEmpty
					fullWidth={false}
					onClick={() => window.print()}
					size="xs"
					{...props}
				>
					Print
				</EuiButtonEmpty>
			</EuiShowFor>

			<EuiPortal>
				<div className="print">{children}</div>
			</EuiPortal>
		</>
	);
};

Print.propTypes = {
	children: PropTypes.node.isRequired,
	size: PropTypes.oneOf(['m', 's', 'xs']),
};

Print.defaultProps = {
	size: 'm',
};

export default Print;
