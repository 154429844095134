import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locale/en_PH.json';

const resources = {
	en,
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: 'en',
		keySeparator: '.',
	});

export default i18n;
