/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import {
	EuiFlexGroup,
	EuiFlexItem,
	EuiForm,
	EuiFormRow,
	EuiFieldText,
	EuiSpacer,
} from '@elastic/eui';

import PropTypes from 'prop-types';

import AddressForm from 'components/AddressForm';

const PatientOrderReceiverForm = ({ formikBag }) => {
	const { errors, handleChange, touched, values } = formikBag;
	return (
		<EuiForm>
			<EuiSpacer />
			<EuiFlexGroup>
				<EuiFlexItem grow={false} style={{ width: 500 }}>
					<EuiFormRow
						error={
							touched.receiverFirstName &&
							errors.receiverFirstName
						}
						isInvalid={
							touched.receiverFirstName &&
							!!errors.receiverFirstName
						}
						label="Receiver First Name *"
					>
						<EuiFieldText
							data-testid="receiver-name-input"
							id="receiverFirstName"
							isInvalid={
								touched.receiverFirstName &&
								!!errors.receiverFirstName
							}
							name="receiverFirstName"
							onChange={handleChange}
							placeholder="Receiver First Name"
							value={values.receiverFirstName}
						/>
					</EuiFormRow>
				</EuiFlexItem>
				<EuiFlexItem grow={false} style={{ width: 500 }}>
					<EuiFormRow
						error={
							touched.receiverLastName && errors.receiverLastName
						}
						isInvalid={
							touched.receiverLastName &&
							!!errors.receiverLastName
						}
						label="Receiver Last Name *"
					>
						<EuiFieldText
							data-testid="receiver-name-input"
							id="receiverLastName"
							isInvalid={
								touched.receiverLastName &&
								!!errors.receiverLastName
							}
							name="receiverLastName"
							onChange={handleChange}
							placeholder="Receiver Last Name"
							value={values.receiverLastName}
						/>
					</EuiFormRow>
				</EuiFlexItem>
			</EuiFlexGroup>
			<EuiSpacer />
			<EuiFlexGroup>
				<EuiFlexItem grow={false} style={{ width: 500 }}>
					<EuiFormRow
						error={touched.receiverEmail && errors.receiverEmail}
						isInvalid={
							touched.receiverEmail && !!errors.receiverEmail
						}
						label="Receiver Email Address *"
					>
						<EuiFieldText
							data-testid="receiver-contact-input"
							id="receiverEmail"
							isInvalid={
								touched.receiverEmail && !!errors.receiverEmail
							}
							name="receiverEmail"
							onChange={handleChange}
							placeholder="Receiver Email Address *"
							value={values.receiverEmail}
						/>
					</EuiFormRow>
				</EuiFlexItem>
				<EuiFlexItem grow={false} style={{ width: 500 }}>
					<EuiFormRow
						error={
							touched.receiverContactNumber &&
							errors.receiverContactNumber
						}
						isInvalid={
							touched.receiverContactNumber &&
							!!errors.receiverContactNumber
						}
						label="Receiver Contact Number *"
					>
						<EuiFieldText
							data-testid="receiver-contact-input"
							id="receiverContactNumber"
							isInvalid={
								touched.receiverContactNumber &&
								!!errors.receiverContactNumber
							}
							name="receiverContactNumber"
							onChange={handleChange}
							placeholder="Receiver Contact Number"
							value={values.receiverContactNumber}
						/>
					</EuiFormRow>
				</EuiFlexItem>
			</EuiFlexGroup>
			<EuiSpacer />
			<AddressForm formikBag={formikBag} markRequired />
		</EuiForm>
	);
};

PatientOrderReceiverForm.propTypes = {
	formikBag: PropTypes.shape({
		errors: PropTypes.instanceOf(Object),
		handleChange: PropTypes.func,
		touched: PropTypes.instanceOf(Object),
		values: PropTypes.instanceOf(Object),
	}).isRequired,
};

export default PatientOrderReceiverForm;
