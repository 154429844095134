import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { get } from 'lodash';
import {
	askUserPermission,
	createPushNotificationEndpoint,
	registerServiceWorker,
	isPushNotificationSupported,
	getDeviceSubscription,
	getSubscription,
	putSubscribeUser,
} from './push-notifications.fetch';

export default function usePushNotifications() {
	const [userConsent, setSuserConsent] = useState(
		isPushNotificationSupported() ? Notification.permission : 'denied',
	);
	const { user } = useSelector((state) => ({
		user: state.auth.user,
	}));
	const [userSubscription, setUserSubscription] = useState(null);
	const pushNotificationSupported = isPushNotificationSupported();

	useEffect(() => {
		if (pushNotificationSupported) {
			registerServiceWorker();
		}
	}, []);

	useEffect(() => {
		async function subscribe() {
			let subscription = null;

			const deviceSubscription = await getDeviceSubscription();

			if (deviceSubscription) {
				const [data] = await getSubscription({
					endpoint: deviceSubscription.endpoint,
				});
				subscription = data;
			}

			if (
				get(subscription, 'id') &&
				get(subscription, 'user.id') !== user.id
			) {
				subscription = await putSubscribeUser(subscription.id);
			}

			if (!subscription) {
				subscription = await createPushNotificationEndpoint();
			}

			setUserSubscription(subscription);
		}

		if (userConsent === 'granted') {
			subscribe();
		}
	}, [userConsent]);

	const handleAskUserPermission = async () => {
		const consent = await askUserPermission();

		setSuserConsent(consent);
	};

	return {
		handleAskUserPermission,
		userSubscription,
	};
}
