import React from 'react';
import { EuiConfirmModal, EuiOverlayMask } from '@elastic/eui';

import PropTypes from 'prop-types';

const ConfirmationModal = ({
	id,
	isVisible,
	message,
	onClose,
	onConfirm,
	title,
	...props
}) => {
	if (!isVisible) {
		return null;
	}

	return (
		<EuiOverlayMask key={id}>
			<EuiConfirmModal
				cancelButtonText="Cancel"
				confirmButtonText="Confirm"
				onCancel={onClose}
				onConfirm={onConfirm}
				title={title}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			>
				{message}
			</EuiConfirmModal>
		</EuiOverlayMask>
	);
};

ConfirmationModal.propTypes = {
	id: PropTypes.string,
	isVisible: PropTypes.bool,
	message: PropTypes.string,
	onClose: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	title: PropTypes.string,
};

ConfirmationModal.defaultProps = {
	id: null,
	isVisible: true,
	message: null,
	title: null,
};

export default ConfirmationModal;
