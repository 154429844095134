import api, { attachToken, detachToken } from 'utils/api';
import { getFieldError } from 'utils/helpers';

export const postLogin = async (credentials) => {
	try {
		const { data } = await api.post('/auth/local', credentials);
		attachToken(data.jwt);

		const userRole = data.user.role.type;
		let additionalData = null;
		if (userRole !== 'patient') {
			const { data: userData } = await api.get('/users/me');
			additionalData = userData;
		} else {
			const { data: userData } = await api.get('/patients/me');
			additionalData = userData;
		}

		return {
			...data,
			user: {
				...data.user,
				...additionalData,
			},
		};
	} catch (err) {
		detachToken();

		let { message: errorMessage } = getFieldError(err);

		if (
			!errorMessage ||
			errorMessage === 'Identifier or password invalid.'
		) {
			errorMessage = 'Wrong Username or Password';
		}

		throw new Error(errorMessage);
	}
};

export const postForgotPassword = async (email) => {
	try {
		await api.post('/auth/forgot-password', { email });

		return { success: true };
	} catch (err) {
		throw new Error(`Failed to send reset password link to: ${email}`);
	}
};

export const postResetPassword = async (data) => {
	return api.post('/auth/reset-password', data);
};

export const updatePassword = async (id, payload) => {
	return api.put(`/users/${id}/change-password`, payload);
};

export const postDoctorRegistration = async (payload) => {
	return api.post('/doctors/register', payload);
};

export const secretaryRegistration = async (payload) => {
	return api.post('/secretaries/register', payload);
};

export const getUserDetails = async () => {
	return api.get('/users/me');
};

export const updateErxContractAccepted = async (id) => {
	return api.put(`doctors/${id}`, { erxContractAccepted: true });
};

export const getRegistrationContent = async () => {
	const { data } = await api.get('/registration');

	return data;
};

export const postPatientLogin = async (credentials) => {
	try {
		const { data } = await api.post('/auth/local', credentials);
		attachToken(data.jwt);

		const userRole = data.user.role.type;
		let additionalData = null;
		if (userRole !== 'patient') {
			const { data: userData } = await api.get('/users/me');
			additionalData = userData;
		} else {
			const { data: userData } = await api.get('/patients/me');
			if (userData?.user?.confirmed) {
				additionalData = userData;
			} else {
				// eslint-disable-next-line no-throw-literal
				throw {
					response: {
						data: {
							data: [
								{
									messages: [
										{
											message:
												'The account you are trying to login is net yet verified, Kindly check your email.',
										},
									],
								},
							],
						},
					},
				};
			}
		}
		return {
			...data,
			user: {
				...data.user,
				...additionalData,
			},
		};
	} catch (err) {
		detachToken();

		let { message: errorMessage } = getFieldError(err);

		if (
			!errorMessage ||
			errorMessage === 'Identifier or password invalid.'
		) {
			errorMessage = 'Wrong Username or Password';
		}

		throw new Error(errorMessage);
	}
};

export const postPatientRegistation = async (payload) => {
	try {
		return await api.post('/patients/register', payload);
	} catch (err) {
		let { message: errorMessage } = getFieldError(err);
		if (
			errorMessage ===
			'Only letters, numbers and symbols (-_.) are allowed'
		) {
			errorMessage =
				'Invalid Username, Only letters, numbers and symbols (-_.) are allowed';
		}
		throw new Error(errorMessage);
	}
};

export const postCustomer = async (payload) => {
	return api.post('/customers', payload);
};
