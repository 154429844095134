import React, { useState } from 'react';

import { EuiSuperSelect } from '@elastic/eui';

import PropTypes from 'prop-types';

import DropdownOptWSub from 'components/DropdownOptWSub';
import {
	getVendors,
	// getVendorsByAddress,
	getVendorsByCity,
} from 'modules/vendors/vendors.fetch';
import { useDebouncedEffect } from 'utils/hooks';

const VendorDropdown = ({
	address,
	cityId,
	name,
	onBlur,
	onChange,
	pickup,
	preferred,
	selected,
	showBranchInfo,
	...props
}) => {
	const [vendors, setVendors] = useState([]);

	useDebouncedEffect(
		() => {
			const constructName = (data, pref) => {
				let text = data.name;

				if (pref === data.id) {
					text += ' (Preferred)';
				}

				return text;
			};

			const mapOpts = (data) => (v) => {
				const { id } = v;
				const found = data.find((d) => d.id === id);
				const constructedName = constructName(v, preferred);
				const forValue = pickup ? 'Pick-up' : 'Address';
				const branchInfo = !found
					? `No Branches Available for ${forValue}`
					: 'Branches Available';
				return {
					...v,
					inputDisplay: constructedName,
					dropdownDisplay: (
						<DropdownOptWSub
							description={showBranchInfo ? branchInfo : null}
							title={constructedName}
						/>
					),
					disabled: !found,
				};
			};

			const fetchVendorsByCity = async (opts) => {
				const { data } = await getVendorsByCity(cityId, pickup);

				return opts
					.map(mapOpts(data))
					.sort((a, b) => {
						if (a.name > b.name) {
							return -1;
						}
						if (a.name < b.name) {
							return 1;
						}
						return 0;
					})
					.sort((value) => {
						return value.disabled ? 1 : -1;
					});
			};

			const fetchVendors = async () => {
				const { data } = await getVendors();

				let opts = data.map((d) => ({
					...d,
					inputDisplay: constructName(d, preferred),
					dropdownDisplay: (
						<DropdownOptWSub
							description="Branches Available"
							title={constructName(d, preferred)}
						/>
					),
					value: d.id,
					disabled: false,
				}));

				opts = await fetchVendorsByCity(opts);

				const current = opts.find((opt) => opt.id === selected);

				if (current && current.disabled) {
					onChange(null);
				}

				if (current && !current.disabled) {
					onChange(current.id);
				}

				setVendors(opts);
			};

			fetchVendors();
		},
		800,
		[cityId, pickup],
	);

	return (
		<EuiSuperSelect
			name={name}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
			onChange={onChange}
			options={vendors.filter((v) => v.name.toUpperCase() !== 'WATSONS')}
			valueOfSelected={selected}
		/>
	);
};

VendorDropdown.propTypes = {
	address: PropTypes.string,
	cityId: PropTypes.string,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func.isRequired,
	pickup: PropTypes.bool,
	preferred: PropTypes.string,
	selected: PropTypes.string,
	showBranchInfo: PropTypes.bool,
};

VendorDropdown.defaultProps = {
	address: null,
	cityId: null,
	name: null,
	// eslint-disable-next-line no-unused-vars
	onBlur: (e) => {},
	pickup: false,
	preferred: null,
	selected: null,
	showBranchInfo: false,
};

export default VendorDropdown;
