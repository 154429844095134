import React from 'react';
import PropTypes from 'prop-types';
import Toasts from 'modules/toasts/Toasts';
import Lightbox from 'modules/lightbox/Lightbox';
import { EuiPage, EuiPageBody, EuiPageSection } from '@elastic/eui';

function ValidateRxLayout({ children }) {
	return (
		<>
			<EuiPage data-testid="auth-layout-container">
				<EuiPageBody>
					<EuiPageSection
						style={{
							padding: '0',
						}}
					>
						{children}
					</EuiPageSection>
				</EuiPageBody>
			</EuiPage>
			<Toasts />
			<Lightbox />
		</>
	);
}

ValidateRxLayout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default ValidateRxLayout;
