/* eslint-disable no-console */
import { thenCallbackRQ } from 'utils/helpers';
import { attachToken, detachToken } from './grabapi';
import api from '../../utils/api';

// function hasBearer(instance) {
// 	return instance.defaults.headers.common.Authorization !== undefined;
// }

export const getQuote = async (codAmount, order) => {
	const body = {
		codAmount,
		order,
	};
	return api.post('/grab-orders/quote', body);
};

export const getAccessToken = async () => {
	try {
		const { data } = await api.get('/grab-orders/token');
		attachToken(data.access_token);
	} catch (err) {
		detachToken();
		console.log(err.message);
		throw new Error(err);
	}
};

export const getGrabOrder = async (ctx) => {
	const { queryKey } = ctx;
	const [, id] = queryKey;
	return api.get(`/grab-orders/${id}`).then(thenCallbackRQ);
};

export const createGrabEntry = async (res, order) => {
	const payload = {
		deliveryId: res.deliveryID,
		status: res.status,
		origin: order.branch.address.id,
		destination: order.patient.address.id,
		// pickup:res.quote.estimatedTimeline.pickup,
		// dropoff:res.quote.estimatedTimeline.dropoff,
		amount: res.quote.amount,
		order: order.id,
		branch: order.branch.id,
		patient: order.patient.id,
		trackingURL: res.trackingURL,
		invoiceNo: res.invoiceNo,
	};
	await api.post('/grab-orders', payload).then(thenCallbackRQ);
};

export const createDeliveryRequest = async (
	order,
	contact,
	email,
	totalAmt,
	refetch,
) => {
	console.log('calling  strapi for create request');
	const payload = { order, contact, email, totalAmt };
	console.log(payload);
	const { data } = await api.post('/grab-orders/request', payload);
	console.log('done');
	console.log(data);
	refetch();
	return data;
};

export const updateGrabEntry = async (grabOrder, id) => {
	await api.put(`/grab-orders/${id}`, grabOrder).then(thenCallbackRQ);
};

export const getDeliveryStatus = async (deliveryId) => {
	const { data } = await api.get(`/grab-orders/status/${deliveryId}`);
	console.log(data);
	return data;
};

export const cancelDelivery = async (deliveryId) => {
	console.log('canceling grab (grab api)...', deliveryId);
	const { data } = await api.delete(`/grab-orders/cancel/${deliveryId}`);
	return data;
};
