import React, { useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { EuiTabbedContent } from '@elastic/eui';
import { useTranslation } from 'react-i18next';

import Page from 'components/Page';
import General from './General';
import Licenses from '../licenses/Licenses';
import Clinic from './Clinic';
import Secretaries from '../secretaries/Secretaries';

const DoctorSettings = () => {
	const { t } = useTranslation();
	const [headerContent, setHeaderContent] = useState(null);
	const tabs = [
		{
			id: 'general',
			name: 'General',
			content: <General />,
		},
		{
			id: 'clinic',
			name: 'Clinic',
			content: <Clinic />,
		},
		{
			id: 'licenses',
			name: 'Licenses',
			content: <Licenses setHeaderContent={setHeaderContent} />,
		},
		{
			id: 'secretaries',
			name: 'Secretary',
			content: <Secretaries setHeaderContent={setHeaderContent} />,
		},
	];

	return (
		<>
			<Helmet title={t('general.title')} />
			<Page headerRight={headerContent} title={t('settings.title')}>
				<EuiTabbedContent
					autoFocus="selected"
					initialSelectedTab={tabs[0]}
					tabs={tabs}
				/>
			</Page>
		</>
	);
};

export default DoctorSettings;
