import React, { useState, useEffect } from 'react';
import {
	EuiHorizontalRule,
	EuiLink,
	EuiFlexGroup,
	EuiFlexItem,
	EuiButton,
	EuiSpacer,
} from '@elastic/eui';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import Table from 'components/Table';
import ContactDetails from 'modules/_global/ContactDetails';
import ConfirmationModal from 'components/ConfirmationModal';
import { addToast } from '../toasts/toasts.actions';
import AddSecretary from './AddSecretary';
import { getSecretaries, deleteSecretary } from './secretaries.fetch';

const Secretaries = ({ setHeaderContent }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const [addSecretaryVisible, setAddSecretaryVisible] = useState(false);
	const [secretaries, setSecretaries] = useState([]);
	const [selectedSecretary, setSelectedSecretary] = useState(null);
	const [isConfirmDeleteModalVisisble, setIsConfirmDeleteModalVisible] =
		useState(false);
	const [, setIsLoading] = useState(false);

	useEffect(() => {
		const fetchSecretaries = async () => {
			try {
				setIsLoading(true);
				const { data } = await getSecretaries();
				setSecretaries(data);
			} catch (err) {
				dispatch(addToast('Error', err.message, 'danger', 'help'));
			} finally {
				setIsLoading(false);
			}
		};
		fetchSecretaries();
	}, []);

	const handleCloseAddSecretary = () => {
		setAddSecretaryVisible(false);
	};

	useEffect(() => {
		if (setAddSecretaryVisible) {
			setHeaderContent(
				<AddSecretary
					onClose={handleCloseAddSecretary}
					setSecretaries={setSecretaries}
					visible={addSecretaryVisible}
				/>,
			);
		} else {
			setHeaderContent(null);
		}
	}, [addSecretaryVisible]);

	const handleDelete = (data) => {
		setIsConfirmDeleteModalVisible(true);
		setSelectedSecretary(data);
	};

	const removeContact = async (data) => {
		setIsLoading(true);
		try {
			await deleteSecretary(data.id);
			const updatedSecretaries = secretaries.filter(
				(contact) => contact.id !== data.id,
			);
			setSecretaries(updatedSecretaries);
			dispatch(
				addToast('Success', 'Secretary Deleted', 'success', 'check'),
			);
		} catch (err) {
			dispatch(addToast('Error', err.message, 'danger', 'help'));
		} finally {
			setIsLoading(false);
		}
	};

	const confirmDeleteModal = isConfirmDeleteModalVisisble ? (
		<ConfirmationModal
			message="Are you sure you want to do this"
			onClose={() => {
				setIsConfirmDeleteModalVisible(false);
				setSelectedSecretary(null);
			}}
			onConfirm={() => {
				removeContact(selectedSecretary);
				setIsConfirmDeleteModalVisible(false);
				setSelectedSecretary(null);
			}}
			title="Delete Secretary"
		/>
	) : null;

	const columns = [
		{
			field: 'id',
			name: 'Name',
			sortable: true,
			render: (id, secretary) => (
				<Link to={`/secretaries/${id}`}>
					<EuiLink>
						{`
							${secretary.lastName}, 
							${secretary.firstName} 
							${secretary.middleName || ''}
						`}
					</EuiLink>
				</Link>
			),
		},
		{
			field: 'user.email',
			name: t('secretaries.email'),
			sortable: true,
		},
		{
			field: 'user.username',
			name: t('secretaries.username'),
			sortable: true,
		},
		{
			field: 'contactDetails',
			name: 'Contact Details',
			sortable: false,
			render: (contactDetails) => (
				<ContactDetails
					ids={contactDetails.map((contact) => contact.id)}
				/>
			),
		},
		{
			field: 'actions',
			name: '',
			actions: [
				{
					name: 'View',
					description: 'View',
					onClick: (data) => history.push(`/secretaries/${data.id}`),
					icon: 'eye',
					type: 'icon',
					isPrimary: true,
				},
				{
					name: 'Edit',
					description: 'Edit',
					onClick: (data) => history.push(`/secretaries/${data.id}`),
					icon: 'pencil',
					type: 'icon',
					color: 'primary',
					isPrimary: true,
				},
				{
					name: 'Delete',
					description: 'Delete',
					onClick: (data) => handleDelete(data),
					icon: 'trash',
					type: 'icon',
					color: 'danger',
					isPrimary: true,
				},
			],
		},
	];

	return (
		<>
			<EuiSpacer />
			<EuiFlexGroup
				direction="row"
				gutterSize="m"
				justifyContent="flexEnd"
			>
				<EuiFlexItem grow={false}>
					<EuiFlexGroup responsive={false}>
						<EuiFlexItem>
							<EuiButton
								fill
								iconType="plusInCircleFilled"
								isDisabled={Boolean(secretaries.length)}
								onClick={() => setAddSecretaryVisible(true)}
							>
								Add Secretary
							</EuiButton>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
			<EuiHorizontalRule margin="m" />
			<Table
				columns={columns}
				isSelectable
				itemId="secretaryId"
				items={secretaries}
				// search={search}
				// selection={selectionProps}
			/>
			{confirmDeleteModal}
		</>
	);
};

Secretaries.propTypes = {
	setHeaderContent: PropTypes.func.isRequired,
};

export default Secretaries;
