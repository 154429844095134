import React from 'react';
import { EuiStepsHorizontal } from '@elastic/eui';
import PropTypes from 'prop-types';

const CustomStep = ({ steps }) => {
	return (
		<div>
			<EuiStepsHorizontal steps={steps} />
		</div>
	);
};

CustomStep.propTypes = {
	steps: PropTypes.instanceOf(Array).isRequired,
};

CustomStep.defaultProps = {};

export default CustomStep;
