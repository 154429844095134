import React from 'react';
import { useTranslation } from 'react-i18next';
import { EuiSelect, EuiFieldText } from '@elastic/eui';
import PropTypes from 'prop-types';

const RxFulfillmentDropdown = ({ readOnly, value, ...props }) => {
	const { t } = useTranslation();
	const fulfillmentOpts = [
		{ value: 'purchase_all', text: 'Purchase All' },
		{ value: 'partial_only', text: 'Partial Only / Maintenance Medicine' },
		{ value: 'inquire_first', text: 'Inquire First' },
	];

	if (readOnly) {
		return (
			<EuiFieldText
				readOnly
				value={t(
					`prescriptions.fulfillment.${value || 'purchase_all'}`,
				)}
			/>
		);
	}

	return (
		<EuiSelect
			disabled={readOnly}
			options={fulfillmentOpts}
			value={value}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		/>
	);
};

RxFulfillmentDropdown.propTypes = {
	readOnly: PropTypes.bool,
	value: PropTypes.string,
};

RxFulfillmentDropdown.defaultProps = {
	readOnly: false,
	value: '',
};

export default RxFulfillmentDropdown;
