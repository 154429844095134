import React from 'react';
import { Helmet } from 'react-helmet-async';
import { EuiTabbedContent, EuiSpacer } from '@elastic/eui';
import { useTranslation } from 'react-i18next';

import Page from 'components/Page';
// import { Link } from 'react-router-dom';
// import Branches from '../branches/Branches';
import Brands from '../brands/Brands';

const Settings = () => {
	const { t } = useTranslation();
	const tabs = [
		{
			id: 'brands--id',
			name: 'Brands',
			content: (
				<>
					<EuiSpacer />
					<Brands />
				</>
			),
		},
		// {
		// 	id: 'branches--id',
		// 	name: 'Branches',
		// 	content: (
		// 		<>
		// 			<EuiSpacer />
		// 			<Branches />
		// 		</>
		// 	),
		// },
	];

	return (
		<>
			<Helmet title={t('settings.title')} />
			<Page title={t('settings.title')}>
				<EuiTabbedContent
					autoFocus="selected"
					initialSelectedTab={tabs[0]}
					// eslint-disable-next-line no-unused-vars
					onTabClick={(tab) => {}}
					tabs={tabs}
				/>
			</Page>
		</>
	);
};

export default Settings;
