import React, { useState } from 'react';
import {
	EuiContextMenuPanel,
	EuiContextMenuItem,
	EuiPopover,
	EuiHeaderSectionItemButton,
	EuiIcon,
	EuiText,
} from '@elastic/eui';

const Points = () => {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	const handleOpen = () =>
		setIsPopoverOpen((isPopoverOpenVal) => !isPopoverOpenVal);

	const handleClose = () => setIsPopoverOpen(false);

	const items = [
		<EuiContextMenuItem
			key="Redeem"
			href="https://nowna.com.ph/"
			target="_blank"
		>
			Redeem
		</EuiContextMenuItem>,
		<EuiContextMenuItem
			key="Donate"
			href="https://nowna.com.ph/"
			target="_blank"
		>
			Donate
		</EuiContextMenuItem>,
	];

	return (
		<EuiPopover
			anchorPosition="downLeft"
			button={
				<EuiHeaderSectionItemButton
					aria-label="Points"
					onClick={handleOpen}
				>
					<EuiText className="points">
						<EuiIcon type="database" />
						&nbsp;x1000
					</EuiText>
				</EuiHeaderSectionItemButton>
			}
			closePopover={handleClose}
			isOpen={isPopoverOpen}
			panelPaddingSize="none"
		>
			<EuiContextMenuPanel items={items} />
		</EuiPopover>
	);
};

export default Points;
