import React, { useCallback, useEffect, useState } from 'react';

import { EuiComboBox } from '@elastic/eui';
import { debounce } from 'lodash';

import PropTypes from 'prop-types';

import { getCities } from 'modules/_global/global.fetch';

const CityMunicipalityDropdown = ({ onChange, province, value, ...props }) => {
	const [selected, setSelected] = useState([]);
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);

	const processResults = (res) => {
		const processedOptions = res.map((d) => ({
			label: `${d.name} - ${d.province.name}`,
			value: d.id,
			...d,
		}));

		setOptions(processedOptions);

		return processedOptions;
	};

	const onSelect = (e) => {
		const [selectedOption] = e;

		if (!selectedOption) {
			if (onChange) onChange(null);
			setOptions([]);
			return setSelected([]);
		}

		if (onChange) onChange(selectedOption);

		return setSelected(e);
	};

	const searchCity = async (searchValue) => {
		try {
			if (!searchValue.length || searchValue.length < 3 || loading) {
				return;
			}

			const params = {
				_q: searchValue,
			};

			if (province) {
				params.province = province;
			}

			setLoading(true);

			const { data: results } = await getCities(params);

			processResults(results);
		} catch (err) {
			setOptions([]);
			setSelected([]);
		} finally {
			setLoading(false);
		}
	};

	const onSearchChange = useCallback(
		debounce((q) => searchCity(q), 500),
		[],
	);

	useEffect(() => {
		const fetchCurrentValue = async () => {
			const { data: result } = await getCities({ id: value });

			const processed = processResults(result);

			setSelected(processed);
		};

		if (value) fetchCurrentValue();
	}, [value]);

	// useEffect(() => {
	// 	const fetchOptions = async () => {
	// 		const params = {};

	// 		if (province) {
	// 			params.province = province;
	// 		}

	// 		const { data: result } = await getCities(params);

	// 		processResults(result);
	// 	};

	// 	if (!options.length) fetchOptions();
	// }, [options, province]);

	return (
		<EuiComboBox
			async
			isLoading={loading}
			onChange={onSelect}
			onSearchChange={onSearchChange}
			options={options}
			placeholder="Search City/Municipality"
			selectedOptions={selected}
			singleSelection={{ asPlainText: true }}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		/>
	);
};

CityMunicipalityDropdown.propTypes = {
	onChange: PropTypes.func.isRequired,
	province: PropTypes.string,
	value: PropTypes.string,
};

CityMunicipalityDropdown.defaultProps = {
	province: null,
	value: null,
};

export default CityMunicipalityDropdown;
