import React from 'react';
import { EuiText, EuiSpacer } from '@elastic/eui';

const PrivacyPolicy = () => (
	<EuiText>
		<h3>Privacy Policy for Unexus Medical Solutions, Inc.</h3>
		<p>
			At MedAlert Rx Relay, accessible from https://rx.medalert.ph, one of
			our main priorities is the privacy of our visitors. This Privacy
			Policy document contains types of information that is collected and
			recorded by MedAlert Rx Relay and how we use it.
		</p>
		<p>
			If you have additional questions or require more information about
			our Privacy Policy, do not hesitate to contact us.
		</p>
		<p>
			This Privacy Policy applies only to our online activities and is
			valid for visitors to our website with regards to the information
			that they shared and/or collect in MedAlert Rx Relay. This policy is
			not applicable to any information collected offline or via channels
			other than this website.
		</p>
		<h3>Consent</h3>
		<p>
			By using our website, you hereby consent to our Privacy Policy and
			agree to its terms.
		</p>
		<h3>Information we collect</h3>
		<p>
			The personal information that you are asked to provide, and the
			reasons why you are asked to provide it, will be made clear to you
			at the point we ask you to provide your personal information.
		</p>
		<p>
			If you contact us directly, we may receive additional information
			about you such as your name, email address, phone number, the
			contents of the message and/or attachments you may send us, and any
			other information you may choose to provide.
		</p>
		<p>
			When you register for an Account, we may ask for your contact
			information, including items such as name, company name, address,
			email address, and telephone number.
		</p>
		<h3>How we use your information</h3>
		<p>We use the information we collect in various ways, including to:</p>
		<ul>
			<li>Provide, operate, and maintain our webste and relay service</li>
			<li>
				Improve, personalize, and expand our webste and relay service
			</li>
			<li>
				Understand and analyze how you use our webste and relay service
			</li>
			<li>Develop new products, services, features, and functionality</li>
			<li>
				Communicate with you, either directly or through one of our
				partners, including for customer service, to provide you with
				updates and other information relating to the webste and relay
				service, and for marketing and promotional purposes
			</li>
			<li>Send you emails</li>
			<li>Find and prevent fraud</li>
		</ul>
		<h3>Log Files</h3>
		<p>
			MedAlert Rx Relay follows a standard procedure of using log files.
			These files log visitors when they visit websites. All hosting
			companies do this and a part of hosting services&apos; analytics.
			The information collected by log files include internet protocol
			(IP) addresses, browser type, Internet Service Provider (ISP), date
			and time stamp, referring/exit pages, and possibly the number of
			clicks. These are not linked to any information that is personally
			identifiable. The purpose of the information is for analyzing
			trends, administering the site, tracking users&apos; movement on the
			website, and gathering demographic information.
		</p>
		<h3>Third Party Privacy Policies</h3>
		<p>
			MedAlert Rx Relay&apos;s Privacy Policy does not apply to other
			advertisers or websites. Thus, we are advising you to consult the
			respective Privacy Policies of these third-party ad servers for more
			detailed information. It may include their practices and
			instructions about how to opt-out of certain options.
		</p>
		<p>
			You can choose to disable cookies through your individual browser
			options. To know more detailed information about cookie management
			with specific web browsers, it can be found at the browsers&apos;
			respective websites.
		</p>
		<h3>GDPR Data Protection Rights</h3>
		<p>
			We would like to make sure you are fully aware of all of your data
			protection rights. Every user is entitled to the following:
		</p>
		<p>
			The right to access – You have the right to request copies of your
			personal data. We may charge you a small fee for this service. The
			right to rectification – You have the right to request that we
			correct any information you believe is inaccurate. You also have the
			right to request that we complete the information you believe is
			incomplete.
		</p>
		<p>
			The right to erasure – You have the right to request that we erase
			your personal data, under certain conditions.
		</p>
		<p>
			The right to restrict processing – You have the right to request
			that we restrict the processing of your personal data, under certain
			conditions.
		</p>
		<p>
			The right to object to processing – You have the right to object to
			our processing of your personal data, under certain conditions.
		</p>
		<p>
			The right to data portability – You have the right to request that
			we transfer the data that we have collected to another organization,
			or directly to you, under certain conditions.
		</p>
		<p>
			If you make a request, we have one month to respond to you. If you
			would like to exercise any of these rights, please contact us.
		</p>
		<EuiSpacer />
	</EuiText>
);

export default PrivacyPolicy;
