import React, { useState } from 'react';
import {
	EuiFlyout,
	EuiFlyoutHeader,
	EuiFlyoutBody,
	EuiFlyoutFooter,
	EuiFlexGroup,
	EuiFlexItem,
	EuiButtonEmpty,
	EuiButton,
	EuiText,
	EuiForm,
	EuiFormRow,
	EuiFieldText,
	EuiFlexGrid,
	EuiSpacer,
	EuiFieldNumber,
	EuiTextArea,
	EuiFilePicker,
	EuiImage,
} from '@elastic/eui';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import NoProductPhoto from 'assets/images/no-product-photo.png';

const ProductSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, 'Product Name must be at least 2 characters')
		.required('Product Name is required'),
	price: Yup.number()
		.default(0)
		.min(0, 'Price must be greater than or equal to 0'),
});

const AddProduct = ({ addProduct, isLoading, onClose, setError, visible }) => {
	const { t } = useTranslation();
	const [files, setFiles] = useState({});

	const {
		errors,
		handleChange,
		handleSubmit,
		initialValues,
		resetForm,
		touched,
		values,
	} = useFormik({
		initialValues: {
			productId: '',
			name: '',
			generic: '',
			sku: '',
			price: '',
			brand: {
				id: '',
				name: '',
			},
			productCategory: {
				id: '',
				name: '',
			},
			quantity: 0,
			description: '',
			productLogs: [],
			totalAmount: 0,
			vatAmount: 0,
		},
		validateOnBlur: true,
		validationSchema: ProductSchema,
		onSubmit: async (data) => {
			try {
				addProduct(data);
			} catch (err) {
				setError(err.message || 'Something went wrong');
			} finally {
				setError(null);
				resetForm({ values: initialValues });
			}
		},
	});

	const { description, generic, name, price, sku } = values;

	const renderFiles = () => {
		if (files.length > 0) {
			return (
				<ul>
					{Object.keys(files).map((item) => (
						<li key={files[item].name}>
							<strong>{files[item].name}</strong> (
							{files[item].size} bytes)
						</li>
					))}
				</ul>
			);
		}
		return (
			<EuiImage
				allowFullScreen={false}
				alt="Accessible image alt goes here"
				// caption="Medium"
				hasShadow
				size="l"
				url={NoProductPhoto}
			/>
		);
	};

	if (!visible) return null;

	return (
		<EuiFlyout
			closeButtonAriaLabel="Close Add Order"
			onClose={() => {
				onClose();
				resetForm({ values: initialValues });
			}}
			size="m"
			// TODO: move to stylesheet
			style={{ zIndex: 0 }}
		>
			<EuiFlyoutHeader hasBorder>
				<EuiText>
					<h3>Add Product</h3>
				</EuiText>
			</EuiFlyoutHeader>
			<EuiFlyoutBody>
				<EuiForm>
					<EuiFlexItem grow={false}>
						<EuiFlexGrid columns={2}>
							<EuiFlexItem>
								<EuiText>
									<h3>Product Information</h3>
								</EuiText>
								<EuiSpacer />
								<EuiFlexItem>
									<EuiFlexItem>
										<EuiText>{renderFiles()}</EuiText>
									</EuiFlexItem>
									{/* DisplayToggles wrapper for Docs only */}
									<EuiFilePicker
										aria-label="Use aria labels when no actual label is in use"
										display="large"
										id="asdf2"
										initialPromptText="Select or drag and drop multiple files"
										multiple
										onChange={setFiles}
									/>
								</EuiFlexItem>
							</EuiFlexItem>
							<EuiFlexItem>
								<EuiFlexGroup>
									<EuiFlexItem>
										<EuiSpacer />
										<EuiFormRow
											error={touched.name && errors.name}
											isInvalid={
												touched.name && !!errors.name
											}
											label={t('products.name')}
										>
											<EuiFieldText
												data-testid="name-input"
												id="name"
												isInvalid={
													touched.name &&
													!!errors.name
												}
												name="name"
												onChange={handleChange}
												value={name}
											/>
										</EuiFormRow>
										<EuiFormRow label="Generic Name">
											<EuiFieldText
												data-testid="generic-input"
												id="generic"
												name="generic"
												onChange={handleChange}
												placeholder="Generic Name"
												value={generic}
											/>
										</EuiFormRow>
										<EuiFormRow label="SKU">
											<EuiFieldText
												data-testid="sku-input"
												id="sku"
												name="sku"
												onChange={handleChange}
												placeholder="SKU"
												value={sku}
											/>
										</EuiFormRow>
										<EuiFormRow
											error={
												touched.price && errors.price
											}
											isInvalid={
												touched.price && !!errors.price
											}
											label={t('products.price')}
										>
											<EuiFieldNumber
												id="price"
												isInvalid={!!errors.price}
												min={0}
												name="price"
												onChange={handleChange}
												value={price}
											/>
										</EuiFormRow>
										<EuiFormRow
											label={t('products.description')}
										>
											<EuiTextArea
												data-testid="description-input"
												id="description"
												name="description"
												onChange={handleChange}
												value={description}
											/>
										</EuiFormRow>
									</EuiFlexItem>
								</EuiFlexGroup>
								<EuiSpacer />
							</EuiFlexItem>
						</EuiFlexGrid>
					</EuiFlexItem>
				</EuiForm>
			</EuiFlyoutBody>
			<EuiFlyoutFooter>
				<EuiFlexGroup
					direction="row"
					gutterSize="m"
					justifyContent="spaceBetween"
				>
					<EuiFlexItem>
						<EuiButtonEmpty
							color="danger"
							iconType="crossInACircleFilled"
							onClick={() => {
								onClose();
								resetForm({ values: initialValues });
							}}
						>
							{t('general.cancel')}
						</EuiButtonEmpty>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiButton
							disabled={isLoading}
							fill
							iconType="checkInCircleFilled"
							onClick={handleSubmit}
						>
							{t('general.saveChanges')}
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiFlyoutFooter>
		</EuiFlyout>
	);
};

AddProduct.propTypes = {
	addProduct: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	onClose: PropTypes.bool.isRequired,
	setError: PropTypes.string.isRequired,
	visible: PropTypes.bool.isRequired,
};

export default AddProduct;
