import React from 'react';
import { useDispatch } from 'react-redux';
import { FiSend } from 'react-icons/fi';

import { EuiToolTip, EuiButtonIcon } from '@elastic/eui';

import PropTypes from 'prop-types';

import { openSendRxModal } from '../sendRxModal.actions';

const SendToPatient = ({ rxId, sent, size }) => {
	const dispatch = useDispatch();

	const handleOpen = () => {
		dispatch(openSendRxModal(rxId));
	};

	return (
		<EuiToolTip
			content={`${!sent ? 'Send' : 'Resend'} To Patient`}
			position="top"
		>
			<EuiButtonIcon
				iconType={() => <FiSend size={size} />}
				onClick={handleOpen}
			/>
		</EuiToolTip>
	);
};

SendToPatient.propTypes = {
	rxId: PropTypes.string.isRequired,
	sent: PropTypes.bool,
	size: PropTypes.number,
};

SendToPatient.defaultProps = {
	sent: false,
	size: 14,
};

export default SendToPatient;
