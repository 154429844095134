import React, { useState } from 'react';

import { EuiShowFor } from '@elastic/eui';
import { FaFilePdf } from 'react-icons/fa';

import PropTypes from 'prop-types';

import PdfViewerModal from 'components/PdfViewerModal';

const PdfThumbnail = ({ data, onClose, onOpen, visible }) => {
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);

	const handleOpenPdf = (file) => {
		setSelectedFile(file);
		setIsOpenModal(true);
		if (onOpen) {
			onOpen();
		}
	};

	const handleClosePdf = () => {
		setIsOpenModal(false);
		if (onClose) {
			onClose();
		}
	};

	if (!visible) return null;

	return (
		<>
			{/* Preview Icon Type */}

			<FaFilePdf
				className="thumbnail-img"
				onClick={() => handleOpenPdf(data)}
				size={120}
				style={{ cursor: 'pointer' }}
			/>
			<EuiShowFor sizes={['xs', 's']}>
				<PdfViewerModal
					file={selectedFile}
					onClose={handleClosePdf}
					small
					visible={isOpenModal}
				/>
			</EuiShowFor>
			<EuiShowFor sizes={['m', 'l', 'xl']}>
				<PdfViewerModal
					file={selectedFile}
					onClose={handleClosePdf}
					visible={isOpenModal}
				/>
			</EuiShowFor>
		</>
	);
};

PdfThumbnail.propTypes = {
	data: PropTypes.shape({
		name: PropTypes.string,
		url: PropTypes.string,
	}),
	onClose: PropTypes.func,
	onOpen: PropTypes.func,
	// size: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
	visible: PropTypes.bool,
};

PdfThumbnail.defaultProps = {
	data: null,
	onClose: () => {},
	onOpen: () => {},
	// size: 's',
	visible: true,
};
export default PdfThumbnail;
