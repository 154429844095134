import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	EuiButton,
	EuiFieldText,
	EuiForm,
	EuiFormRow,
	EuiHorizontalRule,
	EuiSpacer,
	EuiText,
	EuiTitle,
} from '@elastic/eui';
import { useFormik } from 'formik';
import * as yup from 'yup';
import _ from 'lodash';

import AddressForm from 'components/AddressForm';

import { updateUserDetails } from 'modules/auth/auth.actions';
import { addToast } from 'modules/toasts/toasts.actions';
import { createClinic, getClinic, updateClinic } from './doctors.fetch';

const ClinicSchema = yup.object().shape({
	id: yup.string().nullable(),
	name: yup.string().required('Clinic Name is required'),
	line1: yup.string().required('Address Line 1 is required'),
	line2: yup.string().nullable(),
	city: yup.string().nullable(),
	cityId: yup.string().required('City is required.'),
	brgy: yup.string().nullable(),
	brgyId: yup.string().nullable(),
});

const Clinic = () => {
	const { user } = useSelector((state) => ({
		user: state.auth.user,
	}));
	const dispatch = useDispatch();
	const [clinic, setClinic] = useState();
	const [loading, setLoading] = useState(false);

	const { details } = user;

	const formikBag = useFormik({
		initialValues: {
			name: _.get(clinic, 'name', ''),
			addressId: _.get(clinic, 'address.id', ''),
			line1: _.get(clinic, 'address.line1', ''),
			line2: _.get(clinic, 'address.line2', ''),
			city: _.get(clinic, 'address.city', ''),
			cityId: _.get(clinic, 'address.cityId', ''),
			brgy: _.get(clinic, 'address.brgy', ''),
			brgyId: _.get(clinic, 'address.brgyId', ''),
		},
		validationSchema: ClinicSchema,
		enableReinitialize: true,
		onSubmit: async (data) => {
			const payload = {
				name: data.name,
				address: {
					line1: data.line1,
					line2: data.line2,
					city: data.city,
					cityId: data.cityId,
					brgy: data.brgy,
					brgyId: data.brgyId,
				},
			};

			const isNew = !!(clinic && clinic.id);
			let result = null;

			try {
				setLoading(true);
				if (isNew) {
					result = await updateClinic(clinic.id, payload);
				} else {
					result = await createClinic(payload);
				}

				if (result.data) {
					setClinic(result.data);

					dispatch(
						addToast(
							'Successfully saved clinic data',
							null,
							'success',
						),
					);

					dispatch(
						updateUserDetails({
							...user,
							details: {
								...user.details,
								clinic: result.data,
							},
						}),
					);
				}
			} catch (err) {
				if (clinic) setClinic(clinic);

				dispatch(
					addToast(
						'Error',
						err.message || 'Something went wrong',
						'danger',
						'help',
					),
				);
			} finally {
				setLoading(false);
			}
		},
	});

	const {
		dirty,
		errors,
		handleBlur,
		handleChange,
		handleSubmit,
		setFieldValue,
		touched,
		values,
	} = formikBag;

	useEffect(() => {
		const fetchClinic = async () => {
			const { data } = await getClinic(details.clinic.id);

			setClinic(data);
			setFieldValue('name', _.get(data, 'name', ''));
			setFieldValue('addressId', _.get(data, 'address.id', ''));
			setFieldValue('line1', _.get(data, 'address.line1', ''));
			setFieldValue('line2', _.get(data, 'address.line2', ''));
			setFieldValue('city', _.get(data, 'address.city', ''));
			setFieldValue('cityId', _.get(data, 'address.cityId', ''));
			setFieldValue('brgy', _.get(data, 'address.brgy', ''));
			setFieldValue('brgyId', _.get(data, 'address.brgyId', ''));
		};

		if (!clinic && details.clinic) fetchClinic();
	}, [clinic]);

	return (
		<EuiForm style={{ maxWidth: 400 }}>
			<EuiSpacer size="m" />
			<EuiFormRow>
				<EuiTitle size="xs">
					<h3>Clinic Information</h3>
				</EuiTitle>
			</EuiFormRow>
			<EuiHorizontalRule margin="xs" />
			<EuiFormRow
				error={touched.name && errors.name}
				fullWidth
				isInvalid={touched.name && !!errors.name}
				label="Clinic Name"
			>
				<EuiFieldText
					id="name"
					isInvalid={touched.name && !!errors.name}
					name="name"
					onBlur={handleBlur}
					onChange={handleChange}
					value={values.name}
				/>
			</EuiFormRow>
			<EuiFormRow>
				<AddressForm formikBag={formikBag} />
			</EuiFormRow>
			<EuiHorizontalRule margin="m" />
			<EuiFormRow>
				<EuiButton
					color="primary"
					disabled={!dirty}
					fill
					isLoading={loading}
					onClick={handleSubmit}
				>
					<EuiText>
						{clinic ? 'Save Changes' : 'Create Clinic'}
					</EuiText>
				</EuiButton>
			</EuiFormRow>
		</EuiForm>
	);
};

export default Clinic;
