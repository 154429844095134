import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import auth from 'modules/auth/auth.reducer';
import dashboard from 'modules/dashboard/dashboard.reducer';
import prescriptions from 'modules/prescriptions/prescriptions.reducer';
import toasts from 'modules/toasts/toasts.reducer';
import lightbox from 'modules/lightbox/lightbox.reducer';
import sendRxModal from 'modules/prescriptions/sendRxModal.reducer';

const authPersistConfig = {
	key: 'auth',
	storage: sessionStorage,
};

const rootReducer = combineReducers({
	dashboard,
	auth: persistReducer(authPersistConfig, auth),
	prescriptions,
	toasts,
	lightbox,
	sendRxModal,
});

export default rootReducer;
