import React, { useEffect } from 'react';

import { useFormik } from 'formik';

import PropTypes from 'prop-types';

import { keys, pickBy, identity } from 'lodash';

import CommentForm from './CommentForm';

const Comment = ({
	attachments,
	branchName,
	entities,
	event,
	hasActions,
	hasChanges,
	id,
	isLoading,
	isSuccess,
	onDelete,
	onSubmit,
	readOnly,
	role,
	setHasChanges,
	timestamp,
	userDetailId,
	username,
	value,
}) => {
	const formikBag = useFormik({
		initialValues: {
			id: id || null,
			activeFieldName: '',
			comment: value,
			prescription: null,
			order: null,
			attachments: attachments || [],
			deletedFiles: [],
			event: !id ? 'added comment' : 'edited comment',
			...entities,
			[role === 'call-center' ? 'callCenter' : role]: userDetailId,
		},
		// validationSchema: CommentSchema,
		enableReinitialize: true,
		onSubmit,
	});

	const {
		handleSubmit,
		resetForm,
		setFieldTouched,
		setFieldValue,
		setTouched,
		touched,
		values,
	} = formikBag;

	useEffect(() => {
		if (!hasChanges) {
			setHasChanges(keys(pickBy(touched, identity)).length > 0);
		}
	}, [touched]);

	useEffect(() => {
		function reset(isSuccessSubmitting) {
			if (isSuccessSubmitting) {
				resetForm();
				setTouched({});
				setHasChanges(false);
			}
		}
		reset(isSuccess);
	}, [isSuccess]);

	return (
		<CommentForm
			attachments={values.attachments}
			branchName={branchName}
			deletedFiles={values.deletedFiles}
			event={event}
			hasActions={hasActions}
			id={id}
			isLoading={isLoading}
			onCancel={resetForm}
			onChange={setFieldValue}
			onDelete={onDelete}
			onSubmit={handleSubmit}
			readOnly={readOnly}
			resetForm={isSuccess}
			role={role}
			setFieldTouched={setFieldTouched}
			setFieldValue={setFieldValue}
			timestamp={timestamp}
			username={username}
			value={values.comment}
		/>
	);
};

Comment.propTypes = {
	attachments: PropTypes.arrayOf(PropTypes.shape({})),
	branchName: PropTypes.string,
	entities: PropTypes.shape({}),
	event: PropTypes.string,
	hasActions: PropTypes.bool,
	hasChanges: PropTypes.bool,
	id: PropTypes.string,
	isLoading: PropTypes.bool,
	isSuccess: PropTypes.bool,
	onDelete: PropTypes.func,
	onSubmit: PropTypes.func,
	readOnly: PropTypes.bool,
	role: PropTypes.string,
	setHasChanges: PropTypes.func,
	timestamp: PropTypes.string,
	userDetailId: PropTypes.string,
	username: PropTypes.string,
	value: PropTypes.string,
};

Comment.defaultProps = {
	attachments: [],
	branchName: '',
	entities: {},
	event: '',
	hasActions: false,
	hasChanges: false,
	id: '',
	isLoading: false,
	isSuccess: false,
	onDelete: () => {},
	onSubmit: () => {},
	readOnly: false,
	role: '',
	setHasChanges: () => {},
	timestamp: '',
	userDetailId: null,
	username: '',
	value: '',
};

export default Comment;
