import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	EuiPopover,
	EuiHeaderSectionItemButton,
	EuiButton,
	EuiFlexItem,
	EuiPopoverTitle,
	EuiText,
	EuiPopoverFooter,
	EuiPanel,
	EuiIcon,
	EuiPageSection,
} from '@elastic/eui';
import pluralize from 'pluralize';
import { useTranslation } from 'react-i18next';
import { addToast } from 'modules/toasts/toasts.actions';
import {
	RECENT_NOTIFICATION_DEFAULT_SORT,
	RECENT_NOTIFICATION_LIMIT,
	NOTIFICATION_TYPE_APP,
} from 'constants/notifications';
import { formatDate } from 'utils/helpers';
import {
	getNotifications,
	getUnreadNotificationsCount,
} from '../modules/_global/global.fetch';
import { readNotification } from '../modules/push-notifications/push-notifications.fetch';

const Notifications = () => {
	const auth = useSelector((state) => state.auth);
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [error, setError] = useState(null);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const [limit, setLimit] = useState(RECENT_NOTIFICATION_LIMIT);
	const [notifications, setNotifications] = useState([]);
	const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const hasNotifications = notifications.length > 0;
	const exceedShowLimit = RECENT_NOTIFICATION_LIMIT > notifications.length;

	useEffect(() => {
		async function fetchNotificationsCount() {
			try {
				const result = await getUnreadNotificationsCount({
					type: NOTIFICATION_TYPE_APP,
					read: false,
				});

				setUnreadNotificationsCount(result.data);
			} catch (err) {
				setError(err.message || 'Something went wrong');
			} finally {
				setError(null);
			}
		}

		async function fetchNotifications() {
			setIsLoading(true);
			try {
				const results = await getNotifications({
					type: NOTIFICATION_TYPE_APP,
					_limit: limit,
					_sort: RECENT_NOTIFICATION_DEFAULT_SORT,
				});
				setNotifications(results.data);
			} catch (err) {
				setError(err.message || 'Something went wrong');
			} finally {
				setError(null);
				setIsLoading(false);
			}
		}

		if (auth.isLoggedIn) {
			fetchNotificationsCount();
			if (isPopoverOpen) fetchNotifications();
		}
	}, [isPopoverOpen, limit, auth]);

	useEffect(() => {
		function toastError() {
			dispatch(addToast('Error', error, 'danger', 'help'));
		}
		if (error) toastError();
	}, [error]);

	const handleOpenNotifications = () =>
		setIsPopoverOpen((isPopoverOpenVal) => !isPopoverOpenVal);

	const handleCloseNotifications = () => setIsPopoverOpen(false);

	const handleRead = async (notification) => {
		history.push(
			`/${pluralize(notification.resource)}/${notification.resourceId}`,
		);
		if (!notification.read) {
			await readNotification(notification.id);
		}
		handleCloseNotifications();
	};

	const handleSeeMore = async () => {
		setLimit((currentLimit) => currentLimit + RECENT_NOTIFICATION_LIMIT);
	};

	return (
		<EuiFlexItem grow={false}>
			<EuiPopover
				anchorPosition="downRight"
				button={
					<EuiHeaderSectionItemButton
						aria-label={`${unreadNotificationsCount} Notifications`}
						notification={unreadNotificationsCount}
						onClick={handleOpenNotifications}
					>
						<EuiIcon size="m" type="bell" />
					</EuiHeaderSectionItemButton>
				}
				closePopover={handleCloseNotifications}
				isOpen={isPopoverOpen}
				ownFocus
				panelPaddingSize="none"
			>
				<EuiPopoverTitle
					style={{
						padding: '10px 15px',
						minWidth: '400px',
					}}
				>
					Notifications
				</EuiPopoverTitle>
				<EuiPageSection className="notification-scrolling" size="s">
					{hasNotifications ? (
						notifications.map((notification) => (
							<EuiPanel
								key={notification.id}
								className={
									!notification.read
										? 'notification-unread'
										: 'notification-read '
								}
								onClick={async () => handleRead(notification)}
								paddingSize="m"
							>
								<div className="notification-template">
									<span className="notification-template title">
										{`${!notification.read ? '•' : ''} ${t(
											`notifications.${notification.template}`,
										)}`}
									</span>
									<span className="notification-template date">
										{formatDate(notification.createdAt)}
									</span>
								</div>
								<span>{notification.content}</span>
							</EuiPanel>
						))
					) : (
						<div style={{ padding: 25 }}>
							<EuiText textAlign="center">
								No Notifications
							</EuiText>
						</div>
					)}
				</EuiPageSection>
				<EuiPopoverFooter>
					<EuiButton
						disabled={exceedShowLimit}
						fullWidth
						isLoading={isLoading}
						onClick={handleSeeMore}
						size="s"
					>
						See More
					</EuiButton>
				</EuiPopoverFooter>
			</EuiPopover>
		</EuiFlexItem>
	);
};

export default Notifications;
