import {
	APP_DOCK_NAVIGATION,
	APP_UNDOCK_NAVIGATION,
	GET_LATEST_RX_SUCCESS,
} from 'constants/actionTypes';

export const dockAction = () => ({
	type: APP_DOCK_NAVIGATION,
});

export const undockAction = () => ({
	type: APP_UNDOCK_NAVIGATION,
});

export const getDashboardRx = (data) => ({ type: GET_LATEST_RX_SUCCESS, data });
