/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EuiFlexItem } from '@elastic/eui';
import { FaFilePrescription } from 'react-icons/fa';
import _ from 'lodash';

import PropTypes from 'prop-types';

import { ROLE_PATH } from 'constants/protect';
import { DOCTOR, SECRETARY } from 'components/roles';
import protectHOC from 'components/protectHOC';
import AddPrescription from '../AddPrescription';

const AddRxButton = ({ visible }) => {
	const { role, vendor } = useSelector((state) => ({
		vendor: _.get(state, 'auth.user.vendor.id'),
		role: _.get(state, ROLE_PATH),
	}));
	const shareRxData = JSON.parse(localStorage.getItem('data'));
	const [addVisible, setAddVisible] = useState(false);
	const [isSharing, setSharing] = useState(false);

	useEffect(() => {
		if (shareRxData) {
			document.getElementById('rxButton').click();
			setSharing(true);
		}
	}, [!shareRxData]);

	if (!visible || (!visible && addVisible)) {
		return null;
	}

	return (
		<>
			<EuiFlexItem>
				<a
					className="floating-rx-button"
					href="#"
					id="rxButton"
					onClick={() => {
						if (role === DOCTOR.key || role === SECRETARY.key) {
							setAddVisible(true);
						}
					}}
					style={{
						visibility: isSharing ? 'hidden' : '',
						backgroundColor: _.get(
							vendor,
							'primaryColor',
							'#1595d3',
						),
					}}
				>
					<FaFilePrescription
						className="add-rx-button"
						color="ghost"
						size={30}
					/>
				</a>
			</EuiFlexItem>
			<AddPrescription
				onClose={() => {
					setAddVisible(false);
				}}
				rxSharedData={shareRxData}
				visible={addVisible}
			/>
		</>
	);
};

AddRxButton.propTypes = {
	visible: PropTypes.bool,
};

AddRxButton.defaultProps = {
	visible: true,
};

export default protectHOC([DOCTOR.key, SECRETARY.key], ROLE_PATH)(AddRxButton);
