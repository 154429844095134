import { useCallback, useEffect } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const useDebouncedEffect = (effect, delay, deps) => {
	const callback = useCallback(effect, deps);

	useEffect(() => {
		const handler = setTimeout(() => {
			callback();
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [callback, delay]);
};
