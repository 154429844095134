import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useParams } from 'react-router';

import _ from 'lodash';
import * as Yup from 'yup';
import { EuiSpacer, EuiHorizontalRule } from '@elastic/eui';

import NoProfilePhoto from 'assets/images/no-profile-photo.png';
import ConfirmationModal from 'components/ConfirmationModal';
import PersonalInfomationForm from 'components/PersonalInfomationForm';

import { updatePassword } from '../auth/auth.fetch';
import ProfilePage from '../../components/ProfilePage';
import AccountTab from '../../components/AccountTab';
import TabLabel from '../../components/TabLabel';

import { addToast } from '../toasts/toasts.actions';
import { getSecretary, updateSecretary } from './secretaries.fetch';

const SecretarySchema = Yup.object().shape({
	firstName: Yup.string().required('First Name required'),
	lastName: Yup.string().required('Last Name required'),
	email: Yup.string().required('Email required'),
});

const Secretary = () => {
	// const { t } = useTranslation();
	const { secretaryId } = useParams();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const [secretary, setSecretary] = useState({});
	const [hasChanges, setHasChanges] = useState(false);
	const [isConfirmationModalVisible, setConfirModalVisible] = useState(false);

	const formikBag = useFormik({
		initialValues: {
			firstName: secretary.firstName,
			middleName: secretary.middleName,
			lastName: secretary.lastName,
			birthdate: secretary.birthdate,
			email: secretary.email?.value || '',
			mobile: secretary.mobile?.value || '',
			attachments: secretary.attachments || {
				url: NoProfilePhoto,
			},
		},
		validationSchema: SecretarySchema,
		validateOnBlur: true,
		enableReinitialize: true,
		onSubmit: async (data, { resetForm: reset }) => {
			setIsLoading(true);
			try {
				const payload = {
					firstName: data.firstName,
					middleName: data.middleName,
					lastName: data.lastName,
					birthdate: data.birthdate,
				};

				const contactDetails = [];
				if (data.email) {
					contactDetails.push({
						type: 'email',
						value: data.email,
					});
				}
				if (data.mobile) {
					contactDetails.push({
						type: 'mobile',
						value: data.mobile,
					});
				}

				if (contactDetails.length)
					payload.contactDetails = contactDetails;

				let result = await updateSecretary(secretaryId, payload);
				result = result.data;

				result.email = result.contactDetails.find(
					(contactDetail) => contactDetail.type === 'email',
				);
				result.mobile = result.contactDetails.find(
					(contactDetail) => contactDetail.type === 'mobile',
				);
				delete result.contactDetails;

				setSecretary(result);
				dispatch(
					addToast(
						'Profile updated',
						'Changes Saved',
						'success',
						'check',
					),
				);
			} catch (err) {
				dispatch(addToast('Error', err, 'danger', 'help'));
				reset();
			} finally {
				setIsLoading(false);
			}
		},
	});

	const { errors, handleSubmit, initialValues, resetForm, touched, values } =
		formikBag;

	useEffect(() => {
		setHasChanges(_.isEqual(initialValues, values));
	}, [values]);

	useEffect(() => {
		async function fetchSecretary() {
			try {
				const { data } = await getSecretary(secretaryId);

				data.email = data.contactDetails.find(
					(contactDetail) => contactDetail.type === 'email',
				);
				data.mobile = data.contactDetails.find(
					(contactDetail) => contactDetail.type === 'mobile',
				);
				delete data.contactDetails;

				setSecretary(data);
			} catch (err) {
				dispatch(addToast('Error', err, 'danger', 'help'));
			} finally {
				setIsLoading(false);
			}
		}
		fetchSecretary();
	}, [secretaryId]);

	const handleToggleConfirmModal = () => {
		setConfirModalVisible((isOpen) => !isOpen);
	};

	const handleConfirmModal = () => {
		resetForm({ values: initialValues });
		handleToggleConfirmModal();
	};

	const tabs = [
		{
			id: 'profileTab',
			name: (
				<TabLabel
					isInvalid={
						(touched.firstName && !!errors.firstName) ||
						(touched.lastName && !!errors.lastName)
					}
					text="Profile"
				/>
			),
			content: (
				<>
					<EuiSpacer />
					<PersonalInfomationForm formikBag={formikBag} />
					<EuiHorizontalRule margin="l" />
				</>
			),
		},
		{
			id: 'accountTab',
			name: (
				<TabLabel
					isInvalid={
						(touched.line1 && !!errors.line1) ||
						(touched.cityId && !!errors.cityId)
					}
					text="Account"
				/>
			),
			content: (
				<>
					<AccountTab
						onSubmit={updatePassword}
						requireOldPassword={false}
						user={secretary.user}
					/>
				</>
			),
		},
	];

	return (
		<>
			<ProfilePage
				data={secretary}
				hasChanges={hasChanges}
				initialSelectedTab={0}
				isLoading={isLoading}
				onCancel={handleToggleConfirmModal}
				onSubmit={handleSubmit}
				tabs={tabs}
				title="Secretary"
			/>
			<ConfirmationModal
				isVisible={isConfirmationModalVisible}
				message="Cancel Changes?"
				onClose={handleToggleConfirmModal}
				onConfirm={handleConfirmModal}
				title="Confirm"
			/>
		</>
	);
};

export default Secretary;
