import React from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import {
	EuiFlyout,
	EuiFlyoutHeader,
	EuiFlyoutBody,
	EuiFlyoutFooter,
	EuiFlexGroup,
	EuiFlexItem,
	EuiButtonEmpty,
	EuiButton,
	EuiText,
	EuiTitle,
	EuiSpacer,
	EuiHorizontalRule,
} from '@elastic/eui';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import * as yup from 'yup';

import PersonalInformationForm from 'components/PersonalInfomationForm';
import AddressForm from 'components/AddressForm';
import { addToast } from 'modules/toasts/toasts.actions';
import { createPatient, patientModel } from './patients.fetch';

const AddPatientValidationSchema = yup.object().shape({
	firstName: yup.string().required('First Name is required'),
	lastName: yup.string().required('Last Name is required'),
	birthdate: yup.string().nullable().required('Birthdate is required'),
	email: yup.string().email('Invalid email'),
	mobile: yup
		.number()
		.typeError('Invalid mobile number')
		.required('Mobile number is required'),
	line1: yup.string().nullable(),
	cityId: yup.string().nullable(),
	brgyId: yup.string().nullable(),
});

// eslint-disable-next-line react/prop-types
const AddPatient = ({ onClose, visible }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { isLoading, mutate } = useMutation(createPatient, {
		onSuccess: () => {
			dispatch(
				addToast(
					'Success',
					'Successfully added new patient record',
					'success',
					'check',
				),
			);
		},
		onError: (err) => {
			dispatch(
				addToast(
					'Error',
					err.message || 'Something went wrong',
					'danger',
					'help',
				),
			);
		},
		onSettled: onClose,
	});

	const formikBag = useFormik({
		initialValues: { ...patientModel },
		validationSchema: AddPatientValidationSchema,
		validateOnBlur: true,
		onSubmit: async (data, { resetForm: reset }) => {
			mutate(data);
			reset();
		},
	});
	const { handleSubmit, initialValues, resetForm } = formikBag;

	if (!visible) return null;

	return (
		<EuiFlyout
			closeButtonAriaLabel="Close Add Order"
			onClose={() => {
				onClose();
				resetForm({ values: initialValues });
			}}
			size="s"
			// TODO: move to stylesheet
			// style={{ zIndex: 0 }}
		>
			<EuiFlyoutHeader hasBorder>
				<EuiText>
					<h3>Add Patient</h3>
				</EuiText>
			</EuiFlyoutHeader>
			<EuiFlyoutBody>
				<EuiSpacer size="l" />
				<EuiTitle size="xs">
					<h3>Patient Information</h3>
				</EuiTitle>
				<EuiHorizontalRule margin="m" />
				<PersonalInformationForm formikBag={formikBag} />
				<EuiSpacer size="l" />
				<EuiTitle size="xs">
					<h3>Address</h3>
				</EuiTitle>
				<EuiHorizontalRule margin="m" />
				<AddressForm formikBag={formikBag} />
			</EuiFlyoutBody>
			<EuiFlyoutFooter>
				<EuiFlexGroup
					direction="row"
					gutterSize="m"
					justifyContent="spaceBetween"
				>
					<EuiFlexItem>
						<EuiButtonEmpty
							color="danger"
							iconType="crossInACircleFilled"
							onClick={() => {
								onClose();
								resetForm({ values: initialValues });
							}}
						>
							{t('general.cancel')}
						</EuiButtonEmpty>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiButton
							disabled={isLoading}
							fill
							iconType="checkInCircleFilled"
							onClick={handleSubmit}
						>
							{t('general.saveChanges')}
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiFlyoutFooter>
		</EuiFlyout>
	);
};

export default AddPatient;
