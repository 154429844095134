import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import {
	EuiButtonEmpty,
	EuiFlexGroup,
	EuiFlexItem,
	EuiSpacer,
	EuiText,
	EuiIcon,
} from '@elastic/eui';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { postDoctorRegistration } from './auth.fetch';
import RegistrationForm from './RegistrationForm';

function Registration() {
	const { t } = useTranslation();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [errors, setErrors] = useState(false);

	const RegistrationSchema = yup.object().shape({
		email: yup.string().email().required('Email is required'),
		password: yup
			.string()
			.min(8, 'Must be at least 8 characters')
			.required('Password is required'),
		confirmPassword: yup
			.string()
			.oneOf([yup.ref('password'), null], "Passwords don't match")
			.required('Password must be confirmed'),
		username: yup
			.string()
			.matches(
				/^[ A-Za-z0-9_@./#&+-]{3,255}$/gm,
				'Only letters, numbers and symbols (-_.) are allowed',
			)
			.required('Username is required'),
		firstName: yup.string().required('First Name is required'),
		lastName: yup.string().required('Last Name is required'),
		middleName: yup.string(),
		acceptTerms: yup
			.boolean()
			.oneOf(
				[true],
				'You must accept the "terms & conditions" and the "privacy policy"',
			),
	});

	// Setup Formik
	const formikBag = useFormik({
		initialValues: {
			firstName: '',
			middleName: '',
			lastName: '',
			email: '',
			username: '',
			password: '',
			confirmPassword: '',
			acceptTerms: false,
			mailingList: false,
		},
		validationSchema: RegistrationSchema,
		validateOnBlur: true,
		// eslint-disable-next-line no-unused-vars
		onSubmit: async (payload, { resetForm, setFieldValue }) => {
			try {
				setIsLoading(true);
				await postDoctorRegistration(payload);
				setSuccess(true);
				resetForm({
					firstName: '',
					middleName: '',
					lastName: '',
					email: '',
					username: '',
					password: '',
					confirmPassword: '',
				});
			} catch (err) {
				const errorResponse = ((err || {}).response || {}).data || null;
				setFieldValue('password', '');
				setFieldValue('confirmPassword', '');
				const errorsGenerated = {};
				if (
					errorResponse &&
					errorResponse.message &&
					errorResponse.message === 'Email address already exist.'
				) {
					errorsGenerated.email = 'Email address already exist';
				}
				setErrors(errorsGenerated);
			} finally {
				setIsLoading(false);
			}
		},
	});

	const backToLogin = () => history.push('/doctor-login');

	return (
		<>
			<Helmet title={t('register.title')} />
			<EuiText textAlign="center">
				<h3>{t('register.title')}</h3>
			</EuiText>
			<EuiSpacer size="m" />
			{success ? (
				<EuiFlexGroup
					alignContent="center"
					alignItems="center"
					direction="row"
					justifyContent="center"
					style={{ minHeight: 476 }}
				>
					<EuiFlexItem style={{ maxWidth: 250 }}>
						<EuiText textAlign="center">
							<EuiIcon type="checkInCircleFilled" /> Registration
							Successful.
							<EuiSpacer />
							Please check your email to confirm your account
							<EuiSpacer />
						</EuiText>
					</EuiFlexItem>
				</EuiFlexGroup>
			) : (
				<RegistrationForm
					errors={errors}
					formik={formikBag}
					isLoading={isLoading}
				/>
			)}
			<EuiSpacer size="s" />
			<EuiButtonEmpty onClick={backToLogin} size="xs">
				{t('general.goBack')}
			</EuiButtonEmpty>
		</>
	);
}

export default Registration;
