import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';

import {
	EuiButton,
	EuiCallOut,
	EuiCheckbox,
	EuiForm,
	EuiFormRow,
	EuiFieldText,
	EuiFieldPassword,
	EuiSpacer,
} from '@elastic/eui';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { loginAction } from './auth.actions';
import { postLogin } from './auth.fetch';

const LoginSchema = Yup.object().shape({
	identifier: Yup.string().required('Username or Email is required'),
	password: Yup.string().required('Password is required'),
	remember: Yup.bool(),
});

const emailValid = Yup.string().email();

const LoginForm = () => {
	const dispatch = useDispatch();
	const [error, setError] = useState(null);
	const urlParams = new URLSearchParams(window.location.search);
	const username = urlParams.get('username');

	const loginMutation = useMutation(postLogin, {
		onMutate: () => {
			setError(null);
		},
		onSuccess: (data, values) => {
			dispatch(
				loginAction({
					...data,
					rememberMe: values.rememberMe,
				}),
			);

			window.addEventListener('unload', (ev) => {
				ev.preventDefault();
				if (!values.rememberMe) {
					localStorage.clear();
				}
			});
		},
		onError: (err) => {
			setError(err.message || 'Something went wrong');
		},
	});

	const onSubmit = async (values) => {
		const isEmail = await emailValid.isValid(values.identifier);

		loginMutation.mutate({
			...values,
			identifier: isEmail
				? values.identifier.toLowerCase()
				: values.identifier,
		});
	};

	// Setup Formik
	const formik = useFormik({
		initialValues: {
			identifier: username ?? '',
			password: '',
			rememberMe: false,
		},
		validationSchema: LoginSchema,
		validateOnBlur: true,
		onSubmit,
	});

	useEffect(() => {
		// eslint-disable-next-line consistent-return
		const listener = (event) => {
			if (event.code === 'Enter' || event.code === 'NumpadEnter') {
				return formik.handleSubmit();
			}
		};
		document.addEventListener('keydown', listener);
		return () => {
			document.removeEventListener('keydown', listener);
		};
	}, []);

	const disableButton = Object.keys(formik.errors).length > 0;

	return (
		<EuiForm>
			{error && (
				<>
					<EuiCallOut color="danger" size="s" title={error} />
					<EuiSpacer size="s" />
				</>
			)}
			<EuiFormRow
				error={formik.errors.identifier}
				fullWidth
				isInvalid={!!formik.errors.identifier}
				label="Enter Username or E-mail"
			>
				<EuiFieldText
					data-testid="identifier-input"
					fullWidth
					icon="user"
					id="identifier"
					isInvalid={!!formik.errors.identifier}
					name="identifier"
					onChange={formik.handleChange}
					placeholder="Username or E-mail"
					value={formik.values.identifier}
				/>
			</EuiFormRow>
			<EuiFormRow
				error={formik.errors.password}
				fullWidth
				isInvalid={!!formik.errors.password}
				label="Enter Password"
			>
				<EuiFieldPassword
					data-testid="password-input"
					fullWidth
					id="password"
					isInvalid={!!formik.errors.password}
					name="password"
					onChange={formik.handleChange}
					placeholder="Password"
					type="dual"
				/>
			</EuiFormRow>
			<EuiFormRow>
				<EuiCheckbox
					checked={formik.values.rememberMe}
					id="rememberMe"
					label="Remember Me"
					name="rememberMe"
					onChange={formik.handleChange}
				/>
			</EuiFormRow>
			<EuiFormRow fullWidth>
				<EuiButton
					color="primary"
					data-testid="login-button"
					disabled={disableButton}
					fill
					fullWidth
					isLoading={loginMutation.isLoading}
					onClick={formik.handleSubmit}
					type="submit"
				>
					Login
				</EuiButton>
			</EuiFormRow>
		</EuiForm>
	);
};

export default LoginForm;
