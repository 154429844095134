import React from 'react';
import { useHistory } from 'react-router';
import { EuiIcon, EuiShowFor } from '@elastic/eui';

export default function HeaderBackButton() {
	const { goBack, location } = useHistory();
	const isRootPage = location.pathname.match(/\//g).length === 1;

	if (isRootPage) return null;

	return (
		<EuiShowFor sizes={['xs', 's']}>
			<EuiIcon
				display="inline"
				onClick={goBack}
				size="l"
				style={{ marginRight: 5 }}
				type="arrowLeft"
			/>
		</EuiShowFor>
	);
}
