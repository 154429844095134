import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import {
	EuiDatePicker,
	EuiFilePicker,
	EuiFlyout,
	EuiFlyoutHeader,
	EuiFlyoutBody,
	EuiFlyoutFooter,
	EuiFlexGroup,
	EuiFlexItem,
	EuiButtonEmpty,
	EuiButton,
	EuiText,
	EuiForm,
	EuiFormRow,
	EuiFieldText,
	EuiSpacer,
} from '@elastic/eui';
import { getApiError, getFieldError } from 'utils/helpers';
import { addToast } from '../toasts/toasts.actions';
import { createLicense } from './licenses.fetch';

const LicenseSchema = Yup.object().shape({
	licenseNumber: Yup.string().required('License Number is required'),
	expiry: Yup.date().required('Expiration Date is required').nullable(),
	image: Yup.string().required('License Photo is required'),
});

const AddLicense = ({ onClose, setLicenses, visible }) => {
	const dispatch = useDispatch();
	const [image, setImage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const {
		errors,
		handleChange,
		handleSubmit,
		initialValues,
		resetForm,
		setFieldError,
		setFieldValue,
		touched,
		values,
	} = useFormik({
		initialValues: {
			licenseNumber: '',
			expiry: null,
		},
		validateOnBlur: true,
		validateOnChange: false,
		validationSchema: LicenseSchema,
		onSubmit: async (data) => {
			setIsLoading(true);
			try {
				const payload = {
					licenseNumber: data.licenseNumber,
					expiry: moment(data.expiry).format('YYYY-MM-DD'),
					image,
				};

				const newLicense = await createLicense(payload);

				setLicenses((licenses) => [...licenses, newLicense.data]);
				dispatch(
					addToast('Success', 'License added', 'success', 'check'),
				);
				onClose();
				resetForm({ values: initialValues });
			} catch (err) {
				const fieldError = getFieldError(err);

				if (fieldError) {
					setFieldError(fieldError.field, fieldError.message);
				} else {
					const apiError = getApiError(err);

					if (apiError && apiError.field) {
						setFieldError(apiError.field, apiError.message);
					} else {
						dispatch(
							addToast(
								'Error',
								apiError.message,
								'danger',
								'help',
							),
						);
					}
				}
			} finally {
				setIsLoading(false);
			}
		},
	});

	const handleClose = () => {
		onClose();
		resetForm({ values: initialValues });
	};

	const setFile = (key, value) => {
		if (value.length > 0) {
			if (key === 'image') setImage(value[0]);
			setFieldValue(key, value[0].name);
		} else {
			if (key === 'image') setImage(null);
			setFieldValue(key, null);
		}
	};

	if (!visible) return null;

	return (
		<EuiFlyout
			closeButtonAriaLabel="Close Add Prescription"
			onClose={handleClose}
			size="s"
		>
			<EuiFlyoutHeader hasBorder>
				<EuiText>
					<h3>Add License</h3>
				</EuiText>
			</EuiFlyoutHeader>
			<EuiFlyoutBody>
				<EuiForm>
					<EuiFlexGroup>
						<EuiFlexItem>
							<EuiSpacer />
							<EuiFormRow
								error={
									touched.licenseNumber &&
									errors.licenseNumber
								}
								isInvalid={
									touched.licenseNumber &&
									!!errors.licenseNumber
								}
								label="License Number"
								type="column"
							>
								<EuiFieldText
									data-testid="licenseNumber-input"
									id="licenseNumber"
									isInvalid={
										touched.licenseNumber &&
										!!errors.licenseNumber
									}
									name="licenseNumber"
									onChange={handleChange}
									value={values.licenseNumber}
								/>
							</EuiFormRow>
							<EuiSpacer size="l" />
							<EuiFormRow
								error={touched.expiry && errors.expiry}
								isInvalid={touched.expiry && !!errors.expiry}
								label="Expiration Date"
								type="column"
							>
								<EuiDatePicker
									id="expiry"
									isInvalid={
										touched.expiry && !!errors.expiry
									}
									name="expiry"
									onChange={(e) => setFieldValue('expiry', e)}
									selected={
										values.expiry
											? moment(values.expiry)
											: null
									}
									yearDropdownItemNumber={50}
								/>
							</EuiFormRow>
							<EuiSpacer size="l" />
							<EuiFormRow
								error={errors.image}
								isInvalid={errors.image || false}
								label="License Photo"
								type="column"
							>
								<EuiFilePicker
									display="default"
									id="image"
									isInvalid={errors.image || false}
									name="image"
									onChange={(e) => setFile('image', e)}
								/>
							</EuiFormRow>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiForm>
			</EuiFlyoutBody>
			<EuiFlyoutFooter>
				<EuiFlexGroup
					direction="row"
					gutterSize="m"
					justifyContent="spaceBetween"
				>
					<EuiFlexItem>
						<EuiButtonEmpty
							color="danger"
							iconType="crossInACircleFilled"
							onClick={handleClose}
						>
							Cancel
						</EuiButtonEmpty>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiButton
							disabled={isLoading}
							fill
							iconType="plusInCircleFilled"
							isLoading={isLoading}
							onClick={handleSubmit}
						>
							Add License
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiFlyoutFooter>
		</EuiFlyout>
	);
};

AddLicense.propTypes = {
	onClose: PropTypes.func.isRequired,
	setLicenses: PropTypes.func.isRequired,
	visible: PropTypes.bool.isRequired,
};

export default AddLicense;
