import React from 'react';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet-async';
import {
	EuiButtonEmpty,
	EuiCallOut,
	EuiText,
	EuiSpacer,
	EuiFlexGroup,
	EuiFlexItem,
} from '@elastic/eui';

import PropTypes from 'prop-types';

import { LOGIN_TITLE } from 'constants/displayText';
import { getDisplayText } from 'utils/helpers';
import LoginForm from './LoginForm';

function Login({ type }) {
	const history = useHistory();

	const navigateToForgot = () => history.push('/forgot-password');
	const navigateToPatientLogin = () => history.push('/login');
	const navigateToRegistration = () => history.push('/doctor-register');

	const urlParams = new URLSearchParams(window.location.search);
	const confirmation = urlParams.get('confirmation');

	const renderLinks = () => {
		if (type === 'doctor') {
			return (
				<>
					<EuiFlexItem>
						<EuiButtonEmpty
							flush="left"
							onClick={navigateToRegistration}
							size="xs"
						>
							Register
						</EuiButtonEmpty>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiButtonEmpty
							flush="left"
							onClick={navigateToPatientLogin}
							size="xs"
						>
							Patient Login
						</EuiButtonEmpty>
					</EuiFlexItem>
				</>
			);
		}

		return <></>;
	};

	return (
		<>
			<Helmet title={getDisplayText(LOGIN_TITLE, type) || 'Login'} />
			<EuiText textAlign="center">
				<h3>{getDisplayText(LOGIN_TITLE, type) || 'Login'}</h3>
			</EuiText>
			<EuiSpacer />
			{confirmation && (
				<>
					<EuiCallOut
						color="success"
						size="s"
						title="Email confirmed you may now login"
					/>
					<EuiSpacer size="s" />
				</>
			)}
			<LoginForm />
			<EuiSpacer />
			<EuiFlexGroup
				direction="row"
				gutterSize="none"
				responsive={false}
				wrap
			>
				<EuiFlexItem>
					<EuiButtonEmpty
						color="primary"
						flush="left"
						onClick={navigateToForgot}
						size="xs"
					>
						Forgot Password?
					</EuiButtonEmpty>
				</EuiFlexItem>
				{renderLinks()}
			</EuiFlexGroup>
		</>
	);
}

Login.propTypes = {
	type: PropTypes.oneOf(['doctor', 'pharma', 'patient']),
};

Login.defaultProps = {
	type: 'doctor',
};

export default Login;
