import { nanoid } from 'nanoid';
import { ADD_TOAST, CLEAR_TOASTS, REMOVE_TOAST } from 'constants/actionTypes';

/**
 *
 * @param {*} title
 * @param {*} text
 * @param {*} color
 * @param {*} iconType
 */
export const addToast = (title, text, color, iconType) => ({
	type: ADD_TOAST,
	toast: {
		id: nanoid(),
		title,
		text,
		color,
		iconType,
	},
});

export const removeToast = (toast) => ({ type: REMOVE_TOAST, toast });

export const clearToast = () => ({ type: CLEAR_TOASTS });
