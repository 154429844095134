import React, { useCallback, useEffect, useState } from 'react';

import { EuiComboBox } from '@elastic/eui';

import PropTypes from 'prop-types';

import { getBarangays } from 'modules/_global/global.fetch';

const BarangayDropdown = ({ city, onChange, value, ...props }) => {
	const [selected, setSelected] = useState([]);
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);

	const processResults = (res) => {
		const processedOptions = res.map((d) => ({
			label: `${d.name}${d.city ? ` - ${d.city.name}` : ''}`,
			value: d.id,
			...d,
		}));

		if (!city) {
			setOptions([]);
		} else {
			setOptions(processedOptions);
		}

		return processedOptions;
	};

	const onSelect = (e) => {
		const [selectedOption] = e;

		if (!selectedOption) {
			if (onChange) onChange(null);

			return setSelected([]);
		}

		if (onChange) onChange(selectedOption);

		return setSelected(e);
	};

	const onSearchChange = useCallback(async (searchValue) => {
		try {
			if (!searchValue.length || searchValue.length < 3 || loading) {
				return;
			}

			const params = {
				_q: searchValue,
			};

			if (city) {
				params.city = city;
			}

			setLoading(true);

			const { data: results } = await getBarangays(params);

			processResults(results);
		} catch (err) {
			setOptions([]);
			setSelected([]);
			onChange(null);
		} finally {
			setLoading(false);
		}
	});

	useEffect(() => {
		const fetchCurrentValue = async () => {
			const { data: result } = await getBarangays({ id: value });

			const processed = processResults(result);

			setSelected(processed);
		};

		if (value) fetchCurrentValue();
	}, [value]);

	const fetchOptions = async () => {
		const params = {};

		if (city) {
			params.city = city;
		}

		const { data: result } = await getBarangays(params);

		processResults(result);
	};

	useEffect(() => {
		if (!options.length) fetchOptions();
	}, []);

	useEffect(() => {
		if (!city) {
			setSelected([]);
			setOptions([]);
			onChange(null);
		} else {
			fetchOptions();
		}
	}, [city]);

	return (
		<EuiComboBox
			async
			isLoading={loading}
			onChange={onSelect}
			onSearchChange={onSearchChange}
			options={options}
			placeholder="Search Barangay"
			selectedOptions={selected}
			singleSelection={{ asPlainText: true }}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		/>
	);
};

BarangayDropdown.propTypes = {
	city: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string,
};

BarangayDropdown.defaultProps = {
	city: null,
	value: null,
};

export default BarangayDropdown;
