import React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText } from '@elastic/eui';
import { get } from 'lodash';
import PropTypes from 'prop-types';

const RxReceived = ({ rx }) => {
	const name = get(rx, 'vendor.name', '');
	const vendor = name ? ` ${name} ` : ' - ';
	const message = `A${vendor}Pharmacist will call you shortly`;

	return (
		<EuiFlexGroup justifyContent="spaceAround">
			<EuiFlexItem
				grow={false}
				style={{
					borderColor: 'gray',
					borderWidth: '2px',
					borderStyle: 'solid',
					padding: '10%',
				}}
			>
				<EuiText
					size="m"
					style={{ fontWeight: 'bolder', fontSize: '24px' }}
					textAlign="center"
				>
					Received Prescription
				</EuiText>
				<EuiSpacer />
				<EuiText textAlign="center">{message}</EuiText>
				{/* <EuiText textAlign="center">Date: 12/20/2021 10:00 PM</EuiText> */}
			</EuiFlexItem>
		</EuiFlexGroup>
	);
};

RxReceived.propTypes = {
	rx: PropTypes.shape({
		vendor: PropTypes.shape({
			name: PropTypes.string,
		}),
	}).isRequired,
};

export default RxReceived;
