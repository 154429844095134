import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from 'modules/App';
import configureStore from 'store/store';

import 'assets/stylesheets/index.scss';
import 'modules/i18n';

const { persistor, store } = configureStore();

const render = (Component) => {
	// eslint-disable-next-line react/no-render-return-value
	return ReactDOM.render(
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<Component />
			</PersistGate>
		</Provider>,
		document.getElementById('root'),
	);
};

render(App);

if (module.hot) {
	module.hot.accept('./modules/App', () => {
		// eslint-disable-next-line global-require
		const NextApp = require('./modules/App').default;
		render(NextApp);
	});
}
