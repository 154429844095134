import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import {
	EuiButton,
	EuiButtonEmpty,
	EuiCallOut,
	EuiForm,
	EuiFormRow,
	EuiFieldText,
	EuiFieldPassword,
	EuiSpacer,
	EuiFlexGroup,
	EuiFlexItem,
	EuiCheckbox,
	EuiText,
} from '@elastic/eui';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { loginAction } from './auth.actions';
import { postPatientLogin } from './auth.fetch';

const PatientLoginForm = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [error, setError] = useState(null);

	const PatientLoginSchema = Yup.object().shape({
		identifier: Yup.string().required('Email or Username is required'),
		password: Yup.string().required('Password is required'),
		remember: Yup.bool(),
	});

	const loginMutation = useMutation(postPatientLogin, {
		onMutate: () => {
			setError(null);
		},
		onSuccess: (data, values) => {
			dispatch(
				loginAction({
					...data,
					rememberMe: values.rememberMe,
				}),
			);

			window.addEventListener('unload', (ev) => {
				ev.preventDefault();
				if (!values.rememberMe) {
					localStorage.clear();
				}
			});
		},
		onError: (err) => {
			if (err?.message === 'Invalid Credentials') {
				setError('Invalid Username or Password');
			} else {
				setError(err?.message || 'Something went wrong');
			}
		},
	});

	const formik = useFormik({
		initialValues: {
			identifier: '',
			password: '',
			rememberMe: false,
		},
		validationSchema: PatientLoginSchema,
		validateOnBlur: true,
		onSubmit: async (values) => {
			loginMutation.mutate({
				...values,
				identifier: values.identifier.toLowerCase(),
			});
		},
	});

	const { errors, handleChange, handleSubmit, values } = formik;

	const navigateToRegistration = () => history.push('/register');
	const navigateToDoctorLogin = () => history.push('/doctor-login');
	const navigateToForgot = () => history.push('/forgot-password');

	return (
		<>
			<EuiText textAlign="center">
				<h3>Login</h3>
			</EuiText>
			<EuiSpacer size="l" />
			{error && (
				<>
					<EuiCallOut color="danger" size="s" title={error} />
					<EuiSpacer size="l" />
				</>
			)}
			<EuiForm>
				<EuiFormRow
					error={errors.identifier}
					fullWidth
					isInvalid={!!errors.identifier}
					label="Email or Username"
				>
					<EuiFieldText
						data-testid="identifier-input"
						fullWidth
						id="identifier"
						isInvalid={!!errors.identifier}
						name="identifier"
						onChange={handleChange}
						value={values.identifier}
					/>
				</EuiFormRow>
				<EuiFormRow
					error={errors.password}
					fullWidth
					isInvalid={!!errors.password}
					label="Password"
				>
					<EuiFieldPassword
						data-testid="password-input"
						fullWidth
						id="password"
						isInvalid={!!errors.password}
						name="password"
						onChange={handleChange}
						type="dual"
						value={values.password}
					/>
				</EuiFormRow>
				<EuiSpacer size="l" />
				<EuiFormRow fullWidth>
					<EuiButton
						color="primary"
						data-testid="login-button"
						fill
						fullWidth
						isLoading={loginMutation.isLoading}
						onClick={handleSubmit}
						type="submit"
					>
						Login
					</EuiButton>
				</EuiFormRow>
				<EuiFormRow>
					<EuiCheckbox
						checked={formik.values.rememberMe}
						id="rememberMe"
						label="Remember Me"
						name="rememberMe"
						onChange={formik.handleChange}
					/>
				</EuiFormRow>
			</EuiForm>
			<EuiSpacer size="l" />
			<EuiFlexGroup
				direction="row"
				gutterSize="m"
				responsive={false}
				wrap
			>
				<EuiFlexItem>
					<EuiButtonEmpty
						color="primary"
						onClick={navigateToForgot}
						size="xs"
					>
						Forgot Password?
					</EuiButtonEmpty>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiButtonEmpty
						color="primary"
						onClick={navigateToDoctorLogin}
						size="xs"
					>
						Physician Login
					</EuiButtonEmpty>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiButtonEmpty
						color="primary"
						onClick={navigateToRegistration}
						size="xs"
					>
						No account yet? Click here to register
					</EuiButtonEmpty>
				</EuiFlexItem>
			</EuiFlexGroup>
		</>
	);
};

export default PatientLoginForm;
