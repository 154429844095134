import React, { useCallback, useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import {
	EuiBadge,
	EuiButton,
	EuiFlexGroup,
	EuiFlexItem,
	EuiSpacer,
} from '@elastic/eui';

const containerStyle = {
	maxWidth: '500px',
	height: '500px',
	borderRadius: '5px',
};

const containerStyleSmall = {
	maxWidth: '400px',
	height: '100px',
	borderRadius: '5px',
};

function Map({ editDisabled, key, mapCenter, markerPosition, onChange }) {
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: key,
	});

	const [map, setMap] = useState(null);
	const [editable, setEditable] = useState(false);

	const onLoad = useCallback(function callback(m) {
		// const bounds = new window.google.maps.LatLngBounds(mapCenter);
		// map.fitBounds(bounds);
		setMap(m);
	}, []);

	const onUnmount = useCallback(function callback() {
		setMap(null);
	}, []);

	const onDragEnd = () => {
		if (map) {
			onChange(map.getCenter().toJSON());
		}
	};

	const toggleEdit = () => {
		setEditable((e) => !e);
	};

	return isLoaded ? (
		<>
			<EuiSpacer />
			<GoogleMap
				center={mapCenter}
				draggable={false}
				mapContainerStyle={
					editable ? containerStyle : containerStyleSmall
				}
				onDrag={onDragEnd}
				onLoad={onLoad}
				onUnmount={onUnmount}
				onZoomChanged={onDragEnd}
				options={{
					gestureHandling: editable ? 'auto' : 'none',
					disableDefaultUI: true,
					clickableIcons: false,
				}}
				zoom={17}
			>
				<Marker
					position={markerPosition}
					title="Drag marker to delivery address"
				/>
			</GoogleMap>
			<EuiSpacer size="s" />
			<EuiFlexGroup gutterSize="xs" responsive={false} wrap>
				<EuiFlexItem grow={false}>
					<EuiButton
						disabled={editDisabled}
						onClick={toggleEdit}
						size="s"
					>
						{editable ? 'Done' : 'Edit Pin'}
					</EuiButton>
				</EuiFlexItem>
				{editable && (
					<EuiFlexItem grow={false}>
						<EuiBadge color="primary">
							Drag the map to move the pin
						</EuiBadge>
					</EuiFlexItem>
				)}
			</EuiFlexGroup>
		</>
	) : (
		<></>
	);
}

Map.propTypes = {
	editDisabled: PropTypes.bool,
	key: PropTypes.string,
	mapCenter: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number,
	}),
	markerPosition: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number,
	}),
	onChange: PropTypes.func.isRequired,
};

Map.defaultProps = {
	editDisabled: false,
	key: process.env.REACT_APP_MAPS,
	mapCenter: {
		lat: 0,
		lng: 0,
	},
	markerPosition: {
		lat: 0,
		lng: 0,
	},
};

export default React.memo(Map);
