import React, { useState } from 'react';
import {
	EuiFlyout,
	EuiFlyoutHeader,
	EuiFlyoutBody,
	EuiFlyoutFooter,
	EuiFlexGroup,
	EuiFlexItem,
	EuiButtonEmpty,
	EuiButton,
	EuiText,
	EuiForm,
	EuiFormRow,
	EuiFieldText,
	EuiSpacer,
	EuiTextArea,
	// EuiFilePicker,
	EuiImage,
} from '@elastic/eui';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

const AddBrandSchema = Yup.object().shape({
	name: Yup.string().required('Brand Name is required'),
});

const AddBrand = ({ addBrand, onClose, setError, visible }) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [files, setFiles] = useState({});

	const {
		errors,
		handleChange,
		handleSubmit,
		initialValues,
		resetForm,
		values,
	} = useFormik({
		initialValues: {
			name: '',
			description: '',
		},
		validateOnBlur: true,
		validationSchema: AddBrandSchema,
		onSubmit: async (data) => {
			try {
				addBrand(data);
			} catch (err) {
				// set error message from api request failure
				setError(err.message || 'Something went wrong');
			} finally {
				setError(null);
				setIsLoading(false);
				resetForm({ values: initialValues });
			}
		},
	});

	const { description, name } = values;

	// eslint-disable-next-line no-unused-vars
	const renderFiles = () => {
		if (files.length > 0) {
			return (
				<ul>
					{Object.keys(files).map((item) => (
						<li key={files[item].name}>
							<strong>{files[item].name}</strong> (
							{files[item].size} bytes)
						</li>
					))}
				</ul>
			);
		}
		return (
			<EuiImage
				allowFullScreen={false}
				alt="Accessible image alt goes here"
				// caption="Medium"
				hasShadow
				size="l"
				url="https://source.unsplash.com/1000x1000/?Nature"
			/>
		);
	};

	if (!visible) return null;

	return (
		<EuiFlyout
			closeButtonAriaLabel="Close Add Brand"
			onClose={() => {
				onClose();
				resetForm({ values: initialValues });
			}}
			size="s"
			// TODO: move to stylesheet
			style={{ zIndex: 0 }}
		>
			<EuiFlyoutHeader hasBorder>
				<EuiText>
					<h3>Add Brand</h3>
				</EuiText>
			</EuiFlyoutHeader>
			<EuiFlyoutBody>
				<EuiForm>
					<EuiFlexItem grow={false}>
						<EuiFlexGroup>
							<EuiFlexItem>
								<EuiText>
									<h3>Brand Information</h3>
								</EuiText>
								<EuiSpacer />
								<EuiFormRow
									error={errors.name}
									isInvalid={!!errors.name}
									label="Brand Name"
								>
									<EuiFieldText
										data-testid="name-input"
										id="name"
										isInvalid={!!errors.name}
										name="name"
										onChange={handleChange}
										placeholder="Brand Name"
										value={name}
									/>
								</EuiFormRow>
								<EuiSpacer size="l" />
								<EuiFormRow
									// error={errors.description}
									// isInvalid={!!errors.description}
									label="Description"
								>
									<EuiTextArea
										data-testid="description-input"
										id="description"
										// isInvalid={!!errors.description}
										name="description"
										onChange={handleChange}
										placeholder="Description"
										value={description}
									/>
								</EuiFormRow>
							</EuiFlexItem>
						</EuiFlexGroup>
					</EuiFlexItem>
					{/* <EuiFlexItem>
								<EuiSpacer size="l" />
								<EuiSpacer size="l" />
								<EuiSpacer size="l" />
								<EuiFlexItem>
									<EuiText>{renderFiles()}</EuiText>
									<EuiFilePicker
										aria-label="Use aria labels when no actual label is in use"
										display="large"
										id="asdf2"
										initialPromptText="Select or drag and drop multiple files"
										multiple
										onChange={setFiles}
									/>
								</EuiFlexItem>
							</EuiFlexItem> */}
				</EuiForm>
			</EuiFlyoutBody>
			<EuiFlyoutFooter>
				<EuiFlexGroup
					direction="row"
					gutterSize="m"
					justifyContent="spaceBetween"
				>
					<EuiFlexItem>
						<EuiButtonEmpty
							color="danger"
							iconType="crossInACircleFilled"
							onClick={() => {
								onClose();
								resetForm({ values: initialValues });
							}}
						>
							{t('general.cancel')}
						</EuiButtonEmpty>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiButton
							disabled={isLoading}
							fill
							iconType="checkInCircleFilled"
							onClick={handleSubmit}
						>
							{t('general.saveChanges')}
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiFlyoutFooter>
		</EuiFlyout>
	);
};

AddBrand.propTypes = {
	addBrand: PropTypes.func.isRequired,
	onClose: PropTypes.bool.isRequired,
	setError: PropTypes.string.isRequired,
	visible: PropTypes.bool.isRequired,
};

export default AddBrand;
