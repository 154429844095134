import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import _ from 'lodash';
import { EuiFlexItem, EuiLink, EuiButton, EuiFlexGroup } from '@elastic/eui';

import Page from 'components/Page';
import Table from 'components/Table';
import { SUPER_ADMIN, ADMIN, PHARMACIST } from 'components/roles';

import { addToast } from '../toasts/toasts.actions';

import AddUser from './AddUser';
import { createUser, getUsers } from './users.fetch';

const Users = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [users, setUsers] = useState([]);
	const [selection, setSelection] = useState([]);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [addUserVisisble, setAddUserVisible] = useState(false);

	useEffect(() => {
		async function fetchUsers() {
			try {
				setIsLoading(true);
				const results = await getUsers();
				const noDoctors = results.data.reduce((acc, account) => {
					if (
						_.get(account, 'role.type', null) === 'doctor' ||
						!Object.keys(account).includes('role')
					)
						return acc;
					return acc.concat(account);
				}, []);
				setUsers(noDoctors);
			} catch (err) {
				setError(err.message || 'Something went wrong');
			} finally {
				setError(null);
				setIsLoading(false);
			}
		}
		fetchUsers();
	}, []);

	useEffect(() => {
		function toastError() {
			dispatch(addToast('Error', error, 'danger', 'help'));
			setTimeout(() => {
				setError(null);
			}, 2000);
		}
		if (error) toastError();
	}, [error]);

	const selectionProps = {
		selectable: () => true,
		onSelectionChange: (select) => {
			setSelection(select);
		},
		selectableMessage: () => undefined,
		initialSelected: selection,
	};

	const addUser = async (data) => {
		try {
			let payload = {
				username: data.username,
				email: data.email,
				password: data.password,
				firstName: data.firstName,
				middleName: data.middleName,
				lastName: data.lastName,
			};

			if (
				data.role === 'superAdmin' ||
				data.role === 'administrator' ||
				data.role === 'callCenter'
			) {
				payload = {
					...payload,
					role: data.role,
				};
			}

			if (data.role === 'doctor' || data.role === 'pharmacist') {
				payload = {
					...payload,
					role: data.role,
					prcLicense: data.prcLicense || data.licenseNumber || null,
					s2License: data.s2License || data.licenseNumber || null,
				};
			}

			const newUser = await createUser(payload);

			setUsers([...users, newUser.data]);
			dispatch(
				addToast(
					'Success',
					'You have successfully added a user.',
					'success',
					'check',
				),
			);
		} catch (err) {
			setError(err.message || 'Something went wrong');
		} finally {
			setError(null);
			setIsLoading(false);
			setAddUserVisible(false);
		}
	};

	const renderToolsLeft = () => {
		if (!selection.length) {
			return null;
		}

		return (
			<EuiButton color="danger" fill={false} iconType="trash">
				Delete
			</EuiButton>
		);
	};
	/**
	 * Omitted Clear Filter because there's no method to remove filte on elastics. You can remmove it nby clicking it again.
	 */

	// const clearFilters = async () => {
	// 	try {
	// 		setIsLoading(true);
	// 	} catch (err) {
	// 		setError(err.message || 'Something went wrong');
	// 	} finally {
	// 		setError(null);
	// 		setIsLoading(false);
	// 	}
	// };

	// const renderToolsRight = () => [
	// 	<EuiFlexItem key="clearFilter" grow={false} justifyContent="center">
	// 		<EuiButton color="disabled" iconType="broom" onClick={clearFilters}>
	// 			Clear Filters
	// 		</EuiButton>
	// 	</EuiFlexItem>,
	// ];

	const search = {
		box: {
			incremental: true,
		},
		toolsLeft: renderToolsLeft(),
		// toolsRight: renderToolsRight(), //
		filters: [
			{
				type: 'field_value_selection',
				field: 'role.name',
				name: 'Role',
				multiSelect: false,
				options: [SUPER_ADMIN, ADMIN, PHARMACIST],
			},
		],
	};

	const action = () => {};

	// eslint-disable-next-line no-unused-vars

	const columns = [
		{
			field: 'username',
			name: 'Username',
			sortable: true,
			render: (username, { id }) => (
				<Link to={`/users/${id}`}>
					<EuiLink>{username}</EuiLink>
				</Link>
			),
		},
		{
			field: 'email',
			name: 'E-mail',
			sortable: true,
		},
		{
			field: 'role.name',
			name: 'Role',
			sortable: true,
		},
		{
			actions: [
				{
					name: 'View',
					description: 'View',
					onClick: async (data) => action(data, 'view'),
					icon: 'eye',
					type: 'icon',
					isPrimary: true,
				},
				{
					name: 'Edit',
					description: 'Edit',
					onClick: (data) => action(data, 'edit'),
					icon: 'pencil',
					type: 'icon',
					color: 'primary',
					isPrimary: true,
				},
				// {
				// 	name: 'Block/Unblock',
				// 	description: 'Block',
				// 	onClick: (data) => handleBlockUnblockAction(data, 'block'),
				// 	icon: 'minusInCircle',
				// 	type: 'icon',
				// 	color: 'danger',
				// 	isPrimary: false,
				// },
			],
		},
	];

	return (
		<>
			<Helmet title={t('users.title')} />
			<Page
				headerRight={
					<EuiFlexGroup direction="row" gutterSize="m">
						<EuiFlexItem>
							<EuiButton
								color="primary"
								fill
								iconType="plusInCircleFilled"
								onClick={() => setAddUserVisible(true)}
							>
								Add User
							</EuiButton>
						</EuiFlexItem>
					</EuiFlexGroup>
				}
				title={t('users.title')}
			>
				<Table
					columns={columns}
					isSelectable
					itemId="userId"
					items={users}
					search={search}
					selection={selectionProps}
				/>

				<AddUser
					addUser={addUser}
					isLoading={isLoading}
					onClose={() => setAddUserVisible(false)}
					setError={setError}
					visible={addUserVisisble}
				/>
			</Page>
		</>
	);
};

export default Users;
