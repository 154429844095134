import React, {
	useState,
	useCallback,
	useImperativeHandle,
	forwardRef,
} from 'react';

import {
	EuiComboBox,
	EuiFlexGroup,
	EuiFlexItem,
	EuiHealth,
	EuiText,
} from '@elastic/eui';
import _ from 'lodash';

import PropTypes from 'prop-types';

import { searchMedicines } from 'modules/products/search.fetch';

const ProductSearchComp = ({ filter, onSelect, ...props }, ref) => {
	const [options, setOptions] = useState([]);
	const [selected, setSelected] = useState([]);
	const [loading, setLoading] = useState(false);

	useImperativeHandle(ref, () => ({
		clear: () => {
			setOptions([]);
			setSelected([]);
		},
		setValue: (value) => {
			setOptions([value]);
			setSelected([value]);
		},
	}));

	const getOptions = async (value) => {
		if (value.length < 3) {
			return;
		}

		setLoading(true);
		setOptions([]);

		const query = { _q: value, _sort: 'name:ASC' };

		if (filter) {
			query.rx = filter === 'rx';
		}

		const { data } = await searchMedicines(query);

		if (data) {
			setOptions(
				data.map((d) => ({
					...d,
					label: d.name,
				})),
			);
			setLoading(false);
		}
	};

	const debouncedSearch = useCallback(_.debounce((v) => getOptions(v), 500));

	const onSearchChange = (value) => debouncedSearch(value);

	const renderOption = (option) => {
		return (
			<>
				<EuiFlexGroup
					gutterSize="none"
					style={{ alignItems: 'center' }}
				>
					<EuiFlexItem grow={false}>
						<EuiHealth color={option.rx ? 'success' : 'warning'} />
					</EuiFlexItem>
					<EuiFlexItem>
						<strong> {option.label}</strong>
					</EuiFlexItem>
				</EuiFlexGroup>
				<EuiText color="subdued" size="s">
					<p className="euiTextColor--subdued">
						{`(${option.rx ? 'RX' : 'OTC'}) ${option.generic}`}
						{option.strength ? ` (${option.strength})` : ''}
					</p>
				</EuiText>
			</>
		);
	};

	return (
		<EuiComboBox
			async
			className="product-search"
			isLoading={loading}
			onChange={(e) => {
				const [selectedOption] = e;
				if (!selectedOption) {
					return setSelected([]);
				}

				if (onSelect) {
					onSelect(selectedOption);
				}

				return setSelected(e);
			}}
			onSearchChange={onSearchChange}
			options={options}
			placeholder="Search Product"
			renderOption={renderOption}
			rowHeight={50}
			selectedOptions={selected}
			singleSelection={{ asPlainText: true }}
			// eslint-disable-next-line
			{...props}
		/>
	);
};

ProductSearchComp.propTypes = {
	filter: PropTypes.oneOf(['rx', 'non-rx']),
	onSelect: PropTypes.func.isRequired,
};

ProductSearchComp.defaultProps = {
	filter: null,
};

const ProductSearch = forwardRef(ProductSearchComp);

export default ProductSearch;
