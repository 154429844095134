import FormData from 'form-data';
import api from '../../utils/api';

export const updateDoctor = async (id, payload) => {
	const { signature, ...data } = payload;
	const formData = new FormData();

	if (!signature) {
		return api.put(`/doctors/${id}`, data);
	}

	formData.append('data', JSON.stringify(data));
	formData.append('files[signature]', signature);

	return api.put(`/doctors/${id}`, formData);
};

export const getDoctorProfile = async () => {
	const { data } = await api.get('/doctors/me');

	return data;
};

export const getClinic = async (id) => {
	return api.get(`/clinics/${id}`);
};

export const updateClinic = async (id, data) => {
	return api.put(`/clinics/${id}`, data);
};

export const createClinic = async (data) => {
	return api.post(`/clinics`, data);
};

export const getSpecializations = async () => {
	return api.get('/specializations').then(({ data }) => data);
};
