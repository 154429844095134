import React from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
	EuiPage,
	EuiPageBody,
	EuiPageSection,
	EuiPanel,
	EuiFlexGroup,
	EuiFlexItem,
	EuiImage,
	EuiText,
	EuiSpacer,
} from '@elastic/eui';
import { getOrder } from 'modules/orders/orders.fetch';
import { addToast } from 'modules/toasts/toasts.actions';

export default function ViewOrder() {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { orderId } = useParams();

	const { data: order } = useQuery([`order-${orderId}`, orderId], getOrder, {
		placeholderData: {},
		onError: (err) => {
			dispatch(
				addToast(
					'Error',
					err.message || 'Something went wrong',
					'danger',
					'help',
				),
			);
		},
	});

	return (
		<>
			<Helmet title={t('users.title')} />
			<EuiPage data-testid="auth-layout-container">
				<EuiPageBody
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: '0',
					}}
				>
					<EuiPageSection>
						<EuiPanel
							style={{
								width: 'fit-content',
								maxWidth: '100%',
								margin: '0 auto',
							}}
						>
							<EuiSpacer />
							<EuiFlexGroup
								alignItems="center"
								direction="column"
								justifyContent="center"
							>
								<EuiFlexItem>
									<EuiImage
										alt={order && order.branch?.name}
										caption={order && order.branch?.name}
										hasShadow
										style={{
											display: 'block',
											margin: '0 auto',
										}}
										url={
											order &&
											order.qrCode?.length &&
											order.qrCode[0]?.url
										}
									/>
								</EuiFlexItem>
								<EuiFlexItem>
									<EuiText>
										<b>
											{order && order.patient?.firstName}{' '}
											{order && order.patient?.lastName}
										</b>
									</EuiText>{' '}
								</EuiFlexItem>
								<EuiFlexItem>Code</EuiFlexItem>
								<EuiFlexItem
									style={{
										backgroundColor: 'red',
										color: '#fff',
										padding: '20px 100px',
										textAlign: 'center',
									}}
								>
									{order && order?.orderId}
								</EuiFlexItem>
								<EuiFlexItem>Date Created</EuiFlexItem>
								<EuiFlexItem
									style={{
										border: '1px solid red',
										color: 'red',
										padding: '20px 100px',
										textAlign: 'center',
									}}
								>
									{order &&
										moment(order.createdAt).format(
											'MM-DD-YYYY',
										)}
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiPanel>
					</EuiPageSection>
				</EuiPageBody>
			</EuiPage>
		</>
	);
}

ViewOrder.propTypes = {};

ViewOrder.defaultProps = {};
