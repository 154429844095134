import React from 'react';
import {
	EuiModal,
	EuiOverlayMask,
	EuiModalHeader,
	EuiModalHeaderTitle,
	EuiModalBody,
	EuiModalFooter,
	EuiButton,
} from '@elastic/eui';

import PropTypes from 'prop-types';

const GrabModal = ({ id, isVisible, message, onClose, title }) => {
	if (!isVisible) {
		return null;
	}

	return (
		<EuiOverlayMask key={id}>
			{/* <EuiConfirmModal
				cancelButtonText="Cancel"
				confirmButtonText="Confirm"
				onCancel={onClose}
				onConfirm={onConfirm}
				title={title}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			>
				{message}
			</EuiConfirmModal> */}
			<EuiModal onClose={onClose}>
				<EuiModalHeader>
					<EuiModalHeaderTitle>
						<h1>{title}</h1>
					</EuiModalHeaderTitle>
				</EuiModalHeader>
				<EuiModalBody>{message}</EuiModalBody>
				<EuiModalFooter>
					<EuiButton fill onClick={onClose}>
						Close
					</EuiButton>
				</EuiModalFooter>
			</EuiModal>
		</EuiOverlayMask>
	);
};

GrabModal.propTypes = {
	id: PropTypes.string,
	isVisible: PropTypes.bool,
	message: PropTypes.string,
	onClose: PropTypes.func.isRequired,
	title: PropTypes.string,
};

GrabModal.defaultProps = {
	id: null,
	isVisible: true,
	message: null,
	title: null,
};

export default GrabModal;
