import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { EuiFlexItem, EuiLink, EuiButton, EuiFlexGroup } from '@elastic/eui';
import Page from 'components/Page';
import Table from 'components/Table';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import AddProduct from './AddProduct';
import { addToast } from '../toasts/toasts.actions';
// eslint-disable-next-line no-unused-vars
import { createProduct, getProducts } from './products.fetch';

const Products = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [products, setProducts] = useState([]);
	const [selection, setSelection] = useState([]);
	const [error, setError] = useState(null);
	// eslint-disable-next-line no-unused-vars
	const [isLoading, setIsLoading] = useState(false);
	const [addProductVisible, setAddProductVisible] = useState(false);

	useEffect(() => {
		async function fetchProducts() {
			try {
				setIsLoading(false);
				const results = await getProducts();
				setProducts(results.data);
			} catch (err) {
				setError(err.message || 'Something went wrong');
			} finally {
				setError(null);
				setIsLoading(false);
			}
		}
		fetchProducts();
	}, []);

	useEffect(() => {
		function toastError() {
			dispatch(addToast('Error', error, 'danger', 'help'));
			setTimeout(() => {
				setError(null);
			}, 2000);
		}
		if (error) toastError();
	}, [error]);

	const selectionProps = {
		selectable: () => true,
		onSelectionChange: (select) => {
			setSelection(select);
		},
		selectableMessage: () => undefined,
		initialSelected: selection,
	};

	const addProduct = async (data) => {
		try {
			const payload = {
				productId: data.productId,
				name: data.name,
				sku: data.sku,
				price: data.price,
				description: data.description,
			};

			const result = await createProduct(payload);
			const updatedItems = [...products, result.data];

			setProducts(updatedItems);
			dispatch(addToast('Success', 'Product added', 'success', 'check'));
		} catch (err) {
			setError(err.message || 'Something went wrong');
		} finally {
			setError(null);
			setIsLoading(false);
			setAddProductVisible(false);
		}
	};

	const clearFilters = async () => {
		try {
			setIsLoading(true);
			const data = await getProducts();
			// setProducts(results.data);
			setProducts(data);
		} catch (err) {
			setError(err.message || 'Something went wrong');
		} finally {
			setError(null);
			setIsLoading(false);
		}
	};

	const renderToolsLeft = () => {
		if (!selection.length) {
			return null;
		}

		return (
			<EuiButton color="danger" fill={false} iconType="trash">
				Delete
			</EuiButton>
		);
	};

	const renderToolsRight = () => [
		<EuiFlexItem key="clearFilter" grow={false} justifyContent="center">
			<EuiButton color="disabled" iconType="broom" onClick={clearFilters}>
				Clear Filters
			</EuiButton>
		</EuiFlexItem>,
	];

	const search = {
		box: {
			incremental: true,
		},
		toolsLeft: renderToolsLeft(),
		toolsRight: renderToolsRight(),
		filters: [
			{
				type: 'field_value_selection',
				field: 'status',
				name: 'Status',
				multiSelect: false,
				options: [],
			},
		],
	};

	const action = () => {};

	const columns = [
		{
			field: 'name',
			name: 'Product Name',
			sortable: true,
			render: (name, { id }) => (
				<Link to={`/products/${id}`}>
					<EuiLink>{name}</EuiLink>
				</Link>
			),
			width: '25%',
		},
		{
			field: 'sku',
			name: 'SKU',
			sortable: true,
			width: '10%',
		},
		{
			field: 'qtyInStock',
			name: 'Quantity',
			dataType: 'number',
			sortable: true,
			width: '10%',
			render: (qtyInStock) => qtyInStock,
		},
		// {
		// 	field: 'status',
		// 	name: 'Product Status',
		// 	sortable: true,
		// 	// render: (status) => <OrderStatus status={status} />,
		// },
		{
			field: 'price',
			name: 'Price',
			dataType: 'number',
			sortable: true,
			render: (price) => `P ${numeral(price).format('0,0.00')}`,
		},
		{
			actions: [
				{
					name: 'View',
					description: 'View',
					onClick: async (data) => action(data, 'view'),
					icon: 'eye',
					type: 'icon',
					isPrimary: true,
				},
				{
					name: 'Edit',
					description: 'Edit',
					onClick: (data) => action(data, 'edit'),
					icon: 'pencil',
					type: 'icon',
					color: 'primary',
					isPrimary: true,
				},
				{
					name: 'Delete',
					description: 'Delete',
					onClick: (data) => action(data, 'delete'),
					icon: 'trash',
					type: 'icon',
					color: 'blue',
					isPrimary: true,
				},
			],
		},
	];

	return (
		<>
			<Helmet title={t('products.title')} />
			<Page
				headerRight={
					<EuiFlexGroup direction="row" gutterSize="m">
						<EuiFlexItem>
							<EuiButton
								color="primary"
								fill
								iconType="plusInCircleFilled"
								onClick={() => setAddProductVisible(true)}
							>
								Add Product
							</EuiButton>
						</EuiFlexItem>
					</EuiFlexGroup>
				}
				title={t('products.title')}
			>
				<Table
					columns={columns}
					isSelectable
					itemId="orderId"
					items={products}
					search={search}
					selection={selectionProps}
				/>

				<AddProduct
					addProduct={addProduct}
					onClose={() => setAddProductVisible(false)}
					setError={setError}
					visible={addProductVisible}
				/>
			</Page>
		</>
	);
};

export default Products;
