import React from 'react';
import { EuiLink, EuiLoadingSpinner, EuiText } from '@elastic/eui';
import PropTypes from 'prop-types';

const LoadMore = ({ fetchNextPage, hasNextPage, isFetchingNextPage, size }) => {
	if (!hasNextPage) {
		return null;
	}

	if (isFetchingNextPage) {
		return <EuiLoadingSpinner size={size} />;
	}

	return (
		<EuiLink onClick={fetchNextPage}>
			<EuiText size={size}>Load More</EuiText>
		</EuiLink>
	);
};

LoadMore.propTypes = {
	fetchNextPage: PropTypes.func.isRequired,
	hasNextPage: PropTypes.bool,
	isFetchingNextPage: PropTypes.bool.isRequired,
	size: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
};

LoadMore.defaultProps = {
	hasNextPage: false,
	size: 'm',
};

export default LoadMore;
