import React from 'react';
import {
	EuiPage,
	EuiPageHeader,
	EuiPageHeaderSection,
	EuiPageBody,
	EuiTitle,
	EuiPageSection,
	EuiFlexGroup,
	EuiFlexItem,
	EuiShowFor,
	EuiPanel,
} from '@elastic/eui';

import PropTypes from 'prop-types';
import HeaderBackButton from './HeaderBackButton';

const Page = ({
	children,
	contentTitle,
	headerRight,
	sidebar,
	stickyHeader,
	title,
	titleSize,
}) => (
	<EuiPage
		className={stickyHeader ? 'hasSticky' : null}
		style={{
			display: 'flex',
			flexDirection: 'column',
		}}
	>
		<EuiPageHeader className={stickyHeader ? 'sticky' : ''}>
			{title && (
				<EuiPageHeaderSection
					style={{
						alignItems: 'center',
						display: 'flex',
					}}
				>
					<HeaderBackButton />
					<EuiShowFor sizes={['xl', 'l', 'm']}>
						<EuiTitle size={titleSize}>
							<h1>{title}</h1>
						</EuiTitle>
					</EuiShowFor>
					<EuiShowFor sizes={['xs', 's']}>
						<EuiTitle size="s">
							<h1>{title}</h1>
						</EuiTitle>
					</EuiShowFor>
				</EuiPageHeaderSection>
			)}
			{headerRight && (
				<EuiPageHeaderSection>{headerRight}</EuiPageHeaderSection>
			)}
		</EuiPageHeader>
		<EuiPageBody>
			<EuiFlexGroup>
				{sidebar && (
					<EuiFlexItem className="sideBar" grow={false}>
						<EuiShowFor sizes={['xl', 'l', 'm']}>
							<EuiPanel>
								<EuiPageSection>{sidebar}</EuiPageSection>
							</EuiPanel>
						</EuiShowFor>
						<EuiShowFor sizes={['s', 'xs']}>
							<EuiPanel panelPaddingSize="s">
								<EuiPageSection>{sidebar}</EuiPageSection>
							</EuiPanel>
						</EuiShowFor>
					</EuiFlexItem>
				)}
				<EuiFlexItem>
					<EuiPanel>
						{contentTitle && (
							<EuiPageHeader>
								<EuiPageHeaderSection>
									<EuiTitle>
										<h2>{contentTitle}</h2>
									</EuiTitle>
								</EuiPageHeaderSection>
							</EuiPageHeader>
						)}
						<EuiPageSection>{children}</EuiPageSection>
					</EuiPanel>
				</EuiFlexItem>
			</EuiFlexGroup>
		</EuiPageBody>
	</EuiPage>
);

Page.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
	contentTitle: PropTypes.string,
	headerRight: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
	sidebar: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
	stickyHeader: PropTypes.bool,
	title: PropTypes.string,
	titleSize: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
};

Page.defaultProps = {
	children: null,
	contentTitle: null,
	headerRight: null,
	sidebar: null,
	stickyHeader: false,
	title: null,
	titleSize: 'm',
};

export default Page;
