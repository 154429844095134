/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, useMutation, useInfiniteQuery } from 'react-query';

import { EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui';

import PropTypes from 'prop-types';
import { get } from 'lodash';

import { ROLE_PATH } from 'constants/protect';
import { getNextPageParams } from 'utils/helpers';
import ConfirmationModal from 'components/ConfirmationModal';
import LoadMore from 'components/LoadMore';
import Comment from './components/Comment';
import { addToast } from '../toasts/toasts.actions';

import {
	getComments,
	addComment,
	deleteComment,
	updateComment,
	countComments,
} from './comments.fetch';

const ChatBox = ({ params }) => {
	const {
		// auth,
		details,
		role,
		user,
	} = useSelector((state) => ({
		auth: state.auth,
		role: get(state, ROLE_PATH),
		user: get(state, 'auth.user'),
		details: get(state, 'auth.user.details'),
	}));
	const dispatch = useDispatch();

	const [selectedComment, setSelectedComment] = useState(null);
	const [hasChanges, setHasChanges] = useState(false);
	const [isConfirmDeleteModalVisisble, setIsConfirmDeleteModalVisible] =
		useState(false);

	const handleSubmit = (data) => {
		if (data.id) {
			return updateComment(data);
		}
		return addComment(data);
	};

	const handleDelete = (id) => deleteComment(id);

	const handleError = (err) => {
		dispatch(
			addToast(
				'Error',
				err.message || 'Something went wrong',
				'danger',
				'help',
			),
		);
	};

	const {
		data: count,
		isFetching: isCounting,
		isSuccess: isCountSuccess,
		refetch: recount,
	} = useQuery(['comments-count', params], countComments, {
		placeholderData: 0,
	});

	const {
		data: { pages } = [],
		fetchNextPage,
		hasNextPage,
		isFetching,
		isFetchingNextPage,
		refetch,
	} = useInfiniteQuery(['comments', params], getComments, {
		placeholderData: [],
		enabled: !isCounting && !hasChanges,
		getNextPageParam: getNextPageParams(count),
		keepPreviousData: true,
	});

	const comments = pages && pages.length ? pages.flat() : [];

	const { isLoading, isSuccess, mutate } = useMutation(handleSubmit, {
		onSuccess: recount,
		onError: handleError,
	});

	const { mutate: mutateDelete } = useMutation(handleDelete, {
		onSuccess: recount,
		onError: handleError,
	});

	const isProcessing = isLoading || isCounting || isFetching;

	useEffect(() => {
		if (count > 0) {
			refetch();
		}
	}, [isCountSuccess]);

	const toggleDeleteConfirmationModal = (data) => {
		setSelectedComment(data);
		setIsConfirmDeleteModalVisible(true);
	};

	const handleCloseDeleteCommentConfirmation = () => {
		setIsConfirmDeleteModalVisible(false);
		setSelectedComment(null);
	};

	const handleDeleteComment = () => {
		mutateDelete(get(selectedComment, 'id'));
		setIsConfirmDeleteModalVisible(false);
		setSelectedComment(null);
	};

	const createDisplayName = (data) => {
		if (get(data, 'firstName') || get(data, 'lastName', '')) {
			return `
			${get(data, 'firstName', '')} 
			${get(data, 'middleName', '')} 
			${get(data, 'lastName', '')}`;
		}
		return get(data, 'username');
	};

	const renderComments = () =>
		comments
			.map((comment) => {
				const pharmacist = get(comment, 'pharmacist');
				const callCenter = get(comment, 'callCenter');

				return {
					...comment,
					value: get(comment, 'comment'),
					attachments: comment.attachments,
					username: createDisplayName(
						pharmacist || callCenter || get(comment, 'user'),
					),
					branchName: callCenter
						? 'Call Center'
						: get(comment, 'branch.name', ''),
					timestamp: get(comment, 'updatedAt'),
					hasActions: get(comment, 'user.id') === get(user, 'id'),
					role: pharmacist ? '(Pharmacist)' : '',
				};
			})
			.map((comment) => (
				<Comment
					key={comment.id}
					{...comment}
					entities={params}
					hasChanges={hasChanges}
					isLoading={isProcessing}
					isSuccess={isSuccess}
					onDelete={() => toggleDeleteConfirmationModal(comment)}
					onSubmit={mutate}
					readOnly
					setHasChanges={setHasChanges}
				/>
			));

	return (
		<div
			style={{
				// maxHeight: 700,
				// overflowY: 'auto',
				// overflowX: 'hidden',
				padding: 10,
			}}
		>
			<EuiSpacer />
			<EuiFlexGroup direction="column" gutterSize="xs" responsive>
				<EuiFlexItem>
					<Comment
						entities={params}
						isLoading={isProcessing}
						isSuccess={isSuccess}
						onSubmit={mutate}
						role={role}
						userDetailId={get(details, 'id')}
						username={createDisplayName(details || user)}
					/>
				</EuiFlexItem>
				{comments.length > 0 && (
					<EuiFlexItem>{renderComments()}</EuiFlexItem>
				)}
				<EuiFlexItem>
					<div style={{ padding: 5, textAlign: 'center' }}>
						<LoadMore
							fetchNextPage={fetchNextPage}
							hasNextPage={!isProcessing && hasNextPage}
							isFetchingNextPage={isFetchingNextPage}
							size="s"
						/>
					</div>
				</EuiFlexItem>
			</EuiFlexGroup>

			<ConfirmationModal
				isVisible={isConfirmDeleteModalVisisble}
				message="Are you sure you want to do this?"
				onClose={handleCloseDeleteCommentConfirmation}
				onConfirm={handleDeleteComment}
				title="Delete Comment"
			/>
		</div>
	);
};

ChatBox.propTypes = {
	countComments: PropTypes.func,
	getComments: PropTypes.func,
	onDelete: PropTypes.func,
	onError: PropTypes.func,
	onSubmit: PropTypes.func,
	params: PropTypes.shape({}),
};

ChatBox.defaultProps = {
	countComments: () => {},
	getComments: () => {},
	onDelete: () => {},
	onError: () => {},
	onSubmit: () => {},
	params: {},
};

export default ChatBox;
