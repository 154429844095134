import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Page from 'components/Page';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
	EuiTitle,
	EuiSpacer,
	EuiFlexGroup,
	EuiButton,
	EuiFlexItem,
	EuiButtonEmpty,
	EuiForm,
	EuiFormRow,
	EuiHorizontalRule,
	EuiTextArea,
	EuiFieldNumber,
	EuiImage,
	EuiFieldText,
} from '@elastic/eui';
import _ from 'lodash';
import NoProductPhoto from 'assets/images/no-product-photo.png';
import ConfirmationModal from 'components/ConfirmationModal';
import { addToast } from '../toasts/toasts.actions';
import { getProduct, updateProduct } from './products.fetch';

const ProductSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, 'Product Name must be at least 2 characters')
		.required('Product Name is required'),
	price: Yup.number()
		.default(0)
		.min(0, 'Price must be greater than or equal to 0'),
});

const Product = () => {
	const { t } = useTranslation();
	const { productId } = useParams();
	const dispatch = useDispatch();
	// eslint-disable-next-line no-unused-vars
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [product, setProduct] = useState({});
	const [hasChanges, setHasChanges] = useState(false);
	const [
		isConfirmCancelChangesModalVisible,
		setIsConfirmCancelChangesModalVisible,
	] = useState(false);

	const {
		errors,
		handleChange,
		handleSubmit,
		initialValues,
		resetForm,
		touched,
		values,
	} = useFormik({
		initialValues: {
			id: productId,
			name: product.name,
			generic: product.generic,
			price: product.price || 0,
			qtyInStock: product.qtyInStock,
			brand: product.brand || {
				id: '',
				name: '',
			},
			productCategory: product.productCategory || {
				id: '',
				name: '',
			},
			formulation: product.formulation,
			description: product.description,
			drNumber: product.drNumber,
			sku: product.sku,
			attachments: product.attachments || {
				url: NoProductPhoto,
			},
		},
		validationSchema: ProductSchema,
		validateOnBlur: true,
		enableReinitialize: true,
		onSubmit: async (data) => {
			setIsLoading(true);
			const payload = {
				name: data.name,
				generic: data.generic,
				// brand: data.brand.id,
				// category: data.productCategory.id,
				price: data.price,
				qtyInStock: data.qtyInStock,
				description: data.description,
				sku: data.sku,
				formulation: data.formulation,
				drNumber: data.drNumber,
			};

			try {
				const result = await updateProduct(productId, payload);
				setProduct(result.data);
				dispatch(
					addToast(
						'Product updated',
						'Changes Saved',
						'success',
						'check',
					),
				);
			} catch (err) {
				setError(err.message || 'Something went wrong');
			} finally {
				setError(null);
				setIsLoading(false);
			}
		},
	});

	useEffect(() => {
		setHasChanges(_.isEqual(initialValues, values));
	}, [values]);

	const {
		attachments,
		description,
		drNumber,
		formulation,
		generic,
		name,
		price,
		qtyInStock,
		sku,
	} = values;

	useEffect(() => {
		async function fetchProduct() {
			try {
				const result = await getProduct(productId);
				setProduct(result.data);
			} catch (err) {
				setError(err.message || 'Something went wrong');
			} finally {
				setError(null);
				setIsLoading(false);
			}
		}
		fetchProduct();
	}, [productId]);

	useEffect(() => {
		function toastError() {
			dispatch(addToast('Error', error, 'danger', 'help'));
		}
		if (error) toastError();
	}, [error]);

	// eslint-disable-next-line no-unused-vars
	const handleDelete = (data) => {};

	const handleCancel = () => setIsConfirmCancelChangesModalVisible(true);

	const confirmCancelChangesModal = isConfirmCancelChangesModalVisible ? (
		<ConfirmationModal
			message="Cancel Changes?"
			onClose={() => {
				setIsConfirmCancelChangesModalVisible(false);
			}}
			onConfirm={() => {
				resetForm({ values: initialValues });
				setIsConfirmCancelChangesModalVisible(false);
			}}
			title="Confirm"
		/>
	) : null;

	// eslint-disable-next-line no-unused-vars
	const disableButton = Object.keys(errors).length > 0;

	return (
		<Page
			headerRight={
				<EuiFlexGroup direction="row" gutterSize="m">
					<EuiFlexItem>
						<EuiButtonEmpty
							color="danger"
							disabled={isLoading || hasChanges}
							iconType="crossInACircleFilled"
							onClick={handleCancel}
						>
							{t('general.cancel')}
						</EuiButtonEmpty>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiButton
							disabled={isLoading || hasChanges}
							fill
							iconType="checkInCircleFilled"
							isLoading={isLoading}
							onClick={handleSubmit}
						>
							{t('general.saveChanges')}
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			}
			stickyHeader
			title={t('products.productTitle', { id: product.Id })}
		>
			<EuiTitle size="xs">
				<h3>{name}</h3>
			</EuiTitle>
			<EuiHorizontalRule margin="xs" />
			<EuiSpacer size="m" />
			<EuiFlexGroup direction="row" justifyContent="spaceBetween">
				<EuiFlexItem style={{ maxWidth: 1600 }}>
					<EuiForm>
						<EuiFlexGroup>
							<EuiFlexItem>
								<EuiImage
									allowFullScreen={false}
									alt="Accessible image alt goes here"
									// caption="Large"
									hasShadow
									size="l"
									url={attachments.url}
								/>
							</EuiFlexItem>
							<EuiFlexItem>
								<EuiFormRow
									error={touched.name && errors.name}
									isInvalid={touched.name && !!errors.name}
									label={t('products.name')}
								>
									<EuiFieldText
										data-testid="name-input"
										id="name"
										isInvalid={
											touched.name && !!errors.name
										}
										name="name"
										onChange={handleChange}
										value={name}
									/>
								</EuiFormRow>
								<EuiFormRow
									error={touched.generic && errors.generic}
									isInvalid={
										touched.generic && !!errors.generic
									}
									label={t('products.generic')}
								>
									<EuiFieldText
										data-testid="generic-input"
										id="generic"
										isInvalid={
											touched.generic && !!errors.generic
										}
										name="generic"
										onChange={handleChange}
										value={generic}
									/>
								</EuiFormRow>
								{/* need to be integrated with brands dropdown */}
								{/* <EuiFormRow label={t('products.brand')}>
									<EuiSuperSelect
										id="brand.name"
										name="brand"
										onChange={(value) =>
											setFieldValue('brand', value)
										}
										options={brandOpts}
										valueOfSelected={brand.name}
									/>
								</EuiFormRow> */}
								<EuiFormRow label={t('products.description')}>
									<EuiTextArea
										data-testid="description-input"
										id="description"
										name="description"
										onChange={handleChange}
										value={description}
									/>
								</EuiFormRow>
							</EuiFlexItem>
							<EuiFlexItem>
								<EuiFormRow
									error={
										touched.formulation &&
										errors.formulation
									}
									isInvalid={
										touched.formulation &&
										!!errors.formulation
									}
									label={t('products.formulation')}
								>
									<EuiFieldText
										data-testid="formulation-input"
										id="formulation"
										isInvalid={
											touched.formulation &&
											!!errors.formulation
										}
										name="formulation"
										onChange={handleChange}
										value={formulation}
									/>
								</EuiFormRow>
								<EuiFormRow
									error={touched.sku && errors.sku}
									isInvalid={touched.sku && !!errors.sku}
									label={t('products.sku')}
								>
									<EuiFieldText
										data-testid="sku-input"
										id="sku"
										isInvalid={touched.sku && !!errors.sku}
										name="sku"
										onChange={handleChange}
										value={sku}
									/>
								</EuiFormRow>
								<EuiFormRow
									error={touched.drNumber && errors.drNumber}
									isInvalid={
										touched.drNumber && !!errors.drNumber
									}
									label={t('products.drNumber')}
								>
									<EuiFieldText
										data-testid="drNumber-input"
										id="drNumber"
										isInvalid={
											touched.drNumber &&
											!!errors.drNumber
										}
										name="drNumber"
										onChange={handleChange}
										value={drNumber}
									/>
								</EuiFormRow>
							</EuiFlexItem>
							<EuiFlexItem>
								<EuiFormRow
									error={touched.price && errors.price}
									isInvalid={touched.price && !!errors.price}
									label={t('products.price')}
								>
									<EuiFieldNumber
										id="price"
										isInvalid={!!errors.price}
										min={0}
										name="price"
										onChange={handleChange}
										value={price}
									/>
								</EuiFormRow>
								<EuiFormRow label={t('products.qtyInStock')}>
									<EuiFieldNumber
										id="qtyInStock"
										isInvalid={!!errors.qtyInStock}
										min={0}
										name="qtyInStock"
										onChange={handleChange}
										value={qtyInStock}
									/>
								</EuiFormRow>
							</EuiFlexItem>
						</EuiFlexGroup>
					</EuiForm>
				</EuiFlexItem>
			</EuiFlexGroup>
			<EuiSpacer size="m" />
			<EuiHorizontalRule margin="xs" />
			<EuiSpacer size="m" />
			{confirmCancelChangesModal}
		</Page>
	);
};

export default Product;
