import { thenCallbackRQ } from 'utils/helpers';
import api from '../../utils/api';

export const createBranch = async (payload) => api.post('/branches', payload);

export const getBranches = async () => api.get('/branches');

/**
 * Get Branches by Vendor
 * @param {String} vendor Vendor ID
 * @param {Boolean} pickup set to true if pickup only
 */
export const getBranchesByVendor = async (vendor, pickup) => {
	const params = {
		vendor,
	};

	if (pickup) {
		params.pickup = true;
	}

	return api.get(`/branches`, { params });
};

export const getBranchesByVendorCity = async (id, cityId, pickup = false) => {
	const params = {};
	if (cityId) {
		params.cityId = cityId;
	}

	if (pickup) {
		params.pickup = true;
	}

	return api.get(`/vendors/${id}/branches`, { params });
};

export const getBranchesByVendorNearest = async (
	id,
	address,
	pickup = false,
) => {
	const params = {};
	if (address) {
		params.address = address;
	}

	if (pickup) {
		params.pickup = true;
	}

	return api.get(`/vendors/${id}/branches/near`, { params });
};

export const getBranch = async (id) => api.get(`/branches/${id}`);
export const getBranchInfo = async (id) => {
	return api.get(`/branches/${id}/info`).then(thenCallbackRQ);
};

export const updateBranch = async (id, payload) =>
	api.put(`/branches/${id}`, payload);
