import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import {
	EuiButton,
	EuiModal,
	EuiModalBody,
	EuiModalFooter,
	EuiModalHeader,
	EuiModalHeaderTitle,
	EuiOverlayMask,
	EuiForm,
	EuiFormRow,
	EuiTextArea,
	EuiToolTip,
	EuiSelect,
	EuiDescriptionList,
	EuiLink,
	EuiHorizontalRule,
	EuiSpacer,
	EuiHeaderSectionItemButton,
	EuiIcon,
} from '@elastic/eui';
import * as Yup from 'yup';

import { postContactUs } from 'modules/_global/global.fetch';

import { addToast } from '../toasts/toasts.actions';

const inquiryOptions = [
	{
		text: 'Bug Report',
		value: 'Bug Report',
	},
	{
		text: 'General Inquiry',
		value: 'General Inquiry',
	},
];

const ContactUsSchema = Yup.object().shape({
	message: Yup.string().min(12, 'Message too short.').required('Required'),
});

const ContactUs = () => {
	const dispatch = useDispatch();
	const [isContactUsModalVisible, setIsContactUsModalVisible] =
		useState(false);

	const [isLoading, setIsLoading] = useState(false);

	const formikBag = useFormik({
		initialValues: {
			subject: inquiryOptions[0].value || '',
			message: '',
		},
		validationSchema: ContactUsSchema,
		validateOnBlur: true,
		onSubmit: async (data, { resetForm }) => {
			try {
				setIsLoading(true);
				const payload = {
					subject: data.subject,
					message: data.message,
				};
				await postContactUs(payload);
				resetForm();
				setIsContactUsModalVisible(false);
				dispatch(
					addToast('Success', 'Inquiry Sent', 'success', 'check'),
				);
			} catch (err) {
				const errorResponse = ((err || {}).response || {}).data || null;
				dispatch(
					addToast(
						'Error',
						errorResponse.error || 'Something went wrong',
						'danger',
						'help',
					),
				);
			} finally {
				setIsLoading(false);
			}
		},
	});

	const {
		errors,
		handleChange,
		handleSubmit,
		resetForm,
		setFieldValue,
		touched,
		values,
	} = formikBag;

	useEffect(() => {
		const reset = () => {
			resetForm();
		};
		if (!isContactUsModalVisible) {
			reset();
		}
	}, [isContactUsModalVisible]);

	const onChange = (e) => {
		setFieldValue('subject', e.target.value);
	};

	const handleOpenContactUsModal = () => {
		setIsContactUsModalVisible(true);
	};

	const handleCloseContactUsModal = () => {
		setIsContactUsModalVisible(false);
	};

	const listItems = [
		{
			title: 'For partnerships',
			description: (
				<EuiLink href="mailto:partnerships@medalert.ph">
					partnerships@medalert.ph
				</EuiLink>
			),
		},
		{
			title: 'Support',
			description: (
				<EuiLink href="mailto:erx-support@medalert.ph">
					erx-support@medalert.ph
				</EuiLink>
			),
		},
		{
			title: 'Facebook',
			description: (
				<EuiLink href="https://www.facebook.com/MedAlertEMR">
					https://www.facebook.com/MedAlertEMR
				</EuiLink>
			),
		},
	];

	const contactUsModal = (
		<EuiOverlayMask>
			<EuiModal onClose={handleCloseContactUsModal}>
				<EuiModalHeader>
					<EuiModalHeaderTitle>Contact Us</EuiModalHeaderTitle>
				</EuiModalHeader>

				<EuiModalBody>
					<EuiForm>
						<EuiFormRow fullWidth>
							<EuiSelect
								async
								fullWidth
								onChange={onChange}
								options={inquiryOptions}
								placeholder="Select"
								value={values.subject}
							/>
						</EuiFormRow>
						<EuiFormRow
							error={touched.message && errors.message}
							fullWidth
							isInvalid={touched.message && !!errors.message}
						>
							<EuiTextArea
								data-testid="message-input"
								fullWidth
								icon="message"
								id="message"
								isInvalid={touched.email && !!errors.message}
								name="message"
								onChange={handleChange}
								value={values.message}
							/>
						</EuiFormRow>
					</EuiForm>
					<EuiHorizontalRule size="full" />
					<EuiDescriptionList listItems={listItems} />
					<EuiSpacer />
				</EuiModalBody>

				<EuiModalFooter>
					<EuiButton
						fill
						isLoading={isLoading}
						onClick={handleSubmit}
					>
						Send
					</EuiButton>
				</EuiModalFooter>
			</EuiModal>
		</EuiOverlayMask>
	);

	return (
		<>
			<EuiToolTip content={<p>Contact Us</p>} position="bottom">
				<EuiHeaderSectionItemButton
					aria-label="Contact Us"
					onClick={handleOpenContactUsModal}
				>
					<EuiIcon size="m" type="email" />
				</EuiHeaderSectionItemButton>
			</EuiToolTip>
			{isContactUsModalVisible ? contactUsModal : null}
		</>
	);
};

export default ContactUs;
