// should be able to accept query parameters
import { ENDPOINTS } from 'constants/api';
import { get } from 'lodash';
import { thenCallbackRQ } from 'utils/helpers';
import api from '../../utils/api';

export const orderModel = {
	id: '',
	status: '',
	cancellationReason: '',
	patient: {},
	branch: '',
	vendor: '',
	discountType: '',
	discountValue: '',
	notes: '',
	lineItemsSubtotal: '',
	discountSubtotal: '',
	amount: '',
	vatAmount: '',
	totalAmount: '',
	lineItems: [],
	prescription: '',
	address: {},
	pickup: '',
	confirmed: false,
};

export const mapFormData = (order) => {
	return {
		id: order.id || '',
		status: order.status || '',
		cancellationReason: order.cancellationReason || '',
		patient: order.patient || {},
		branch: get(order, 'branch.id'),
		vendor: order.vendor || '',
		discountType: order.discountType || 'percent',
		discountValue: order.discountValue || 0,
		notes: order.notes || '',
		lineItemsSubtotal: order.lineItemsSubtotal || 0,
		discountSubtotal: order.discountSubtotal || 0,
		amount: order.amount || 0,
		vatAmount: order.vatAmount || 0,
		totalAmount: order.totalAmount || 0,
		lineItems: order.lineItems || [],
		prescription: order.prescription || {},
		address: get(order, 'patient.address', {}),
		pickup: order.pickup || false,
		confirmed: order.confirmed || false,
		paid: order.isPaid,
	};
};

const buildFormData = (data) => {
	let payload = {
		prescription: data.prescription,
		branch: get(data, 'branch.id', data.branch),
		patient: get(data, 'patient.id'),
		discountType: data.discountType,
		discountValue: data.discountValue,
		lineItems: data.lineItems || [],
		notes: data.notes,
		amount: data.amount,
		pickup: data.pickup,
		confirmed: data.confirmed,
		pickupType: data.pickupType,
		fulfillment: data.fulfillment,
	};

	if (data.status) {
		payload.status = data.status;
	}

	if (data.shippingRecord) {
		payload = {
			...payload,
			shippingRecord: {
				id: get(data, 'shippingRecord.id'),
				deliveryDate: data.deliveryDate,
				pickupDate: data.pickupDate,
				courier: data.courier,
			},
		};
	}

	return payload;
};

export const createOrder = async (payload) => {
	return api.post('/orders', buildFormData(payload)).then(thenCallbackRQ);
};

export const getOrders = async (ctx) => {
	// const params = {
	// 	_sort: 'createdAt:DESC',
	// };

	const { queryKey } = ctx;
	const [, params] = queryKey;

	return api.get(ENDPOINTS.ORDERS, { params }).then(thenCallbackRQ);
};

export const getOrder = async (ctx) => {
	const { queryKey } = ctx;
	const [, id] = queryKey;

	return api.get(`/orders/${id}`).then(thenCallbackRQ);
};

export const getGrabOrder = async (ctx) => {
	const { queryKey } = ctx;
	const [, id] = queryKey;
	return api.get(`/grab-orders/${id}`).then(thenCallbackRQ);
};

export const getFailedGrabOrders = async (ctx) => {
	const { queryKey } = ctx;
	const [, id] = queryKey;
	const params = {
		order_eq: id,
		status_eq: ['FAILED', 'CANCELED', 'RETURNED'],
	};
	return api.get(`/grab-orders`, { params }).then(thenCallbackRQ);
};

export const getOrdersByParameters = async (params) =>
	api.get(`/orders`, { params });

export const updateOrder = async (payload) => {
	return api
		.put(`/orders/${payload.id}`, buildFormData(payload))
		.then(thenCallbackRQ);
};

export const confirmOrder = async (id) => {
	return api.put(`/orders/${id}/confirm`).then(thenCallbackRQ);
};

export const updateOrderStatus = async (payload) => {
	const { amount, cancellationReason, id, status } = payload;

	return api
		.put(
			`/orders/${id}/status?status=${status}${
				status === 'cancelled'
					? `&cancellationReason=${cancellationReason}`
					: ''
			}${status === 'pendingPayment' ? `&totalAmount=${amount}` : ''}`,
		)
		.then(thenCallbackRQ);
};

export const updateOrderPaymentStatus = async (payload) => {
	const { id, status } = payload;

	return api.put(`/orders/${id}/paid?paid=${status}`).then(thenCallbackRQ);
};

export const getLineItem = async (id) => api.get(`/line-items/${id}`);

export const countOrderComments = (ctx) => {
	const { queryKey } = ctx;
	const [, params] = queryKey;

	return api
		.get(`/comments/count`, {
			params,
		})
		.then(thenCallbackRQ);
};

export const getOrderComments = (ctx) => {
	const { pageParam = 0, queryKey } = ctx;
	const [, queryParams] = queryKey;

	const { _limit: queryLimit, ...initParams } = queryParams;

	const paginationSize = queryLimit || 10;

	const params = {
		_start: pageParam * paginationSize,
		_limit: paginationSize,
		_sort: 'createdAt:DESC',
		...initParams,
	};

	return api
		.get('/comments', {
			params,
		})
		.then(thenCallbackRQ);
};

export const validateOrderBranch = async (id) =>
	api.get(`/orders/${id}/branch`);

export const getOrdersRQ = (ctx) => {
	const { pageParam = 0, queryKey } = ctx;
	const [, queryParams] = queryKey;

	const { _limit: queryLimit, ...initParams } = queryParams;

	const paginationSize =
		queryLimit || Number(process.env.PAGINATION_SIZE) || 5;

	const params = {
		_start: pageParam * paginationSize,
		_limit: paginationSize,
		_sort: 'priority:ASC',
		...initParams,
	};

	return api
		.get('/orders', {
			params,
		})
		.then(thenCallbackRQ);
};

export const countOrders = (ctx) => {
	const { queryKey } = ctx;
	const [, params] = queryKey;

	return api
		.get(`/orders/count`, {
			params,
		})
		.then(thenCallbackRQ);
};
