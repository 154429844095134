import React from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { get } from 'lodash';
import { EuiText, EuiLoadingSpinner, EuiSpacer } from '@elastic/eui';
import PropTypes from 'prop-types';
import { getNextPageParams, formatDate, formatTime } from 'utils/helpers';
import LoadMore from 'components/LoadMore';
import Table from 'components/Table';
import { getTransferHistory, countHistory } from '../prescriptions.fetch';

const TransferHistory = ({ rxId }) => {
	const { data: count } = useQuery(
		[
			`rx-trasnfer-history-count-${rxId}`,
			{
				prescription: rxId,
			},
		],
		countHistory,
		{
			placeholderData: 0,
		},
	);
	const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
		useInfiniteQuery(
			[
				`rx-history-${rxId}`,
				{
					prescription: rxId,
				},
			],
			getTransferHistory,
			{
				placeholderData: [],
				enabled: count > 0,
				getNextPageParam: getNextPageParams(count),
				keepPreviousData: true,
			},
		);

	const getDetails = (sourceEntity, historyData, type) => {
		// eslint-disable-next-line default-case
		switch (sourceEntity) {
			case 'call-center':
				return 'Call Center';
			case 'pharmacist':
				return get(historyData, `${type}.name`, 'Call Center');
			case 'patient':
				return 'Patient';
		}
		return null;
	};

	const columns = [
		{
			field: 'sourceEntity',
			name: 'Origin',
			sortable: true,
			render: (sourceEntity, historyData) =>
				getDetails(sourceEntity, historyData, 'origin'),
		},

		{
			field: 'targetEntity',
			name: 'Destination',
			sortable: true,
			render: (targetEntity, historyData) =>
				getDetails(targetEntity, historyData, 'destination'),
		},
		{
			field: 'remarks',
			name: 'Remaks',
			sortable: true,
			render: (remarks) => remarks,
		},
		{
			field: 'createdAt',
			name: 'Timestamp',
			sortable: true,
			render: (createdAt) =>
				`${formatDate(createdAt)} ${formatTime(createdAt)}`,
		},
	];

	if (isFetching && !isFetchingNextPage) {
		return (
			<>
				<EuiSpacer />
				<EuiLoadingSpinner
					style={{
						display: 'block',
						margin: '0 auto',
					}}
				/>
			</>
		);
	}

	if (!rxId) {
		return null;
	}

	if (!isFetching && (!data || !data.pages || !data.pages.flat)) {
		return (
			<>
				<EuiSpacer />
				<EuiText textAlign="center">
					No Transfer History Related to this Prescription
				</EuiText>
			</>
		);
	}

	return (
		<>
			<EuiSpacer />
			<Table
				columns={columns}
				isExpandable
				itemId="id"
				items={data.pages.flat()}
			/>
			<LoadMore
				fetchNextPage={fetchNextPage}
				hasNextPage={hasNextPage}
				isFetchingNextPage={isFetchingNextPage}
				size="s"
			/>
		</>
	);
};

TransferHistory.propTypes = {
	rxId: PropTypes.string.isRequired,
};

export default TransferHistory;
