import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';
import { clearLightboxData } from './lightbox.actions';

export default () => {
	const dispatch = useDispatch();
	const lightbox = useSelector((state) => state.lightbox);

	const closeLightbox = () => {
		dispatch(clearLightboxData());
	};

	return (
		<>
			{lightbox?.array?.length > 1 && (
				<Lightbox
					images={lightbox.array}
					onClose={closeLightbox}
					startIndex={lightbox.index}
				/>
			)}
			{lightbox?.array?.length === 1 && (
				<Lightbox
					image={lightbox.array[0].url}
					onClose={closeLightbox}
					title={lightbox.array[0].title}
				/>
			)}
		</>
	);
};
