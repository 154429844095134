import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { EuiTitle, EuiHorizontalRule, EuiIcon, EuiLink } from '@elastic/eui';
import moment from 'moment';
import { get } from 'lodash';

import {
	RECENT_PRESCRIPTIONS_LIMIT,
	RECENT_PRESCRIPTIONS_DEFAULT_SORT,
} from 'constants/dashboard';
import Table from 'components/Table';
import protectHOC from 'components/protectHOC';
import { DOCTOR, ADMIN, CALL_CENTER } from 'components/roles';
import { ROLE_PATH } from 'constants/protect';
import RxStatus from 'modules/prescriptions/components/RxStatus';
import {
	getPrescriptions,
	getSignedAttachments,
} from 'modules/prescriptions/prescriptions.fetch';
import ViewPrescription from 'modules/prescriptions/ViewPrescription';
import SendToPatientButton from 'modules/prescriptions/components/SendToPatientButton';

import { getDashboardRx } from './dashboard.actions';

const DoctorDashboardContent = () => {
	const { t } = useTranslation();
	const { prescriptions, role } = useSelector((state) => ({
		role: get(state, ROLE_PATH),
		prescriptions: get(state, 'dashboard.prescriptions'),
	}));
	const dispatch = useDispatch();
	const [columns, setColumns] = useState([]);
	const [, setError] = useState(null);
	const [, setIsLoading] = useState(false);
	const [selectedRx, setSelectedRx] = useState({
		data: null,
		visible: false,
	});
	const [signedAttachments, setSignedAttachments] = useState([]);

	useEffect(() => {
		async function fetchPrescriptions() {
			try {
				setIsLoading(true);
				const { data } = await getPrescriptions({
					_limit: RECENT_PRESCRIPTIONS_LIMIT,
					_sort: RECENT_PRESCRIPTIONS_DEFAULT_SORT,
				});
				dispatch(getDashboardRx(data));
			} catch (err) {
				setError(err.message || 'Something went wrong');
			} finally {
				setError(null);
				setIsLoading(false);
			}
		}
		fetchPrescriptions();
	}, []);

	useEffect(() => {
		async function fetchSignedAttachments(ids) {
			const { data: signedAttachmentsData } = await getSignedAttachments(
				ids,
			);
			setSignedAttachments(signedAttachmentsData);
		}
		if (get(selectedRx.data, 'id') && selectedRx.visible) {
			setSignedAttachments([]);
			fetchSignedAttachments(
				get(selectedRx.data, 'attachments', []).map(({ id }) => id),
			);
		}
		if (!selectedRx.visible) {
			setSignedAttachments([]);
		}
	}, [get(selectedRx.data, 'id'), selectedRx.visible]);

	useEffect(() => {
		if (columns.length) return;

		const cols = [
			{
				field: 'code',
				name: 'Prescription Code',
				sortable: true,
				render: (code, data) => (
					<Link
						onClick={(e) => {
							if (role === DOCTOR.key) {
								e.preventDefault();
								setSelectedRx({ data, visible: true });

								return false;
							}

							return true;
						}}
						to={`/prescriptions/${code}`}
					>
						<EuiLink>{code}</EuiLink>
					</Link>
				),
			},
			{
				field: 'patient.firstName',
				name: 'Patient',
				sortable: true,
				render: (firstName, { patient }) =>
					patient ? `${firstName} ${patient.lastName}` : '',
			},
			{
				field: 'vendor.name',
				name: 'Pharmacy',
				render: (vendor) => vendor || '',
				sortable: true,
			},
			{
				field: 'sentToPatient',
				name: 'Sent to Patient(Email)',
				render: (sentToPatient) => (
					<EuiIcon
						color={!sentToPatient ? 'danger' : 'green'}
						type={!sentToPatient ? 'cross' : 'check'}
					/>
				),
				sortable: true,
			},
			{
				field: 'status',
				name: t('general.status'),
				sortable: true,
				render: (status, data) => (
					<RxStatus
						assigned={data.branch}
						datetime={data.createdAt}
						role={role}
						status={status}
					/>
				),
			},
			{
				field: 'createdAt',
				name: 'Date',
				dataType: 'date',
				sortable: true,
				render: (createdAt) => moment(createdAt).format('ll'),
			},
		];

		if (role === ADMIN.key || role === CALL_CENTER.key) {
			cols.push({
				field: 'branch',
				name: 'Branch',
				sortable: true,
			});
		}

		cols.push({
			actions: [
				{
					name: 'Sent to Patient(Email)',
					description: 'Sent to Patient(Email)',
					render: (data) => (
						<SendToPatientButton
							rxId={data.id}
							sent={data.sentToPatient}
						/>
					),
					isPrimary: true,
				},
			],
		});

		setColumns(cols);
	}, [columns, role]);

	return (
		<>
			<EuiTitle size="s">
				<h3>{t('dashboard.recentPrescriptions')}</h3>
			</EuiTitle>
			<EuiHorizontalRule margin="s" />
			<Table columns={columns} items={prescriptions} pagination={null} />
			<ViewPrescription
				data={{
					...selectedRx.data,
					attachments: signedAttachments,
				}}
				onClose={() => {
					setSelectedRx({ data: null, visible: false });
				}}
				visible={selectedRx.visible}
			/>
		</>
	);
};

export default protectHOC([DOCTOR.key], ROLE_PATH)(DoctorDashboardContent);
