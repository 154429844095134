import React from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useHistory } from 'react-router';

import {
	EuiDescriptionList,
	EuiLink,
	EuiText,
	EuiLoadingSpinner,
} from '@elastic/eui';

import PropTypes from 'prop-types';

import { getNextPageParams } from 'utils/helpers';
import { countOrders, getOrdersRQ } from 'modules/orders/orders.fetch';
import OrderStatus from 'modules/orders/components/OrderStatus';
import LoadMore from 'components/LoadMore';

const PrescriptionOrders = ({ rxId }) => {
	const { push } = useHistory();
	const { data: count } = useQuery(
		[
			`rx-orders-count-${rxId}`,
			{
				prescription: rxId,
			},
		],
		countOrders,
		{
			placeholderData: 0,
		},
	);
	const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
		useInfiniteQuery(
			[
				`rx-orders-${rxId}`,
				{
					prescription: rxId,
				},
			],
			getOrdersRQ,
			{
				placeholderData: [],
				enabled: count > 0,
				getNextPageParam: getNextPageParams(count),
				keepPreviousData: true,
			},
		);

	const mapData = (order) => {
		const { packingCode, status } = order;
		let { orderId: title } = order;

		if (packingCode) {
			title += ` - ${packingCode}`;
		}

		return {
			title: (
				<EuiLink onClick={() => push(`/orders/${order.id}`)}>
					{title}
				</EuiLink>
			),
			description: <OrderStatus status={status} />,
		};
	};

	if (isFetching && !isFetchingNextPage) {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					padding: 5,
				}}
			>
				<EuiLoadingSpinner />
			</div>
		);
	}

	if (!isFetching && (!data || !data.pages || !data.pages.flat)) {
		return (
			<EuiText size="s" style={{ padding: 5 }} textAlign="center">
				No Orders Related to this Prescription
			</EuiText>
		);
	}

	return (
		<div style={{ padding: 5 }}>
			<EuiDescriptionList
				compressed
				listItems={data.pages.flat().map(mapData)}
				type="column"
			/>
			<div style={{ padding: 5, textAlign: 'center' }}>
				<LoadMore
					fetchNextPage={fetchNextPage}
					hasNextPage={hasNextPage}
					isFetchingNextPage={isFetchingNextPage}
					size="s"
				/>
			</div>
		</div>
	);
};

PrescriptionOrders.propTypes = {
	rxId: PropTypes.string.isRequired,
};

export default PrescriptionOrders;
