import api from 'utils/api';

export const getSecretaries = async () => {
	return api.get('/secretaries');
};

export const getSecretary = async (id) => {
	return api.get(`/secretaries/${id}`);
};

export const updateSecretary = async (id, payload) => {
	return api.put(`/secretaries/${id}`, payload);
};

export const deleteSecretary = async (id) => {
	return api.delete(`/secretaries/${id}`);
};
