import api from 'utils/api';

export const getDoctor = async (id) => {
	return api.get(`/doctors/${id}`);
};

export const getContactDetailsByIds = async (ids, types = []) => {
	if (!ids || !ids.length) {
		return Promise.resolve([]);
	}

	const params = {
		id_in: ids,
	};

	if (types && types.length) {
		params.type_in = types;
	}

	return api.get('/contact-details/ids', { params });
};

export const getRegions = async (params, options = {}) => {
	return api.get('/regions', { ...options, params });
};

export const getProvinces = async (params, options = {}) => {
	return api.get('/provinces', { ...options, params });
};

export const getCities = async (params, options = {}) => {
	return api.get('/cities', { ...options, params });
};

export const getBarangays = async (params, options = {}) => {
	return api.get('/barangays', { ...options, params });
};

export const getNotifications = async (params) => {
	return api.get('/notifications', { params });
};

export const getUnreadNotificationsCount = async (params) => {
	return api.get('/notifications/count', { params });
};

export const postContactUs = async (payload) => {
	return api.post('/contact-us', payload);
};
