import React, { useEffect, useState, useRef } from 'react';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { useDispatch } from 'react-redux';
import { setLightboxData } from 'modules/lightbox/lightbox.actions';

import {
	EuiImage,
	EuiShowFor,
	EuiLoadingSpinner,
	EuiSpacer,
	EuiIcon,
	EuiFlexGroup,
	EuiFlexItem,
	EuiButtonIcon,
} from '@elastic/eui';

import PropTypes from 'prop-types';
import PdfThumbnail from './PdfThumbnail';

const FilesSlider = ({
	data,
	isLoading,
	name,
	onChange,
	readOnly,
	visible,
}) => {
	const dispatch = useDispatch();
	const prevData = useRef();
	const [fileNumber, setFileNumber] = useState(0);

	useEffect(() => {
		if (data?.length && data !== prevData.current) {
			if (data?.length > prevData.current?.length)
				setFileNumber(data.length - 1);
			else if (fileNumber >= data?.length) setFileNumber(fileNumber - 1);
			prevData.current = data;
		}
	}, [data]);

	const handleSetFileNumber = (value) => {
		if (parseInt(value, 10) >= 0) {
			setFileNumber(value);
		}
	};

	const openLightbox = (files, index) => {
		const array = files.map((file) => {
			return {
				url: file.url,
				title: file.name,
			};
		});

		dispatch(
			setLightboxData({
				array,
				index,
			}),
		);
	};

	const pdf = (file) => (
		<>
			<PdfThumbnail data={file} />
			<EuiSpacer />
			<span style={{ fontSize: 10 }}>{file.name}</span>
		</>
	);

	const image = (url, fileName) => {
		return (
			<>
				<EuiShowFor sizes={['xs', 's']}>
					<EuiImage
						key={url}
						className="thumbnail-img"
						// size={size ? 'm' : 's'}
						onClick={() => {
							openLightbox(data, fileNumber);
						}}
						style={{
							objectFit: 'cover',
							width: 240,
							height: 180,
							cursor: 'pointer',
						}}
						url={url}
					/>
				</EuiShowFor>
				<EuiShowFor sizes={['xl', 'l', 'm']}>
					<EuiImage
						key={url}
						className="thumbnail-img"
						// size={size ? 'l' : 'm'}
						onClick={() => {
							openLightbox(data, fileNumber);
						}}
						style={{
							objectFit: 'cover',
							width: 400,
							height: 300,
							cursor: 'pointer',
						}}
						url={url}
					/>
				</EuiShowFor>
				<EuiSpacer />
				<span style={{ fontSize: 10 }}>
					{fileName.substring(0, 26)}
				</span>
			</>
		);
	};

	// eslint-disable-next-line consistent-return
	const getRenderType = (file) => {
		const { name: fileName, url } = file;

		// eslint-disable-next-line default-case
		switch (file.type || file.mime) {
			case 'application/pdf':
				return pdf(file);
			case 'image/png':
				return image(url, fileName);
			case 'image/jpeg':
				return image(url, fileName);
			case 'image/gif':
				return image(url, fileName);
		}
	};

	const handleRemoveData = (fileId, fileIndex) => {
		const value = data.filter((file, index) =>
			fileId ? file.id !== fileId : index !== fileIndex,
		);
		onChange({ name, value: [...value] });
	};

	const renderFiles = (files) => {
		if (!files.length) return null;

		return files.map((file, index) => {
			const currentFile = file;

			if (currentFile.provider === 'local') {
				currentFile.url = `${process.env.REACT_APP_API_URL}/${currentFile.url}`;
			}

			if (!currentFile.url) {
				currentFile.url = URL.createObjectURL(file);
			}
			return (
				<div key={file.id || file.name + index}>
					<div
						style={{
							width: 'auto',
							padding: '10px',
							position: 'relative',
							zIndex: 1,
						}}
					>
						{readOnly && (
							<EuiButtonIcon
								aria-label="Delete"
								color="ghost"
								iconType="cross"
								onClick={() => handleRemoveData(file.id, index)}
								size="m"
								style={{
									position: 'absolute',
									top: 1,
									right: 0,
									zIndex: 3,
									height: 'auto',
									width: 'auto',
									padding: 5,
									borderRadius: '100%',
									backgroundColor: 'red',
									opacity: 0.8,
								}}
							/>
						)}
						{getRenderType(currentFile)}
					</div>
				</div>
			);
		});
	};

	const pdfDot = () => <EuiIcon type="document" />;

	const imageDot = (url) => {
		return (
			<EuiImage
				key={url}
				style={{ width: 20, height: 20, objectFit: 'cover' }}
				url={url}
			/>
		);
	};

	// eslint-disable-next-line consistent-return
	const getRenderDotsType = (file) => {
		const { url } = file;

		// eslint-disable-next-line default-case
		switch (file.type || file.mime) {
			case 'application/pdf':
				return pdfDot(url);
			case 'image/png':
				return imageDot(url);
			case 'image/jpeg':
				return imageDot(url);
			case 'image/gif':
				return imageDot(url);
		}
	};

	const renderDots = (files) => {
		if (!files.length) return null;

		return files.map((file) => {
			const currentFile = file;

			if (currentFile.provider === 'local') {
				currentFile.url = `${process.env.REACT_APP_API_URL}/${currentFile.url}`;
			}

			if (!currentFile.url) {
				currentFile.url = URL.createObjectURL(file);
			}

			return (
				<div key={file.url} className="thumbnail-img">
					{getRenderDotsType(currentFile)}
				</div>
			);
		});
	};

	if ((!data.length && !isLoading) || !visible) {
		return null;
	}

	if (isLoading)
		return (
			<EuiFlexGroup alignItems="center">
				<EuiFlexItem>
					<EuiLoadingSpinner size="l" />
				</EuiFlexItem>
			</EuiFlexGroup>
		);

	return (
		<div style={{ width: '100%', maxWidth: '100vw' }}>
			<Carousel
				onChange={handleSetFileNumber}
				plugins={['arrows']}
				value={fileNumber}
			>
				{renderFiles(data)}
			</Carousel>
			<Dots
				onChange={handleSetFileNumber}
				thumbnails={renderDots(data)}
				value={fileNumber}
			/>
		</div>
	);
};
FilesSlider.propTypes = {
	data: PropTypes.arrayOf(PropTypes.shape({})),
	isLoading: PropTypes.bool,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	readOnly: PropTypes.bool,
	visible: PropTypes.bool,
};

FilesSlider.defaultProps = {
	data: [],
	isLoading: false,
	onChange: () => {},
	readOnly: false,
	visible: true,
};

export default FilesSlider;
