import React from 'react';

import { EuiText, EuiIcon } from '@elastic/eui';

import PropTypes from 'prop-types';

const VerificationStatus = ({
	iconOnly,
	unverifiedText,
	verified,
	verifiedText,
}) => {
	if (iconOnly) {
		return (
			<EuiIcon
				color={verified ? 'success' : 'danger'}
				type={verified ? 'checkInCircleFilled' : 'crossInACircleFilled'}
			/>
		);
	}

	return (
		<EuiText>
			<EuiIcon
				color={verified ? 'success' : 'danger'}
				type={verified ? 'checkInCircleFilled' : 'crossInACircleFilled'}
			/>
			{verified
				? ` ${verifiedText || 'Verified'}`
				: ` ${unverifiedText || 'Not Verified'}`}
		</EuiText>
	);
};

VerificationStatus.propTypes = {
	iconOnly: PropTypes.bool,
	unverifiedText: PropTypes.string,
	verified: PropTypes.bool.isRequired,
	verifiedText: PropTypes.string,
};

VerificationStatus.defaultProps = {
	iconOnly: false,
	unverifiedText: null,
	verifiedText: null,
};

export default VerificationStatus;
