// should be able to accept query parameters
import api from '../../utils/api';

export const createProduct = async (payload) => api.post('/products', payload);

export const getProducts = async (params) => api.get('/products', { params });

export const getProduct = async (id) => api.get(`/products/${id}`);

export const getProductsByParameters = async (params) =>
	api.get(`/products`, { params });

export const updateProduct = async (id, payload) =>
	api.put(`/products/${id}`, payload);
