import initialState from 'store/initialState';
import {
	APP_DOCK_NAVIGATION,
	APP_UNDOCK_NAVIGATION,
	GET_LATEST_RX_SUCCESS,
	CREATE_RX_DASHBOARD_SUCCESS,
} from 'constants/actionTypes';

export default (state = initialState.dashboard, action = null) => {
	switch (action.type) {
		case APP_DOCK_NAVIGATION:
			return {
				...state,
				isNavDocked: true,
			};

		case APP_UNDOCK_NAVIGATION:
			return {
				...state,
				isNavDocked: false,
			};

		case GET_LATEST_RX_SUCCESS:
			return {
				...state,
				prescriptions: action.data,
			};

		case CREATE_RX_DASHBOARD_SUCCESS:
			return {
				...state,
				prescriptions: [action.data, ...state.prescriptions],
			};

		default:
			return state;
	}
};
