import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
	EuiForm,
	EuiFormRow,
	EuiFieldText,
	EuiModal,
	EuiOverlayMask,
	EuiModalHeader,
	EuiModalHeaderTitle,
	EuiModalBody,
	EuiFilePicker,
	EuiImage,
	EuiFlexItem,
	EuiModalFooter,
	EuiButtonEmpty,
	EuiButton,
	EuiTitle,
	EuiSpacer,
	EuiHorizontalRule,
	EuiFlexGroup,
	EuiText,
} from '@elastic/eui';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import _ from 'lodash';

import PropTypes from 'prop-types';

import { createLicense } from 'modules/licenses/licenses.fetch';
import { logoutAction, updateUserDetails } from 'modules/auth/auth.actions';
import { getUserDetails } from 'modules/auth/auth.fetch';
import Callout from 'components/Callout';
import { DOCTOR } from 'components/roles';
import protectHOC from 'components/protectHOC';
import { ROLE_PATH } from 'constants/protect';

import { addToast } from '../toasts/toasts.actions';

const ValidationSchema = Yup.object().shape({
	prcLicenseNumber: Yup.string().required('License Number is required.'),
	prcImage: Yup.string().required('License Photo is required.').nullable(),
});

const ValidationModal = ({ pending, visible }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [prcImage, setPrcImage] = useState(null);

	const { errors, handleChange, handleSubmit, setFieldValue, values } =
		useFormik({
			initialValues: {
				prcLicenseNumber: '',
				prcImage: '',
			},
			onSubmit: async (data) => {
				try {
					setIsLoading(true);
					// eslint-disable-next-line default-case
					await createLicense({
						licenseNumber: data.prcLicenseNumber,
						image: prcImage,
						type: 'PRC',
					});

					const { data: user } = await getUserDetails();

					dispatch(updateUserDetails(user));

					addToast(
						'License submitted for validation',
						null,
						'success',
					);
				} catch (err) {
					// log or do something with the error
					setError(err.message || err);
				} finally {
					setIsLoading(false);
				}
			},
			validationSchema: ValidationSchema,
		});

	const setFile = (key, value) => {
		if (value.length > 0) {
			if (key === 'prcImage') setPrcImage(value[0]);

			setFieldValue(key, value[0].name);
		} else {
			if (key === 'prcImage') setPrcImage(null);
			setFieldValue(key, null);
		}
	};

	const renderFiles = (file) => {
		if (!file) return null;

		const url = URL.createObjectURL(file);

		return <EuiImage allowFullScreen={false} size="m" url={url} />;
	};

	const logout = () => dispatch(logoutAction());
	const checkApproval = async () => {
		try {
			setIsLoading(true);
			const { data: user } = await getUserDetails();

			const isValidated = _.get(user, 'details.prcValidated', false);

			if (!isValidated) {
				throw new Error(
					"We're still validating your license, you will receive an email when you get validated",
				);
			}

			dispatch(
				addToast(
					'License Validation',
					'Your license has been validated. You can now create prescriptions.',
					'success',
				),
			);

			dispatch(updateUserDetails(user));
		} catch (err) {
			dispatch(addToast('License Validation', err.message, 'primary'));
		} finally {
			setIsLoading(false);
		}
	};

	if (!visible) return null;

	return (
		<EuiOverlayMask>
			<EuiModal
				// TODO: move to stylesheet
				style={{ position: 'relative', zIndex: 1, width: 600 }}
			>
				<EuiModalHeader>
					<EuiModalHeaderTitle>
						{t('validationModal.title')}
					</EuiModalHeaderTitle>
				</EuiModalHeader>
				<EuiModalBody>
					<EuiForm>
						<Callout color="danger" value={error} />
						{pending ? (
							<>
								<Callout
									color="primary"
									value="You have pending license validation"
								/>
								<EuiButton
									fill
									isLoading={isLoading}
									onClick={checkApproval}
								>
									Check Approval Status
								</EuiButton>
							</>
						) : (
							<>
								<EuiFlexItem>
									<EuiTitle size="xs">
										<h3>{`${t(
											'validationModal.prc',
										)} License`}</h3>
									</EuiTitle>
									<EuiHorizontalRule margin="xs" />
									<EuiFormRow
										error={errors.prcLicenseNumber}
										fullWidth
										isInvalid={
											errors.prcLicenseNumber || false
										}
										label={t(
											'validationModal.licenseNumber',
										)}
									>
										<EuiFieldText
											fullWidth
											id="prcLicenseNumber"
											isInvalid={
												errors.prcLicenseNumber || false
											}
											name="prcLicenseNumber"
											onChange={handleChange}
											placeholder={t(
												'validationModal.licenseNumber',
											)}
											value={values.prcLicenseNumber}
										/>
									</EuiFormRow>
									<EuiFormRow fullWidth>
										<EuiText size="s">
											<p>
												ID submitted will be deleted
												after validation.
											</p>
										</EuiText>
									</EuiFormRow>
									<EuiFormRow
										error={errors.prcImage}
										fullWidth
										isInvalid={errors.prcImage || false}
									>
										<EuiFilePicker
											aria-label="PRC License Photo"
											display="default"
											fullWidth
											id="prcImage"
											initialPromptText="Photo of your PRC License Here"
											isInvalid={errors.prcImage || false}
											name="prcImage"
											onChange={(e) =>
												setFile('prcImage', e)
											}
										/>
									</EuiFormRow>
									<EuiSpacer />
									<EuiFormRow fullWidth>
										<EuiFlexGroup
											alignItems="center"
											fullWidth
											justifyContent="center"
										>
											{renderFiles(prcImage)}
										</EuiFlexGroup>
									</EuiFormRow>
								</EuiFlexItem>
							</>
						)}
					</EuiForm>
				</EuiModalBody>
				<EuiModalFooter>
					<EuiButtonEmpty color="danger" onClick={logout}>
						{t('dashboard.logout')}
					</EuiButtonEmpty>
					{!pending && (
						<EuiButton
							color="primary"
							fill
							isLoading={isLoading}
							onClick={handleSubmit}
						>
							Submit
						</EuiButton>
					)}
				</EuiModalFooter>
			</EuiModal>
		</EuiOverlayMask>
	);
};

ValidationModal.propTypes = {
	pending: PropTypes.bool.isRequired,
	visible: PropTypes.bool,
};

ValidationModal.defaultProps = {
	visible: false,
};

export default protectHOC([DOCTOR.key], ROLE_PATH)(ValidationModal);
