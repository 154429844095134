import React from 'react';

import { EuiSuperSelect } from '@elastic/eui';

import PropTypes from 'prop-types';

import RxStatus from 'modules/prescriptions/components/RxStatus';

const PrescriptionStatusDropdown = ({
	assigned,
	datetime,
	onChange,
	role,
	selected,
	...props
}) => {
	// const NEW = { value: 'new', text: 'New' };
	const RECEIVED = { value: 'received', text: 'Received' };
	const PROCESSING = { value: 'processing', text: 'Processing' };
	// const READY_FOR_PICKUP = {
	// 	value: 'readyForPickup',
	// 	text: 'Ready for pickup',
	// };
	const PARTIAL = { value: 'partial', text: 'Partial' };
	const SERVED = { value: 'served', text: 'Served' };
	const CANCELLED = { value: 'cancelled', text: 'Cancelled' };

	const statusOptions = [
		RECEIVED,
		PROCESSING,
		// READY_FOR_PICKUP,
		PARTIAL,
		SERVED,
		CANCELLED,
	];

	const statusOpts = statusOptions.map((stats) => {
		return {
			value: stats.value,
			inputDisplay: (
				<RxStatus
					assigned={assigned}
					datetime={datetime}
					role={role}
					status={stats.value}
				/>
			),
			dropdownDisplay: (
				<RxStatus
					assigned={assigned}
					datetime={datetime}
					role={role}
					status={stats.value}
				/>
			),
			disabled: stats.value === selected,
		};
	});

	return (
		<EuiSuperSelect
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
			data-testid="prescription.status"
			id="prescription.status"
			name="prescription.status"
			onChange={onChange}
			options={statusOpts}
			valueOfSelected={selected}
		/>
	);
};

PrescriptionStatusDropdown.propTypes = {
	assigned: PropTypes.bool,
	datetime: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	role: PropTypes.string,
	selected: PropTypes.string,
};

PrescriptionStatusDropdown.defaultProps = {
	assigned: false,
	datetime: null,
	role: null,
	selected: null,
};

export default PrescriptionStatusDropdown;
