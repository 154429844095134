import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useFormik } from 'formik';
import {
	EuiSpacer,
	EuiFlexGroup,
	EuiFlexItem,
	EuiForm,
	EuiFormRow,
	EuiHorizontalRule,
	EuiButton,
	EuiTitle,
	EuiFlexGrid,
	EuiFilePicker,
	EuiFormLabel,
	EuiLink,
	EuiCheckbox,
	EuiImage,
	EuiToolTip,
	EuiIcon,
} from '@elastic/eui';
import _ from 'lodash';
import * as yup from 'yup';

import PersonalInfomationForm from 'components/PersonalInfomationForm';
import SpecializationDropdown from 'components/forms/SpecializationDropdown';
import VendorDropdown from 'components/forms/VendorDropdown';
import { getUserDetails } from 'modules/auth/auth.fetch';
import { mapOptions } from 'utils/helpers';

import { addToast } from '../toasts/toasts.actions';
import { updateUserDetails } from '../auth/auth.actions';
import { updateDoctor } from './doctors.fetch';

const DoctorSchema = yup.object().shape({
	firstName: yup.string().required(),
	middleName: yup.string(),
	lastName: yup.string().required(),
	selectedVendor: yup.string().nullable(),
	birthdate: yup.date().nullable(),
	email: yup.string().email('Invalid email').required(),
	mobile: yup.number().typeError('Invalid mobile number').required(),
	signature: yup.string().nullable(),
	mailingList: yup.bool(),
	specializations: yup.array().of(yup.object()),
});

const General = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [signature, setSignature] = useState(null);
	const [replace, setReplace] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const { data: details } = useQuery(['doctor-details'], getUserDetails, {
		placeholderData: {},
		select: ({ data }) => {
			if (data) {
				const { details: deets } = data;
				if (
					deets &&
					deets.specializations &&
					deets.specializations.length
				) {
					const mapFunc = mapOptions('id', 'name');

					deets.specializations = deets.specializations.map(mapFunc);
				}

				if (deets.contactDetails?.length) {
					deets.email = deets.contactDetails.find(
						(contactDetail) => contactDetail.type === 'email',
					)?.value;
					deets.mobile = deets.contactDetails.find(
						(contactDetail) => contactDetail.type === 'mobile',
					)?.value;
				}
				delete deets.contactDetails;

				return deets;
			}
			return false;
		},
	});

	const formValues = {
		firstName: details?.firstName || '',
		middleName: details?.middleName || '',
		lastName: details?.lastName || '',
		selectedVendor: details?.defaultVendor?.id || '',
		birthdate: details?.birthdate || '',
		email: details?.email?.value || '',
		mobile: details?.mobile?.value || '',
		signature: details?.signature || '',
		mailingList: details?.mailingList || false,
		specializations: details?.specializations || [],
	};

	const formikBag = useFormik({
		initialValues: formValues,
		validationSchema: DoctorSchema,
		validateOnBlur: true,
		onSubmit: async (data) => {
			const payload = {
				firstName: data.firstName,
				middleName: data.middleName,
				lastName: data.lastName,
				selectedVendor: data.selectedVendor,
				birthdate: data.birthdate,
				signature,
				mailingList: data.mailingList,
			};

			const contactDetails = [];
			if (data.email) {
				contactDetails.push({
					type: 'email',
					value: data.email,
				});
			}
			if (data.mobile) {
				contactDetails.push({
					type: 'mobile',
					value: data.mobile,
				});
			}

			if (contactDetails.length) payload.contactDetails = contactDetails;

			if (data.specializations && data.specializations.length) {
				payload.specializations = data.specializations.map(
					({ value }) => value,
				);
			}

			if (data.name) {
				payload.clinic = {
					name: data.name,
				};
			}

			if (data.line1 && data.city) {
				payload.clinic.address.line1 = data.line1;
				payload.clinic.address.line2 = data.line2;
				payload.clinic.address.city = data.city;
				payload.clinic.address.cityId = data.cityId;
				payload.clinic.address.brgy = data.brgy;
				payload.clinic.address.brgyId = data.brgyId;
			}

			if (formValues.clinic) {
				payload.clinic.id = formValues.clinic;
			}

			if (formValues.addressId) {
				payload.clinic.address.id = formValues.addressId;
			}

			setIsLoading(true);
			updateDoctor(details.id, payload)
				.then((res) => {
					dispatch(() => {
						updateUserDetails({
							...details.user,
							details: {
								...details,
								...res.data,
							},
						});
					});
					dispatch(
						addToast(
							'Successfully saved changes',
							null,
							'success',
							'check',
						),
					);

					formikBag.resetForm({ values: formikBag.values });
					setIsLoading(false);
				})
				.catch((err) => {
					dispatch(
						addToast(
							'Error',
							err.message || 'Something went wrong',
							'danger',
							'help',
						),
					);

					setIsLoading(false);
				});
		},
	});
	const {
		dirty,
		handleSubmit,
		isInitialValid,
		isValid,
		resetForm,
		setFieldValue,
		setValues,
		values,
	} = formikBag;
	const { selectedVendor, specializations } = values;

	const handleVendorChange = (value) => {
		setFieldValue('selectedVendor', value);
	};

	const handleSpecChange = (specs) => {
		setFieldValue('specializations', specs);
	};

	const toggleReplace = () => setReplace(!replace);

	const handleFilePicker = (event) => {
		const { length, ...fileItems } = event;

		if (!length) return setSignature(null);

		const files = Object.keys(fileItems).map((key) => event[key]);

		setFieldValue('signature', _.get(files, '0.name'));

		return setSignature(files[0]);
	};

	useEffect(() => {
		if (details) {
			setValues(details);
			resetForm({ values: details });
		}
	}, [details]);

	return (
		<>
			<EuiSpacer size="m" />
			<EuiForm style={{ maxWidth: 825 }}>
				<EuiFlexGrid columns={2}>
					<EuiFlexItem>
						<EuiFormRow>
							<EuiTitle size="xs">
								<h3>Personal Information</h3>
							</EuiTitle>
						</EuiFormRow>
						<EuiHorizontalRule margin="xs" />
						<EuiFormRow>
							<PersonalInfomationForm formikBag={formikBag} />
						</EuiFormRow>
						<EuiFormRow label="Specialization(s)">
							<SpecializationDropdown
								id="specializations"
								name="specializations"
								onBlur={formikBag.handleBlur}
								onChange={handleSpecChange}
								selected={specializations}
							/>
						</EuiFormRow>
						<EuiFormRow label="Prefered Pharmacy">
							<VendorDropdown
								id="selectedVendor"
								name="selectedVendor"
								onBlur={formikBag.handleBlur}
								onChange={handleVendorChange}
								selected={selectedVendor}
							/>
						</EuiFormRow>
						{details?.qrCode?.length ? (
							<EuiFormRow label="QR Code">
								<EuiImage
									allowFullScreen
									alt="QR Code"
									size="l"
									url={details?.qrCode[0].url}
								/>
							</EuiFormRow>
						) : (
							''
						)}
						<EuiFormLabel>
							Signature
							<EuiToolTip
								content={
									<ul className="signature-tooltip">
										<li>
											Used to electronically sign
											generated prescriptions, will not be
											used for anything else
										</li>
										<li>
											Without this, receiving Pharmacy
											will not accept the prescription as
											valid
										</li>
										<li>
											Your signature will only be used
											when generating an electronic
											prescription PDF File
										</li>
										<li>
											Encrypted and stored in our Amazon
											Web Services (AWS) server
										</li>
										<li>
											Signature will be locked again 5
											minutes after generating a
											prescription
										</li>
										<li>
											More Questions? email us at{' '}
											erx-support@medalert.ph
										</li>
									</ul>
								}
								position="bottom"
							>
								<EuiIcon
									size="xs"
									style={{
										marginLeft: '5px',
										marginTop: '-2.5px',
									}}
									type="questionInCircle"
								/>
							</EuiToolTip>
						</EuiFormLabel>
						<EuiFormRow helpText="PNG is the recommended file format. It is highly recommended that you use a file with a transparent background.">
							{details?.signature && !replace ? (
								<>
									<EuiSpacer size="xs" />
									You Have registered a Signature.{' '}
									<EuiLink onClick={toggleReplace}>
										Replace
									</EuiLink>
								</>
							) : (
								<EuiFilePicker
									fullWidth
									initialPromptText="Select or drag your signature image here"
									onChange={handleFilePicker}
								/>
							)}
						</EuiFormRow>
						<EuiFormRow label=" ">
							<EuiCheckbox
								checked={values.mailingList}
								id="mailingList"
								label={
									<span>
										I am willing to receive invitations to
										join sponsored panels for research
										relevant to my specialty.
									</span>
								}
								name="mailingList"
								onChange={formikBag.handleChange}
								onClick={formikBag.handleBlur}
							/>
						</EuiFormRow>
					</EuiFlexItem>
				</EuiFlexGrid>
			</EuiForm>
			<EuiSpacer size="m" />
			<EuiHorizontalRule margin="xs" />
			<EuiFlexGroup
				alignItems="center"
				direction="row"
				gutterSize="m"
				justifyContent="flexEnd"
			>
				<EuiFlexItem grow={false}>
					<EuiFlexGroup responsive={false}>
						<EuiFlexItem>
							<EuiButton
								disabled={
									isLoading ||
									!isValid ||
									!dirty ||
									isInitialValid
								}
								fill
								iconType="checkInCircleFilled"
								isLoading={isLoading}
								onClick={handleSubmit}
							>
								{t('general.fullSaveChanges')}
							</EuiButton>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
			<EuiSpacer size="m" />
		</>
	);
};

export default General;
