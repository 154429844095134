import api from 'utils/api';
import { thenCallbackRQ } from 'utils/helpers';

export const countComments = (ctx) => {
	const { queryKey } = ctx;
	const [, params] = queryKey;

	return api
		.get(`/comments/count`, {
			params,
		})
		.then(thenCallbackRQ);
};

export const getComments = (ctx) => {
	const { pageParam = 0, queryKey } = ctx;
	const [, queryParams] = queryKey;

	const { _limit: queryLimit, ...initParams } = queryParams;

	const paginationSize = queryLimit || 10;

	const params = {
		_start: pageParam * paginationSize,
		_limit: paginationSize,
		_sort: 'createdAt:DESC',
		...initParams,
	};

	return api
		.get('/comments', {
			params,
		})
		.then(thenCallbackRQ);
};
export const addComment = async (payload) => {
	const { attachments, ...data } = payload;
	const formData = new FormData();
	Object.keys(data).forEach((key) => {
		if (data[key] === null) {
			delete data[key];
		}
	});

	formData.append('data', JSON.stringify(data));
	attachments.forEach((file) => formData.append('files[attachments]', file));

	return api.post('/comments/', formData).then(thenCallbackRQ);
};

export const updateComment = async (payload) => {
	const { attachments, id, ...data } = payload;
	const formData = new FormData();

	formData.append('data', JSON.stringify(data));
	attachments.forEach((file) => formData.append('files[attachments]', file));

	return api.put(`/comments/${id}`, formData).then(thenCallbackRQ);
};

export const deleteComment = async (id) => {
	return api.delete(`/comments/${id}`).then(thenCallbackRQ);
};
