import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet-async';

import {
	EuiButton,
	EuiButtonEmpty,
	EuiCallOut,
	EuiFieldText,
	EuiFlexGroup,
	EuiFlexItem,
	EuiForm,
	EuiFormRow,
	EuiSpacer,
	EuiText,
} from '@elastic/eui';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { postForgotPassword } from './auth.fetch';

function ForgotPassword() {
	const { t } = useTranslation();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(false);

	const ForgotPasswordSchema = Yup.object().shape({
		email: Yup.string()
			.email('Must be valid email.')
			.required('Email is required'),
	});

	// Setup Formik
	const { errors, handleChange, handleSubmit, values } = useFormik({
		initialValues: {
			email: '',
		},
		validationSchema: ForgotPasswordSchema,
		validateOnBlur: true,
		onSubmit: async ({ email }, { resetForm }) => {
			try {
				setError(null);
				setIsLoading(true);
				// should be an axios request
				await postForgotPassword(email);

				setSuccess(true);
			} catch (err) {
				// set error message from api request failure
				setError(err.message || t('errors.default'));
			} finally {
				setIsLoading(false);
				resetForm({ email: '' });
			}
		},
	});

	const disableButton = Object.keys(errors).length > 0;

	const backToLogin = () => history.push('/login');

	return (
		<>
			<Helmet title={t('forgotPassword.title')} />
			<EuiText textAlign="center">
				<h3>{t('forgotPassword.title')}</h3>
			</EuiText>
			<EuiSpacer size="s" />

			<EuiForm>
				{error && (
					<>
						<EuiCallOut color="danger" size="s" title={error} />
						<EuiSpacer size="s" />
					</>
				)}
				{success && (
					<>
						<EuiCallOut
							color="success"
							size="s"
							title={t('forgotPassword.successMessage', {
								email: values.email,
							})}
						/>
						<EuiSpacer size="s" />
					</>
				)}
				<EuiFormRow
					error={errors.email}
					isInvalid={!!errors.email}
					label={t('forgotPassword.emailLabel')}
				>
					<EuiFieldText
						data-testid="email-input"
						id="email"
						isInvalid={!!errors.email}
						name="email"
						onChange={handleChange}
						placeholder={t('forgotPassword.emailPlaceholder')}
						value={values.email}
					/>
				</EuiFormRow>
				<EuiButton
					color="primary"
					data-testid="forgot-button"
					disabled={disableButton}
					fill
					fullWidth
					isLoading={isLoading}
					onClick={handleSubmit}
					type="submit"
				>
					{t('forgotPassword.title')}
				</EuiButton>
				<EuiSpacer size="s" />
				<EuiFlexGroup justifyContent="center">
					<EuiFlexItem grow={false}>
						<EuiButtonEmpty onClick={backToLogin} size="xs">
							{t('general.goBack')}
						</EuiButtonEmpty>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiForm>
		</>
	);
}

export default ForgotPassword;
