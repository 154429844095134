import React, { useState } from 'react';

import {
	EuiFlexItem,
	EuiButton,
	EuiButtonEmpty,
	EuiShowFor,
	EuiToolTip,
} from '@elastic/eui';

import PropTypes from 'prop-types';
import { get } from 'lodash';

import { CALL_CENTER, PHARMACIST } from 'components/roles';
import protectHOC from 'components/protectHOC';
import { ROLE_PATH } from 'constants/protect';
import { MERCURY } from 'constants/vendor.names';

import ConvertPrescriptionModal from './ConvertPrescriptionModal';

const CreateOrderButton = ({
	disabled,
	onClick,
	prescription,
	size = 'm',
	toolTipContent,
	visible,
}) => {
	const { vendor = {} } = prescription;
	const [modalVisible, setModalVisible] = useState(false);
	const toggleModal = () => setModalVisible(!modalVisible);

	if (!visible) return null;

	return (
		<>
			<EuiFlexItem grow={false}>
				<EuiShowFor sizes={['xl', 'l', 'm']}>
					<EuiToolTip content={toolTipContent} position="bottom">
						<EuiButton
							disabled={disabled}
							fullWidth={false}
							iconType="plusInCircle"
							onClick={() => {
								onClick();
								toggleModal();
							}}
							size={size}
						>
							Prepare Order
						</EuiButton>
					</EuiToolTip>
				</EuiShowFor>
				<EuiShowFor sizes={['s', 'xs']}>
					<EuiToolTip content={toolTipContent} position="bottom">
						<EuiButtonEmpty
							disabled={disabled}
							fullWidth={false}
							onClick={() => {
								onClick();
								toggleModal();
							}}
							size="xs"
						>
							Prepare Order
						</EuiButtonEmpty>
					</EuiToolTip>
				</EuiShowFor>
			</EuiFlexItem>
			<ConvertPrescriptionModal
				confirmationOnly={get(vendor, 'name') === MERCURY}
				onClose={toggleModal}
				rxData={prescription}
				visible={modalVisible}
			/>
		</>
	);
};

CreateOrderButton.propTypes = {
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
	prescription: PropTypes.shape().isRequired,
	size: PropTypes.oneOf(['m', 's', 'xs']),
	toolTipContent: PropTypes.string,
	visible: PropTypes.bool,
};

CreateOrderButton.defaultProps = {
	disabled: false,
	onClick: () => {},
	size: 'm',
	toolTipContent: 'Prepare new order',
	visible: true,
};

export default protectHOC(
	[PHARMACIST.key, CALL_CENTER.key],
	ROLE_PATH,
)(CreateOrderButton);
