import { SET_LIGHTBOX_DATA, CLEAR_LIGHTBOX_DATA } from 'constants/actionTypes';

export const setLightboxData = (data) => ({
	type: SET_LIGHTBOX_DATA,
	data,
});

export const clearLightboxData = () => ({
	type: CLEAR_LIGHTBOX_DATA,
});
