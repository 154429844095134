import initialState from 'store/initialState';
import { SET_LIGHTBOX_DATA, CLEAR_LIGHTBOX_DATA } from 'constants/actionTypes';

export default (state = initialState.lightbox, action = null) => {
	switch (action.type) {
		case SET_LIGHTBOX_DATA:
			return action.data;

		case CLEAR_LIGHTBOX_DATA:
			return null;

		default:
			return state;
	}
};
