import React from 'react';
import PropTypes from 'prop-types';
import { EuiHealth } from '@elastic/eui';

const TabLabel = ({ isInvalid, text }) => {
	return isInvalid ? (
		<span>
			<EuiHealth color="danger" />
			&nbsp;{text}
		</span>
	) : (
		text
	);
};

TabLabel.propTypes = {
	isInvalid: PropTypes.bool,
	text: PropTypes.string,
};

TabLabel.defaultProps = {
	isInvalid: false,
	text: null,
};

export default TabLabel;
